import React, { useRef } from 'react';
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from 'highcharts';

export type HCProps = {
    options: Highcharts.Options;
}

const HighchartsGraph: React.FC<HCProps> = (props) => {

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const {options} = props;

    options.accessibility = {
        enabled: false
    }

    return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        //   {...props}
        />
    );
};

export default HighchartsGraph;
