import { FC } from "react";
import { AppStateProps } from "../../types/AppState";
import { ElectorateDetailsPage } from "./ElectorateDetailsPickerPage";

export const ElectorateTab: FC<AppStateProps> = (props: AppStateProps) => {

    const { appState } = props;

    return (
        <>
            <ElectorateDetailsPage appState={appState}></ElectorateDetailsPage>
        </>
    )
}
