import { FC, useEffect, useState } from "react";
import { ElectionApp } from "../live-election";
import { AppState } from "../types/AppState";
import { HomePageTabLayout } from "./home/TabLayout";
import { HomePageHeader } from "./home/Header";


export type HomePageProps = {
}

export const HomePage: FC<HomePageProps> = () => {

    const [appState, setAppState] = useState<AppState>({});

    useEffect(() => {
        setAppState(ElectionApp.appState);
        ElectionApp.subscribe((state) => setAppState(state));
    }, []);

    return (
        <>
            <HomePageHeader />

            <HomePageTabLayout appState={appState} />
        </>
    )
}
