export const previous_election_data_2020 = {
    "electorates": [
      {
        "name": "AUCKLAND CENTRAL",
        "candidate": {
          "informal": 382,
          "votes": [
            {
              "name": "DELAMERE, Tuariki",
              "count": 320
            },
            {
              "name": "HOFFMAN DERVAN, Dominic",
              "count": 50
            },
            {
              "name": "LOVE, Joshua",
              "count": 73
            },
            {
              "name": "MARCROFT, Jenny",
              "count": 274
            },
            {
              "name": "MELLOW, Emma",
              "count": 9775
            },
            {
              "name": "POOLE, Felix",
              "count": 588
            },
            {
              "name": "SADLER, Chris",
              "count": 23
            },
            {
              "name": "STITT, Kevin",
              "count": 186
            },
            {
              "name": "SWARBRICK, Chlöe",
              "count": 12631
            },
            {
              "name": "TAVA, Vernon",
              "count": 120
            },
            {
              "name": "WHITE, Helen",
              "count": 11563
            }
          ]
        },
        "party": {
          "informal": 160,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2724
            },
            {
              "name": "Advance NZ",
              "count": 198
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 99
            },
            {
              "name": "Green Party",
              "count": 6937
            },
            {
              "name": "HeartlandNZ",
              "count": 1
            },
            {
              "name": "Labour Party",
              "count": 16751
            },
            {
              "name": "Māori Party",
              "count": 111
            },
            {
              "name": "National Party",
              "count": 7680
            },
            {
              "name": "New Conservative",
              "count": 197
            },
            {
              "name": "New Zealand First Party",
              "count": 622
            },
            {
              "name": "NZ Outdoors Party",
              "count": 15
            },
            {
              "name": "ONE Party",
              "count": 20
            },
            {
              "name": "Social Credit",
              "count": 7
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 59
            },
            {
              "name": "TEA Party",
              "count": 35
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 776
            },
            {
              "name": "Vision New Zealand",
              "count": 11
            }
          ]
        }
      },
      {
        "name": "BANKS PENINSULA",
        "candidate": {
          "informal": 772,
          "votes": [
            {
              "name": "ATKINSON, Ben",
              "count": 1518
            },
            {
              "name": "CHU, Catherine",
              "count": 12237
            },
            {
              "name": "FOX, David",
              "count": 1715
            },
            {
              "name": "HONISS, Caleb",
              "count": 639
            },
            {
              "name": "McLELLAN, Tracey Lee",
              "count": 25393
            },
            {
              "name": "O’ROURKE, Denis",
              "count": 679
            },
            {
              "name": "SAGE, Eugenie",
              "count": 6222
            },
            {
              "name": "WILLIAMS, Tiamara",
              "count": 506
            }
          ]
        },
        "party": {
          "informal": 241,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3478
            },
            {
              "name": "Advance NZ",
              "count": 424
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 133
            },
            {
              "name": "Green Party",
              "count": 7089
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 25091
            },
            {
              "name": "Māori Party",
              "count": 144
            },
            {
              "name": "National Party",
              "count": 10453
            },
            {
              "name": "New Conservative",
              "count": 626
            },
            {
              "name": "New Zealand First Party",
              "count": 991
            },
            {
              "name": "NZ Outdoors Party",
              "count": 37
            },
            {
              "name": "ONE Party",
              "count": 69
            },
            {
              "name": "Social Credit",
              "count": 22
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 41
            },
            {
              "name": "TEA Party",
              "count": 10
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1083
            },
            {
              "name": "Vision New Zealand",
              "count": 15
            }
          ]
        }
      },
      {
        "name": "BAY OF PLENTY",
        "candidate": {
          "informal": 605,
          "votes": [
            {
              "name": "CARLEY, Bruce",
              "count": 2068
            },
            {
              "name": "COKER, Christopher",
              "count": 690
            },
            {
              "name": "COLMORE, Margaret",
              "count": 827
            },
            {
              "name": "DEVERY, Sharon",
              "count": 103
            },
            {
              "name": "HUGGINS, Pete",
              "count": 1678
            },
            {
              "name": "JENKINS, Chris",
              "count": 957
            },
            {
              "name": "LAWRENCE, Tricia Jane",
              "count": 963
            },
            {
              "name": "MONCUR, Angela",
              "count": 640
            },
            {
              "name": "MULLER, Todd",
              "count": 20046
            },
            {
              "name": "WARREN-CLARK, Angie",
              "count": 16631
            }
          ]
        },
        "party": {
          "informal": 250,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4571
            },
            {
              "name": "Advance NZ",
              "count": 712
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 244
            },
            {
              "name": "Green Party",
              "count": 2159
            },
            {
              "name": "HeartlandNZ",
              "count": 12
            },
            {
              "name": "Labour Party",
              "count": 19398
            },
            {
              "name": "Māori Party",
              "count": 195
            },
            {
              "name": "National Party",
              "count": 14758
            },
            {
              "name": "New Conservative",
              "count": 972
            },
            {
              "name": "New Zealand First Party",
              "count": 1337
            },
            {
              "name": "NZ Outdoors Party",
              "count": 42
            },
            {
              "name": "ONE Party",
              "count": 83
            },
            {
              "name": "Social Credit",
              "count": 10
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 31
            },
            {
              "name": "TEA Party",
              "count": 11
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 754
            },
            {
              "name": "Vision New Zealand",
              "count": 29
            }
          ]
        }
      },
      {
        "name": "BOTANY",
        "candidate": {
          "informal": 1264,
          "votes": [
            {
              "name": "CHEN, Naisi",
              "count": 15018
            },
            {
              "name": "DE BOER, Dieuwe",
              "count": 624
            },
            {
              "name": "FLEMING, Peter",
              "count": 358
            },
            {
              "name": "LUXON, Christopher",
              "count": 19017
            },
            {
              "name": "SMITH, Damien",
              "count": 1236
            }
          ]
        },
        "party": {
          "informal": 261,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2563
            },
            {
              "name": "Advance NZ",
              "count": 159
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 76
            },
            {
              "name": "Green Party",
              "count": 1236
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 17900
            },
            {
              "name": "Māori Party",
              "count": 70
            },
            {
              "name": "National Party",
              "count": 13970
            },
            {
              "name": "New Conservative",
              "count": 536
            },
            {
              "name": "New Zealand First Party",
              "count": 541
            },
            {
              "name": "NZ Outdoors Party",
              "count": 15
            },
            {
              "name": "ONE Party",
              "count": 85
            },
            {
              "name": "Social Credit",
              "count": 4
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 26
            },
            {
              "name": "TEA Party",
              "count": 152
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 237
            },
            {
              "name": "Vision New Zealand",
              "count": 40
            }
          ]
        }
      },
      {
        "name": "CHRISTCHURCH CENTRAL",
        "candidate": {
          "informal": 699,
          "votes": [
            {
              "name": "ARNEIL, Mark",
              "count": 544
            },
            {
              "name": "BRITNELL, Michael",
              "count": 704
            },
            {
              "name": "CHURCH, Carole",
              "count": 355
            },
            {
              "name": "HORN, Chrys",
              "count": 2566
            },
            {
              "name": "JOHNSON, Abigail",
              "count": 1071
            },
            {
              "name": "LAURIE, Hayden",
              "count": 93
            },
            {
              "name": "PRICE, Benjamin",
              "count": 430
            },
            {
              "name": "STEPHENS, Dale",
              "count": 9833
            },
            {
              "name": "WEBB, Duncan",
              "count": 23931
            },
            {
              "name": "WEBB, Ken",
              "count": 143
            }
          ]
        },
        "party": {
          "informal": 312,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2507
            },
            {
              "name": "Advance NZ",
              "count": 325
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 257
            },
            {
              "name": "Green Party",
              "count": 5168
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 21681
            },
            {
              "name": "Māori Party",
              "count": 142
            },
            {
              "name": "National Party",
              "count": 7921
            },
            {
              "name": "New Conservative",
              "count": 478
            },
            {
              "name": "New Zealand First Party",
              "count": 862
            },
            {
              "name": "NZ Outdoors Party",
              "count": 24
            },
            {
              "name": "ONE Party",
              "count": 92
            },
            {
              "name": "Social Credit",
              "count": 20
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 11
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 887
            },
            {
              "name": "Vision New Zealand",
              "count": 22
            }
          ]
        }
      },
      {
        "name": "CHRISTCHURCH EAST",
        "candidate": {
          "informal": 768,
          "votes": [
            {
              "name": "BERRY, Nikki",
              "count": 2720
            },
            {
              "name": "EASON, Paula Maree",
              "count": 183
            },
            {
              "name": "HOUGHTON, Helen",
              "count": 1050
            },
            {
              "name": "LAMBERT, Paula",
              "count": 843
            },
            {
              "name": "McCONNELL, Glen",
              "count": 395
            },
            {
              "name": "PLATT, Lincoln",
              "count": 7898
            },
            {
              "name": "SEVERIN, Toni",
              "count": 1340
            },
            {
              "name": "STAPLES, Charlotte",
              "count": 215
            },
            {
              "name": "WILLIAMS, Poto",
              "count": 25234
            }
          ]
        },
        "party": {
          "informal": 299,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2511
            },
            {
              "name": "Advance NZ",
              "count": 357
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 256
            },
            {
              "name": "Green Party",
              "count": 3447
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 24563
            },
            {
              "name": "Māori Party",
              "count": 141
            },
            {
              "name": "National Party",
              "count": 6760
            },
            {
              "name": "New Conservative",
              "count": 827
            },
            {
              "name": "New Zealand First Party",
              "count": 1015
            },
            {
              "name": "NZ Outdoors Party",
              "count": 67
            },
            {
              "name": "ONE Party",
              "count": 135
            },
            {
              "name": "Social Credit",
              "count": 20
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 33
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 582
            },
            {
              "name": "Vision New Zealand",
              "count": 32
            }
          ]
        }
      },
      {
        "name": "COROMANDEL",
        "candidate": {
          "informal": 818,
          "votes": [
            {
              "name": "BLOMFIELD, Nathaniel James",
              "count": 17713
            },
            {
              "name": "BRLJEVICH, Tony",
              "count": 855
            },
            {
              "name": "EGLETON, Michael",
              "count": 955
            },
            {
              "name": "GREALEY, Pamela",
              "count": 3357
            },
            {
              "name": "HART, Steven",
              "count": 424
            },
            {
              "name": "HUNTER, Rob",
              "count": 418
            },
            {
              "name": "OLSEN, David Edward",
              "count": 1560
            },
            {
              "name": "SIMPSON, Scott",
              "count": 21218
            },
            {
              "name": "WESSEX, Bob",
              "count": 32
            }
          ]
        },
        "party": {
          "informal": 369,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4602
            },
            {
              "name": "Advance NZ",
              "count": 733
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 171
            },
            {
              "name": "Green Party",
              "count": 2658
            },
            {
              "name": "HeartlandNZ",
              "count": 14
            },
            {
              "name": "Labour Party",
              "count": 20568
            },
            {
              "name": "Māori Party",
              "count": 116
            },
            {
              "name": "National Party",
              "count": 15471
            },
            {
              "name": "New Conservative",
              "count": 772
            },
            {
              "name": "New Zealand First Party",
              "count": 1429
            },
            {
              "name": "NZ Outdoors Party",
              "count": 120
            },
            {
              "name": "ONE Party",
              "count": 103
            },
            {
              "name": "Social Credit",
              "count": 15
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 30
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 401
            },
            {
              "name": "Vision New Zealand",
              "count": 18
            }
          ]
        }
      },
      {
        "name": "DUNEDIN",
        "candidate": {
          "informal": 742,
          "votes": [
            {
              "name": "ANJAIYA-WINDER, Zariah",
              "count": 195
            },
            {
              "name": "BRAZIL, Jack",
              "count": 6916
            },
            {
              "name": "CLARK, David",
              "count": 24140
            },
            {
              "name": "GRIFFITH, Robert",
              "count": 817
            },
            {
              "name": "KING, Solomon",
              "count": 506
            },
            {
              "name": "PETERS, Ben",
              "count": 2480
            },
            {
              "name": "STEELE-MACINTOSH, Callum",
              "count": 1308
            },
            {
              "name": "WOODHOUSE, Michael",
              "count": 8619
            }
          ]
        },
        "party": {
          "informal": 189,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2423
            },
            {
              "name": "Advance NZ",
              "count": 213
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 112
            },
            {
              "name": "Green Party",
              "count": 8165
            },
            {
              "name": "HeartlandNZ",
              "count": 0
            },
            {
              "name": "Labour Party",
              "count": 25377
            },
            {
              "name": "Māori Party",
              "count": 151
            },
            {
              "name": "National Party",
              "count": 6477
            },
            {
              "name": "New Conservative",
              "count": 415
            },
            {
              "name": "New Zealand First Party",
              "count": 978
            },
            {
              "name": "NZ Outdoors Party",
              "count": 35
            },
            {
              "name": "ONE Party",
              "count": 61
            },
            {
              "name": "Social Credit",
              "count": 57
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 42
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1291
            },
            {
              "name": "Vision New Zealand",
              "count": 9
            }
          ]
        }
      },
      {
        "name": "EAST COAST",
        "candidate": {
          "informal": 838,
          "votes": [
            {
              "name": "AKUHATA-BROWN, Meredith",
              "count": 2091
            },
            {
              "name": "ALLAN, Kiri",
              "count": 21420
            },
            {
              "name": "BROWN, Jennie",
              "count": 832
            },
            {
              "name": "KING, Veronica",
              "count": 274
            },
            {
              "name": "NICKERSON, Helena",
              "count": 380
            },
            {
              "name": "TAPSELL, Tania",
              "count": 15089
            },
            {
              "name": "WEBB, Blake",
              "count": 1345
            }
          ]
        },
        "party": {
          "informal": 288,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3305
            },
            {
              "name": "Advance NZ",
              "count": 734
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 183
            },
            {
              "name": "Green Party",
              "count": 2351
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 21412
            },
            {
              "name": "Māori Party",
              "count": 320
            },
            {
              "name": "National Party",
              "count": 11452
            },
            {
              "name": "New Conservative",
              "count": 463
            },
            {
              "name": "New Zealand First Party",
              "count": 1462
            },
            {
              "name": "NZ Outdoors Party",
              "count": 55
            },
            {
              "name": "ONE Party",
              "count": 217
            },
            {
              "name": "Social Credit",
              "count": 13
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 9
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 387
            },
            {
              "name": "Vision New Zealand",
              "count": 109
            }
          ]
        }
      },
      {
        "name": "EAST COAST BAYS",
        "candidate": {
          "informal": 793,
          "votes": [
            {
              "name": "HERNANDEZ, Monina",
              "count": 11702
            },
            {
              "name": "JONES, Dan",
              "count": 2370
            },
            {
              "name": "KOEKEMOER, Marius",
              "count": 172
            },
            {
              "name": "KRUGER, Susanna",
              "count": 275
            },
            {
              "name": "McCOOK, Michael",
              "count": 1397
            },
            {
              "name": "STANFORD, Erica",
              "count": 20466
            },
            {
              "name": "WEBSTER, Matthew",
              "count": 927
            }
          ]
        },
        "party": {
          "informal": 188,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3365
            },
            {
              "name": "Advance NZ",
              "count": 237
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 83
            },
            {
              "name": "Green Party",
              "count": 2353
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 15372
            },
            {
              "name": "Māori Party",
              "count": 65
            },
            {
              "name": "National Party",
              "count": 14658
            },
            {
              "name": "New Conservative",
              "count": 683
            },
            {
              "name": "New Zealand First Party",
              "count": 657
            },
            {
              "name": "NZ Outdoors Party",
              "count": 32
            },
            {
              "name": "ONE Party",
              "count": 37
            },
            {
              "name": "Social Credit",
              "count": 13
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 29
            },
            {
              "name": "TEA Party",
              "count": 161
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 436
            },
            {
              "name": "Vision New Zealand",
              "count": 11
            }
          ]
        }
      },
      {
        "name": "EPSOM",
        "candidate": {
          "informal": 484,
          "votes": [
            {
              "name": "AARON, Faith-Joy",
              "count": 166
            },
            {
              "name": "BELICH, Camilla",
              "count": 10276
            },
            {
              "name": "CHRISTIE, Adriana",
              "count": 889
            },
            {
              "name": "GOLDSMITH, Paul",
              "count": 6397
            },
            {
              "name": "HARRIS, Finn",
              "count": 24
            },
            {
              "name": "JIANG, Noel",
              "count": 337
            },
            {
              "name": "MacDONALD, Kyle",
              "count": 3101
            },
            {
              "name": "PROCHAZKA, Maia",
              "count": 31
            },
            {
              "name": "SEYMOUR, David Breen",
              "count": 19500
            },
            {
              "name": "SUTTON, Norman",
              "count": 231
            },
            {
              "name": "WITHERS, Shannon",
              "count": 72
            }
          ]
        },
        "party": {
          "informal": 203,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4355
            },
            {
              "name": "Advance NZ",
              "count": 147
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 36
            },
            {
              "name": "Green Party",
              "count": 4596
            },
            {
              "name": "HeartlandNZ",
              "count": 6
            },
            {
              "name": "Labour Party",
              "count": 15078
            },
            {
              "name": "Māori Party",
              "count": 108
            },
            {
              "name": "National Party",
              "count": 15668
            },
            {
              "name": "New Conservative",
              "count": 211
            },
            {
              "name": "New Zealand First Party",
              "count": 609
            },
            {
              "name": "NZ Outdoors Party",
              "count": 7
            },
            {
              "name": "ONE Party",
              "count": 27
            },
            {
              "name": "Social Credit",
              "count": 8
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 30
            },
            {
              "name": "TEA Party",
              "count": 112
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 822
            },
            {
              "name": "Vision New Zealand",
              "count": 8
            }
          ]
        }
      },
      {
        "name": "HAMILTON EAST",
        "candidate": {
          "informal": 630,
          "votes": [
            {
              "name": "BENNETT, David",
              "count": 15569
            },
            {
              "name": "BHOOI, Rimu",
              "count": 1676
            },
            {
              "name": "COLEMAN, Matt",
              "count": 67
            },
            {
              "name": "DEEDMAN, Myah",
              "count": 721
            },
            {
              "name": "GIELEN, Jack",
              "count": 28
            },
            {
              "name": "HENRY, Siggi",
              "count": 298
            },
            {
              "name": "HUSBAND, Stuart",
              "count": 368
            },
            {
              "name": "MANDERS, Julie",
              "count": 665
            },
            {
              "name": "MURPHY, Destry",
              "count": 101
            },
            {
              "name": "POCOCK, Naomi",
              "count": 848
            },
            {
              "name": "STRANGE, Jamie",
              "count": 18542
            }
          ]
        },
        "party": {
          "informal": 320,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2744
            },
            {
              "name": "Advance NZ",
              "count": 291
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 126
            },
            {
              "name": "Green Party",
              "count": 3171
            },
            {
              "name": "HeartlandNZ",
              "count": 7
            },
            {
              "name": "Labour Party",
              "count": 19471
            },
            {
              "name": "Māori Party",
              "count": 193
            },
            {
              "name": "National Party",
              "count": 10875
            },
            {
              "name": "New Conservative",
              "count": 796
            },
            {
              "name": "New Zealand First Party",
              "count": 836
            },
            {
              "name": "NZ Outdoors Party",
              "count": 27
            },
            {
              "name": "ONE Party",
              "count": 113
            },
            {
              "name": "Social Credit",
              "count": 14
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 17
            },
            {
              "name": "TEA Party",
              "count": 20
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 868
            },
            {
              "name": "Vision New Zealand",
              "count": 57
            }
          ]
        }
      },
      {
        "name": "HAMILTON WEST",
        "candidate": {
          "informal": 892,
          "votes": [
            {
              "name": "CARGO, Hayden",
              "count": 1024
            },
            {
              "name": "DU PLOOY, Rudi",
              "count": 615
            },
            {
              "name": "HETA, Te Rongopai",
              "count": 223
            },
            {
              "name": "MACINDOE, Tim",
              "count": 14436
            },
            {
              "name": "MANSFIELD, Chloe",
              "count": 357
            },
            {
              "name": "ORMSBY-KINGI, Cherie",
              "count": 490
            },
            {
              "name": "SHARMA, Gaurav",
              "count": 20703
            },
            {
              "name": "WELDON, Roger",
              "count": 1186
            }
          ]
        },
        "party": {
          "informal": 300,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2779
            },
            {
              "name": "Advance NZ",
              "count": 390
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 214
            },
            {
              "name": "Green Party",
              "count": 2279
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 21136
            },
            {
              "name": "Māori Party",
              "count": 212
            },
            {
              "name": "National Party",
              "count": 10161
            },
            {
              "name": "New Conservative",
              "count": 824
            },
            {
              "name": "New Zealand First Party",
              "count": 1029
            },
            {
              "name": "NZ Outdoors Party",
              "count": 44
            },
            {
              "name": "ONE Party",
              "count": 133
            },
            {
              "name": "Social Credit",
              "count": 15
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 21
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 837
            },
            {
              "name": "Vision New Zealand",
              "count": 60
            }
          ]
        }
      },
      {
        "name": "HUTT SOUTH",
        "candidate": {
          "informal": 560,
          "votes": [
            {
              "name": "ANDERSEN, Ginny",
              "count": 22453
            },
            {
              "name": "BEARMAN-RIEDEL, Wilf",
              "count": 115
            },
            {
              "name": "BINDRA, Mahesh",
              "count": 316
            },
            {
              "name": "BISHOP, Chris",
              "count": 18676
            },
            {
              "name": "DAKEN, Mishaela",
              "count": 302
            },
            {
              "name": "EARP, Roger",
              "count": 562
            },
            {
              "name": "McINTOSH, Richard",
              "count": 1171
            },
            {
              "name": "PARKINS, Andy",
              "count": 330
            },
            {
              "name": "SHANLY, Edward",
              "count": 192
            },
            {
              "name": "WINIATA, Paris",
              "count": 201
            },
            {
              "name": "WYLIE-VAN EERD, Ben",
              "count": 590
            }
          ]
        },
        "party": {
          "informal": 255,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2568
            },
            {
              "name": "Advance NZ",
              "count": 309
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 112
            },
            {
              "name": "Green Party",
              "count": 4301
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 25159
            },
            {
              "name": "Māori Party",
              "count": 201
            },
            {
              "name": "National Party",
              "count": 10033
            },
            {
              "name": "New Conservative",
              "count": 528
            },
            {
              "name": "New Zealand First Party",
              "count": 1071
            },
            {
              "name": "NZ Outdoors Party",
              "count": 38
            },
            {
              "name": "ONE Party",
              "count": 201
            },
            {
              "name": "Social Credit",
              "count": 9
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 30
            },
            {
              "name": "TEA Party",
              "count": 25
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 986
            },
            {
              "name": "Vision New Zealand",
              "count": 51
            }
          ]
        }
      },
      {
        "name": "ILAM",
        "candidate": {
          "informal": 835,
          "votes": [
            {
              "name": "BENNETT, David",
              "count": 3230
            },
            {
              "name": "BROWNLEE, Gerry",
              "count": 16362
            },
            {
              "name": "GILBERT, Paul",
              "count": 1956
            },
            {
              "name": "JENSEN-WARREN, Heidi",
              "count": 286
            },
            {
              "name": "PALLETT, Sarah",
              "count": 19825
            },
            {
              "name": "PENGELLY, Toni",
              "count": 247
            },
            {
              "name": "WALMISLEY, Simon",
              "count": 661
            }
          ]
        },
        "party": {
          "informal": 248,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3807
            },
            {
              "name": "Advance NZ",
              "count": 210
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 77
            },
            {
              "name": "Green Party",
              "count": 4489
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 19497
            },
            {
              "name": "Māori Party",
              "count": 108
            },
            {
              "name": "National Party",
              "count": 12861
            },
            {
              "name": "New Conservative",
              "count": 533
            },
            {
              "name": "New Zealand First Party",
              "count": 769
            },
            {
              "name": "NZ Outdoors Party",
              "count": 45
            },
            {
              "name": "ONE Party",
              "count": 54
            },
            {
              "name": "Social Credit",
              "count": 20
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 41
            },
            {
              "name": "TEA Party",
              "count": 21
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 856
            },
            {
              "name": "Vision New Zealand",
              "count": 6
            }
          ]
        }
      },
      {
        "name": "INVERCARGILL",
        "candidate": {
          "informal": 735,
          "votes": [
            {
              "name": "AROHA, Winsome",
              "count": 206
            },
            {
              "name": "CRAIG, Liz",
              "count": 17705
            },
            {
              "name": "FRANCIS, Rochelle Monique",
              "count": 1137
            },
            {
              "name": "GUNN, Joshua",
              "count": 735
            },
            {
              "name": "HAYDEN, Zy",
              "count": 102
            },
            {
              "name": "HONISS, Joshua",
              "count": 757
            },
            {
              "name": "ROHLOFF, Kurt",
              "count": 417
            },
            {
              "name": "SIMMONDS, Penny",
              "count": 17929
            },
            {
              "name": "WALKER, Basil",
              "count": 187
            },
            {
              "name": "WEST, Jackie",
              "count": 237
            }
          ]
        },
        "party": {
          "informal": 308,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3846
            },
            {
              "name": "Advance NZ",
              "count": 406
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 220
            },
            {
              "name": "Green Party",
              "count": 1386
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 19252
            },
            {
              "name": "Māori Party",
              "count": 76
            },
            {
              "name": "National Party",
              "count": 11996
            },
            {
              "name": "New Conservative",
              "count": 862
            },
            {
              "name": "New Zealand First Party",
              "count": 1218
            },
            {
              "name": "NZ Outdoors Party",
              "count": 44
            },
            {
              "name": "ONE Party",
              "count": 158
            },
            {
              "name": "Social Credit",
              "count": 81
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 20
            },
            {
              "name": "TEA Party",
              "count": 16
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 433
            },
            {
              "name": "Vision New Zealand",
              "count": 8
            }
          ]
        }
      },
      {
        "name": "KAIKŌURA",
        "candidate": {
          "informal": 629,
          "votes": [
            {
              "name": "ARBUCKLE, Jamie",
              "count": 2180
            },
            {
              "name": "BHAVIK, Prince",
              "count": 14
            },
            {
              "name": "EVANS, Richard",
              "count": 1833
            },
            {
              "name": "FLIGHT, Matt",
              "count": 15774
            },
            {
              "name": "GREENSLADE, David John",
              "count": 766
            },
            {
              "name": "HOWARD, Ted",
              "count": 93
            },
            {
              "name": "McCASKEY, John",
              "count": 173
            },
            {
              "name": "McCUBBIN, Richard",
              "count": 2377
            },
            {
              "name": "MOORE, John Donald",
              "count": 327
            },
            {
              "name": "MORGAN, Darlene",
              "count": 369
            },
            {
              "name": "ROMANA, Lisa Claire",
              "count": 357
            },
            {
              "name": "SMITH, Stuart",
              "count": 18069
            }
          ]
        },
        "party": {
          "informal": 251,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4917
            },
            {
              "name": "Advance NZ",
              "count": 390
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 139
            },
            {
              "name": "Green Party",
              "count": 2282
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 19159
            },
            {
              "name": "Māori Party",
              "count": 70
            },
            {
              "name": "National Party",
              "count": 13007
            },
            {
              "name": "New Conservative",
              "count": 798
            },
            {
              "name": "New Zealand First Party",
              "count": 1247
            },
            {
              "name": "NZ Outdoors Party",
              "count": 124
            },
            {
              "name": "ONE Party",
              "count": 246
            },
            {
              "name": "Social Credit",
              "count": 35
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 27
            },
            {
              "name": "TEA Party",
              "count": 7
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 493
            },
            {
              "name": "Vision New Zealand",
              "count": 10
            }
          ]
        }
      },
      {
        "name": "KAIPARA KI MAHURANGI",
        "candidate": {
          "informal": 730,
          "votes": [
            {
              "name": "BERRY, Pauline",
              "count": 686
            },
            {
              "name": "BROWN, Zephyr",
              "count": 2904
            },
            {
              "name": "FORD, David",
              "count": 248
            },
            {
              "name": "HOULBROOKE, Beth",
              "count": 2989
            },
            {
              "name": "LUBECK, Marja",
              "count": 15967
            },
            {
              "name": "NEYLON, Callan",
              "count": 198
            },
            {
              "name": "PENK, Chris",
              "count": 20402
            },
            {
              "name": "REEVES, Richard John",
              "count": 208
            },
            {
              "name": "STEELE, Brenda",
              "count": 1220
            }
          ]
        },
        "party": {
          "informal": 191,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 5158
            },
            {
              "name": "Advance NZ",
              "count": 671
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 146
            },
            {
              "name": "Green Party",
              "count": 2887
            },
            {
              "name": "HeartlandNZ",
              "count": 7
            },
            {
              "name": "Labour Party",
              "count": 18451
            },
            {
              "name": "Māori Party",
              "count": 126
            },
            {
              "name": "National Party",
              "count": 15547
            },
            {
              "name": "New Conservative",
              "count": 682
            },
            {
              "name": "New Zealand First Party",
              "count": 1181
            },
            {
              "name": "NZ Outdoors Party",
              "count": 59
            },
            {
              "name": "ONE Party",
              "count": 142
            },
            {
              "name": "Social Credit",
              "count": 41
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 54
            },
            {
              "name": "TEA Party",
              "count": 16
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 591
            },
            {
              "name": "Vision New Zealand",
              "count": 10
            }
          ]
        }
      },
      {
        "name": "KELSTON",
        "candidate": {
          "informal": 908,
          "votes": [
            {
              "name": "BEERAM, Bala",
              "count": 6517
            },
            {
              "name": "BRETT, Kevin",
              "count": 57
            },
            {
              "name": "DEGIA-PALA, Anne",
              "count": 576
            },
            {
              "name": "FRASER, Jessamine",
              "count": 2365
            },
            {
              "name": "JOBSIS, Jason",
              "count": 33
            },
            {
              "name": "KUMEROA, Maureen",
              "count": 288
            },
            {
              "name": "LAVAKA TANGIPA, Faye",
              "count": 177
            },
            {
              "name": "LYE, Jeff Eric",
              "count": 425
            },
            {
              "name": "PERCIVAL, Matthew",
              "count": 1195
            },
            {
              "name": "SEPULONI, Carmel Jean",
              "count": 22177
            },
            {
              "name": "TILDSLEY, Leao",
              "count": 594
            }
          ]
        },
        "party": {
          "informal": 367,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 1543
            },
            {
              "name": "Advance NZ",
              "count": 294
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 135
            },
            {
              "name": "Green Party",
              "count": 2929
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 22081
            },
            {
              "name": "Māori Party",
              "count": 185
            },
            {
              "name": "National Party",
              "count": 6121
            },
            {
              "name": "New Conservative",
              "count": 484
            },
            {
              "name": "New Zealand First Party",
              "count": 915
            },
            {
              "name": "NZ Outdoors Party",
              "count": 14
            },
            {
              "name": "ONE Party",
              "count": 109
            },
            {
              "name": "Social Credit",
              "count": 14
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 13
            },
            {
              "name": "TEA Party",
              "count": 61
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 408
            },
            {
              "name": "Vision New Zealand",
              "count": 20
            }
          ]
        }
      },
      {
        "name": "MANA",
        "candidate": {
          "informal": 991,
          "votes": [
            {
              "name": "BARRY, Roy",
              "count": 522
            },
            {
              "name": "CARTWRIGHT, Helen",
              "count": 360
            },
            {
              "name": "EDMONDS, Barbara",
              "count": 26122
            },
            {
              "name": "GOODE, Richard",
              "count": 69
            },
            {
              "name": "HARAWIRA, Stephanie",
              "count": 378
            },
            {
              "name": "HAYES, Jo",
              "count": 9878
            },
            {
              "name": "ILES, Mike",
              "count": 64
            },
            {
              "name": "LOGIE, Jan",
              "count": 4135
            },
            {
              "name": "MARSHALL, Gordon",
              "count": 119
            },
            {
              "name": "PONDER, Edward",
              "count": 369
            },
            {
              "name": "WALKER, Michael",
              "count": 120
            }
          ]
        },
        "party": {
          "informal": 278,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2403
            },
            {
              "name": "Advance NZ",
              "count": 349
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 148
            },
            {
              "name": "Green Party",
              "count": 4187
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 25271
            },
            {
              "name": "Māori Party",
              "count": 242
            },
            {
              "name": "National Party",
              "count": 7976
            },
            {
              "name": "New Conservative",
              "count": 425
            },
            {
              "name": "New Zealand First Party",
              "count": 954
            },
            {
              "name": "NZ Outdoors Party",
              "count": 22
            },
            {
              "name": "ONE Party",
              "count": 233
            },
            {
              "name": "Social Credit",
              "count": 12
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 20
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 857
            },
            {
              "name": "Vision New Zealand",
              "count": 55
            }
          ]
        }
      },
      {
        "name": "MĀNGERE",
        "candidate": {
          "informal": 1255,
          "votes": [
            {
              "name": "ALAILIMA, Fuiavailili",
              "count": 866
            },
            {
              "name": "CROWTHER, Grant",
              "count": 57
            },
            {
              "name": "JONES, Leslie",
              "count": 151
            },
            {
              "name": "LOHENI, Agnes",
              "count": 3708
            },
            {
              "name": "NOOROA, Wayne Tetou",
              "count": 166
            },
            {
              "name": "SIO, Aupito William",
              "count": 23104
            },
            {
              "name": "SYKES, Peter Brian",
              "count": 1930
            }
          ]
        },
        "party": {
          "informal": 400,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 483
            },
            {
              "name": "Advance NZ",
              "count": 333
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 128
            },
            {
              "name": "Green Party",
              "count": 1246
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 24167
            },
            {
              "name": "Māori Party",
              "count": 280
            },
            {
              "name": "National Party",
              "count": 2846
            },
            {
              "name": "New Conservative",
              "count": 540
            },
            {
              "name": "New Zealand First Party",
              "count": 862
            },
            {
              "name": "NZ Outdoors Party",
              "count": 9
            },
            {
              "name": "ONE Party",
              "count": 79
            },
            {
              "name": "Social Credit",
              "count": 11
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 12
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 127
            },
            {
              "name": "Vision New Zealand",
              "count": 102
            }
          ]
        }
      },
      {
        "name": "MANUREWA",
        "candidate": {
          "informal": 1181,
          "votes": [
            {
              "name": "BERNARDO, Wella",
              "count": 134
            },
            {
              "name": "HALL, John",
              "count": 968
            },
            {
              "name": "JACKSON, Linda",
              "count": 313
            },
            {
              "name": "LINAKER, Luella",
              "count": 325
            },
            {
              "name": "PAHULU, Mote",
              "count": 814
            },
            {
              "name": "SAMARAKONE, Nuwi",
              "count": 3636
            },
            {
              "name": "SINGH, Rattan",
              "count": 198
            },
            {
              "name": "VANO, Lourdes",
              "count": 963
            },
            {
              "name": "VERMUNT, Annalucia",
              "count": 28
            },
            {
              "name": "WILCOX, Sonny",
              "count": 305
            },
            {
              "name": "WILLIAMS, Arena",
              "count": 20815
            }
          ]
        },
        "party": {
          "informal": 515,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 519
            },
            {
              "name": "Advance NZ",
              "count": 250
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 136
            },
            {
              "name": "Green Party",
              "count": 907
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 22137
            },
            {
              "name": "Māori Party",
              "count": 245
            },
            {
              "name": "National Party",
              "count": 3904
            },
            {
              "name": "New Conservative",
              "count": 505
            },
            {
              "name": "New Zealand First Party",
              "count": 861
            },
            {
              "name": "NZ Outdoors Party",
              "count": 9
            },
            {
              "name": "ONE Party",
              "count": 80
            },
            {
              "name": "Social Credit",
              "count": 6
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 14
            },
            {
              "name": "TEA Party",
              "count": 37
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 139
            },
            {
              "name": "Vision New Zealand",
              "count": 196
            }
          ]
        }
      },
      {
        "name": "MAUNGAKIEKIE",
        "candidate": {
          "informal": 1024,
          "votes": [
            {
              "name": "FERGUSSON, Tommy",
              "count": 1225
            },
            {
              "name": "HOLDER, Philip",
              "count": 513
            },
            {
              "name": "LEE, Denise",
              "count": 15597
            },
            {
              "name": "MENENDEZ MARCH, Ricardo",
              "count": 2666
            },
            {
              "name": "RADHAKRISHNAN, Priyanca",
              "count": 16232
            }
          ]
        },
        "party": {
          "informal": 306,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2660
            },
            {
              "name": "Advance NZ",
              "count": 206
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 99
            },
            {
              "name": "Green Party",
              "count": 3403
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 18895
            },
            {
              "name": "Māori Party",
              "count": 159
            },
            {
              "name": "National Party",
              "count": 9668
            },
            {
              "name": "New Conservative",
              "count": 416
            },
            {
              "name": "New Zealand First Party",
              "count": 780
            },
            {
              "name": "NZ Outdoors Party",
              "count": 18
            },
            {
              "name": "ONE Party",
              "count": 66
            },
            {
              "name": "Social Credit",
              "count": 7
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 82
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 697
            },
            {
              "name": "Vision New Zealand",
              "count": 47
            }
          ]
        }
      },
      {
        "name": "MT ALBERT",
        "candidate": {
          "informal": 507,
          "votes": [
            {
              "name": "ARDERN, Jacinda",
              "count": 29238
            },
            {
              "name": "LEE, Melissa",
              "count": 7992
            },
            {
              "name": "LORD, Cameron",
              "count": 903
            },
            {
              "name": "REURICH, Daniel",
              "count": 316
            },
            {
              "name": "VAN DEN HEUVEL, Anthony Joseph J",
              "count": 87
            },
            {
              "name": "WIJOHN, Luke",
              "count": 2299
            }
          ]
        },
        "party": {
          "informal": 258,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2485
            },
            {
              "name": "Advance NZ",
              "count": 141
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 83
            },
            {
              "name": "Green Party",
              "count": 8311
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 20265
            },
            {
              "name": "Māori Party",
              "count": 205
            },
            {
              "name": "National Party",
              "count": 7769
            },
            {
              "name": "New Conservative",
              "count": 241
            },
            {
              "name": "New Zealand First Party",
              "count": 760
            },
            {
              "name": "NZ Outdoors Party",
              "count": 16
            },
            {
              "name": "ONE Party",
              "count": 34
            },
            {
              "name": "Social Credit",
              "count": 4
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 22
            },
            {
              "name": "TEA Party",
              "count": 41
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1048
            },
            {
              "name": "Vision New Zealand",
              "count": 11
            }
          ]
        }
      },
      {
        "name": "MT ROSKILL",
        "candidate": {
          "informal": 912,
          "votes": [
            {
              "name": "CHOKSI, Vishal",
              "count": 254
            },
            {
              "name": "FREDERIKSON, Warwick",
              "count": 45
            },
            {
              "name": "GHAHRAMAN, Golriz",
              "count": 2261
            },
            {
              "name": "HALL, Cliff",
              "count": 87
            },
            {
              "name": "HOOD, Alister",
              "count": 610
            },
            {
              "name": "JOHNSTON, Chris",
              "count": 1371
            },
            {
              "name": "PARMAR, Parmjeet",
              "count": 9197
            },
            {
              "name": "PEHI, Charlene Roxanne",
              "count": 143
            },
            {
              "name": "SHARMA, Anil",
              "count": 257
            },
            {
              "name": "WOOD, Michael Philip",
              "count": 23050
            }
          ]
        },
        "party": {
          "informal": 321,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 1904
            },
            {
              "name": "Advance NZ",
              "count": 198
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 68
            },
            {
              "name": "Green Party",
              "count": 3060
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 21436
            },
            {
              "name": "Māori Party",
              "count": 142
            },
            {
              "name": "National Party",
              "count": 9328
            },
            {
              "name": "New Conservative",
              "count": 459
            },
            {
              "name": "New Zealand First Party",
              "count": 731
            },
            {
              "name": "NZ Outdoors Party",
              "count": 13
            },
            {
              "name": "ONE Party",
              "count": 103
            },
            {
              "name": "Social Credit",
              "count": 18
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 18
            },
            {
              "name": "TEA Party",
              "count": 163
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 499
            },
            {
              "name": "Vision New Zealand",
              "count": 19
            }
          ]
        }
      },
      {
        "name": "NAPIER",
        "candidate": {
          "informal": 722,
          "votes": [
            {
              "name": "BURNSIDE, Deborah",
              "count": 1032
            },
            {
              "name": "CROW, James",
              "count": 1958
            },
            {
              "name": "GASKIN, Camden",
              "count": 92
            },
            {
              "name": "KENDALL, Judy",
              "count": 962
            },
            {
              "name": "NASH, Stuart",
              "count": 21325
            },
            {
              "name": "NIMON, Katie",
              "count": 15469
            },
            {
              "name": "SMITH, John Clive",
              "count": 197
            }
          ]
        },
        "party": {
          "informal": 287,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3410
            },
            {
              "name": "Advance NZ",
              "count": 355
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 179
            },
            {
              "name": "Green Party",
              "count": 2444
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 21245
            },
            {
              "name": "Māori Party",
              "count": 118
            },
            {
              "name": "National Party",
              "count": 11718
            },
            {
              "name": "New Conservative",
              "count": 611
            },
            {
              "name": "New Zealand First Party",
              "count": 899
            },
            {
              "name": "NZ Outdoors Party",
              "count": 39
            },
            {
              "name": "ONE Party",
              "count": 72
            },
            {
              "name": "Social Credit",
              "count": 13
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 21
            },
            {
              "name": "TEA Party",
              "count": 11
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 537
            },
            {
              "name": "Vision New Zealand",
              "count": 24
            }
          ]
        }
      },
      {
        "name": "NELSON",
        "candidate": {
          "informal": 554,
          "votes": [
            {
              "name": "BAILLIE, Chris",
              "count": 1320
            },
            {
              "name": "BOYACK, Rachel",
              "count": 21376
            },
            {
              "name": "CLAASSENS, Deon",
              "count": 111
            },
            {
              "name": "DYER, Bruce",
              "count": 50
            },
            {
              "name": "GREY, Sue",
              "count": 679
            },
            {
              "name": "GUTSCHLAG, Simon",
              "count": 317
            },
            {
              "name": "HARRIS, Ben",
              "count": 393
            },
            {
              "name": "POTTINGER, Mathew",
              "count": 751
            },
            {
              "name": "SMITH, Nick",
              "count": 16851
            },
            {
              "name": "STALLARD, Aaron",
              "count": 2046
            }
          ]
        },
        "party": {
          "informal": 276,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3639
            },
            {
              "name": "Advance NZ",
              "count": 480
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 144
            },
            {
              "name": "Green Party",
              "count": 4028
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 23943
            },
            {
              "name": "Māori Party",
              "count": 90
            },
            {
              "name": "National Party",
              "count": 9185
            },
            {
              "name": "New Conservative",
              "count": 690
            },
            {
              "name": "New Zealand First Party",
              "count": 841
            },
            {
              "name": "NZ Outdoors Party",
              "count": 173
            },
            {
              "name": "ONE Party",
              "count": 180
            },
            {
              "name": "Social Credit",
              "count": 33
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 17
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 775
            },
            {
              "name": "Vision New Zealand",
              "count": 130
            }
          ]
        }
      },
      {
        "name": "NEW LYNN",
        "candidate": {
          "informal": 831,
          "votes": [
            {
              "name": "ABEL, Steve",
              "count": 3701
            },
            {
              "name": "BLANCHFIELD, Shawn Michael",
              "count": 1447
            },
            {
              "name": "DAVIE, Paul",
              "count": 87
            },
            {
              "name": "ER, Lisa",
              "count": 118
            },
            {
              "name": "GORE, Rob",
              "count": 651
            },
            {
              "name": "MALIK, Khurram Shahid",
              "count": 121
            },
            {
              "name": "O’BRIEN, Victoria",
              "count": 582
            },
            {
              "name": "OLIVER, Steve",
              "count": 481
            },
            {
              "name": "PATEL, Smitaben",
              "count": 169
            },
            {
              "name": "RUSSELL, Deborah",
              "count": 23352
            },
            {
              "name": "WHYTE, Lisa",
              "count": 10218
            }
          ]
        },
        "party": {
          "informal": 253,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2643
            },
            {
              "name": "Advance NZ",
              "count": 368
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 103
            },
            {
              "name": "Green Party",
              "count": 4794
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 22339
            },
            {
              "name": "Māori Party",
              "count": 191
            },
            {
              "name": "National Party",
              "count": 9166
            },
            {
              "name": "New Conservative",
              "count": 487
            },
            {
              "name": "New Zealand First Party",
              "count": 976
            },
            {
              "name": "NZ Outdoors Party",
              "count": 36
            },
            {
              "name": "ONE Party",
              "count": 82
            },
            {
              "name": "Social Credit",
              "count": 27
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 30
            },
            {
              "name": "TEA Party",
              "count": 81
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 546
            },
            {
              "name": "Vision New Zealand",
              "count": 16
            }
          ]
        }
      },
      {
        "name": "NEW PLYMOUTH",
        "candidate": {
          "informal": 769,
          "votes": [
            {
              "name": "BENNETT, Glen",
              "count": 21030
            },
            {
              "name": "CHONG, Murray",
              "count": 1347
            },
            {
              "name": "KANE, Rusty",
              "count": 325
            },
            {
              "name": "LAUDERDALE, Kath",
              "count": 115
            },
            {
              "name": "THURSTON CROW, Dan",
              "count": 745
            },
            {
              "name": "WOOD, Rowena",
              "count": 601
            },
            {
              "name": "XIAO, Ada",
              "count": 864
            },
            {
              "name": "YOUNG, Jonathan",
              "count": 18475
            }
          ]
        },
        "party": {
          "informal": 306,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3534
            },
            {
              "name": "Advance NZ",
              "count": 567
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 258
            },
            {
              "name": "Green Party",
              "count": 2044
            },
            {
              "name": "HeartlandNZ",
              "count": 10
            },
            {
              "name": "Labour Party",
              "count": 21394
            },
            {
              "name": "Māori Party",
              "count": 212
            },
            {
              "name": "National Party",
              "count": 13336
            },
            {
              "name": "New Conservative",
              "count": 765
            },
            {
              "name": "New Zealand First Party",
              "count": 1001
            },
            {
              "name": "NZ Outdoors Party",
              "count": 40
            },
            {
              "name": "ONE Party",
              "count": 85
            },
            {
              "name": "Social Credit",
              "count": 53
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 20
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 787
            },
            {
              "name": "Vision New Zealand",
              "count": 35
            }
          ]
        }
      },
      {
        "name": "NORTH SHORE",
        "candidate": {
          "informal": 663,
          "votes": [
            {
              "name": "BREWER, Mike",
              "count": 791
            },
            {
              "name": "DICK, Sue",
              "count": 138
            },
            {
              "name": "HUUSKO, Mari",
              "count": 87
            },
            {
              "name": "KEARNEY, Nick",
              "count": 1596
            },
            {
              "name": "NAVOT, Shai",
              "count": 1493
            },
            {
              "name": "OSBORN, Megan Elspeth",
              "count": 201
            },
            {
              "name": "RAWLINGS, Liz",
              "count": 2595
            },
            {
              "name": "UDANGA, Romy",
              "count": 15468
            },
            {
              "name": "WATTS, Simon",
              "count": 19202
            }
          ]
        },
        "party": {
          "informal": 178,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4172
            },
            {
              "name": "Advance NZ",
              "count": 230
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 66
            },
            {
              "name": "Green Party",
              "count": 3530
            },
            {
              "name": "HeartlandNZ",
              "count": 1
            },
            {
              "name": "Labour Party",
              "count": 17498
            },
            {
              "name": "Māori Party",
              "count": 104
            },
            {
              "name": "National Party",
              "count": 14411
            },
            {
              "name": "New Conservative",
              "count": 511
            },
            {
              "name": "New Zealand First Party",
              "count": 802
            },
            {
              "name": "NZ Outdoors Party",
              "count": 26
            },
            {
              "name": "ONE Party",
              "count": 32
            },
            {
              "name": "Social Credit",
              "count": 5
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 38
            },
            {
              "name": "TEA Party",
              "count": 80
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 806
            },
            {
              "name": "Vision New Zealand",
              "count": 7
            }
          ]
        }
      },
      {
        "name": "NORTHCOTE",
        "candidate": {
          "informal": 756,
          "votes": [
            {
              "name": "BIDOIS, Dan",
              "count": 16552
            },
            {
              "name": "DYET, Bill",
              "count": 601
            },
            {
              "name": "FAIRLEY, Natasha",
              "count": 1905
            },
            {
              "name": "HALBERT, Shanan",
              "count": 19086
            },
            {
              "name": "KRONFELD, Timothy Paul",
              "count": 806
            },
            {
              "name": "READ, Bevan",
              "count": 156
            },
            {
              "name": "USMANI, Lynn",
              "count": 114
            }
          ]
        },
        "party": {
          "informal": 212,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2935
            },
            {
              "name": "Advance NZ",
              "count": 231
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 95
            },
            {
              "name": "Green Party",
              "count": 3482
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 19860
            },
            {
              "name": "Māori Party",
              "count": 93
            },
            {
              "name": "National Party",
              "count": 11086
            },
            {
              "name": "New Conservative",
              "count": 536
            },
            {
              "name": "New Zealand First Party",
              "count": 774
            },
            {
              "name": "NZ Outdoors Party",
              "count": 26
            },
            {
              "name": "ONE Party",
              "count": 44
            },
            {
              "name": "Social Credit",
              "count": 10
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 53
            },
            {
              "name": "TEA Party",
              "count": 89
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 741
            },
            {
              "name": "Vision New Zealand",
              "count": 13
            }
          ]
        }
      },
      {
        "name": "NORTHLAND",
        "candidate": {
          "informal": 646,
          "votes": [
            {
              "name": "BARFOOTE, Trevor",
              "count": 686
            },
            {
              "name": "CAMERON, Mark Stuart",
              "count": 1279
            },
            {
              "name": "FLUTEY, Brad",
              "count": 82
            },
            {
              "name": "JEREMIAH, Helen",
              "count": 326
            },
            {
              "name": "JONES, Shane Geoffrey",
              "count": 5119
            },
            {
              "name": "KING, Matt",
              "count": 16903
            },
            {
              "name": "MITCALFE, Michele",
              "count": 219
            },
            {
              "name": "MITCHELL, Nathan",
              "count": 847
            },
            {
              "name": "PRIME, Willow-Jean",
              "count": 17066
            },
            {
              "name": "SHAW, Mike",
              "count": 480
            },
            {
              "name": "TANA HOFF-NIELSEN, Darleen",
              "count": 1749
            },
            {
              "name": "XIAO-COLLEY, Sophia Yuhui",
              "count": 28
            }
          ]
        },
        "party": {
          "informal": 363,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4326
            },
            {
              "name": "Advance NZ",
              "count": 949
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 294
            },
            {
              "name": "Green Party",
              "count": 2772
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 19997
            },
            {
              "name": "Māori Party",
              "count": 181
            },
            {
              "name": "National Party",
              "count": 12496
            },
            {
              "name": "New Conservative",
              "count": 842
            },
            {
              "name": "New Zealand First Party",
              "count": 2651
            },
            {
              "name": "NZ Outdoors Party",
              "count": 106
            },
            {
              "name": "ONE Party",
              "count": 248
            },
            {
              "name": "Social Credit",
              "count": 69
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 37
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 460
            },
            {
              "name": "Vision New Zealand",
              "count": 30
            }
          ]
        }
      },
      {
        "name": "ŌHĀRIU",
        "candidate": {
          "informal": 596,
          "votes": [
            {
              "name": "CAWOOD, Allan",
              "count": 197
            },
            {
              "name": "FITZPATRICK, Sean",
              "count": 809
            },
            {
              "name": "HAMMOND, Jessica",
              "count": 4443
            },
            {
              "name": "HUDSON, Brett",
              "count": 10976
            },
            {
              "name": "LYNCH, Philip M",
              "count": 357
            },
            {
              "name": "MARTIN, Tracey",
              "count": 928
            },
            {
              "name": "O'CONNOR, Greg",
              "count": 22937
            },
            {
              "name": "RANTA, John",
              "count": 2221
            },
            {
              "name": "SMITH, Jolene",
              "count": 133
            },
            {
              "name": "WALSH, Liam",
              "count": 49
            }
          ]
        },
        "party": {
          "informal": 209,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2662
            },
            {
              "name": "Advance NZ",
              "count": 149
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 80
            },
            {
              "name": "Green Party",
              "count": 5940
            },
            {
              "name": "HeartlandNZ",
              "count": 1
            },
            {
              "name": "Labour Party",
              "count": 22282
            },
            {
              "name": "Māori Party",
              "count": 153
            },
            {
              "name": "National Party",
              "count": 9732
            },
            {
              "name": "New Conservative",
              "count": 375
            },
            {
              "name": "New Zealand First Party",
              "count": 824
            },
            {
              "name": "NZ Outdoors Party",
              "count": 15
            },
            {
              "name": "ONE Party",
              "count": 143
            },
            {
              "name": "Social Credit",
              "count": 8
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 19
            },
            {
              "name": "TEA Party",
              "count": 12
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1221
            },
            {
              "name": "Vision New Zealand",
              "count": 17
            }
          ]
        }
      },
      {
        "name": "ŌTAKI",
        "candidate": {
          "informal": 850,
          "votes": [
            {
              "name": "COSTLEY, Tim",
              "count": 18879
            },
            {
              "name": "FRAUENSTEIN, Martin",
              "count": 496
            },
            {
              "name": "GRATTAN, Wayne Desmond",
              "count": 918
            },
            {
              "name": "KAY, Michael",
              "count": 241
            },
            {
              "name": "LONG, Bernard",
              "count": 2135
            },
            {
              "name": "NGOBI, Terisa",
              "count": 21867
            },
            {
              "name": "SEALA, Pisa",
              "count": 163
            },
            {
              "name": "VICKERS, Amanda",
              "count": 192
            }
          ]
        },
        "party": {
          "informal": 300,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3284
            },
            {
              "name": "Advance NZ",
              "count": 372
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 141
            },
            {
              "name": "Green Party",
              "count": 2947
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 24023
            },
            {
              "name": "Māori Party",
              "count": 141
            },
            {
              "name": "National Party",
              "count": 12159
            },
            {
              "name": "New Conservative",
              "count": 633
            },
            {
              "name": "New Zealand First Party",
              "count": 1218
            },
            {
              "name": "NZ Outdoors Party",
              "count": 41
            },
            {
              "name": "ONE Party",
              "count": 144
            },
            {
              "name": "Social Credit",
              "count": 60
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 21
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 485
            },
            {
              "name": "Vision New Zealand",
              "count": 30
            }
          ]
        }
      },
      {
        "name": "PAKURANGA",
        "candidate": {
          "informal": 882,
          "votes": [
            {
              "name": "BROWN, Simeon",
              "count": 21575
            },
            {
              "name": "HENRY, Nerissa",
              "count": 11525
            },
            {
              "name": "POON, Mo Yee",
              "count": 943
            },
            {
              "name": "SAMPSON, Ian Ronald",
              "count": 426
            },
            {
              "name": "WOOD, Rachel Dominique",
              "count": 302
            },
            {
              "name": "XU-NAN, Lawrence",
              "count": 1632
            }
          ]
        },
        "party": {
          "informal": 217,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3198
            },
            {
              "name": "Advance NZ",
              "count": 193
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 68
            },
            {
              "name": "Green Party",
              "count": 1731
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 15698
            },
            {
              "name": "Māori Party",
              "count": 73
            },
            {
              "name": "National Party",
              "count": 14387
            },
            {
              "name": "New Conservative",
              "count": 498
            },
            {
              "name": "New Zealand First Party",
              "count": 806
            },
            {
              "name": "NZ Outdoors Party",
              "count": 12
            },
            {
              "name": "ONE Party",
              "count": 81
            },
            {
              "name": "Social Credit",
              "count": 4
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 34
            },
            {
              "name": "TEA Party",
              "count": 175
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 347
            },
            {
              "name": "Vision New Zealand",
              "count": 28
            }
          ]
        }
      },
      {
        "name": "PALMERSTON NORTH",
        "candidate": {
          "informal": 768,
          "votes": [
            {
              "name": "BALL, Darroch",
              "count": 875
            },
            {
              "name": "LYON, Sharon Patricia",
              "count": 430
            },
            {
              "name": "PHILLIPS, Jack",
              "count": 1192
            },
            {
              "name": "POPPELWELL, David",
              "count": 733
            },
            {
              "name": "TUIONO, Teanau",
              "count": 2049
            },
            {
              "name": "UTIKERE, Tangi",
              "count": 22516
            },
            {
              "name": "WOOD, William",
              "count": 10008
            }
          ]
        },
        "party": {
          "informal": 274,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2626
            },
            {
              "name": "Advance NZ",
              "count": 332
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 157
            },
            {
              "name": "Green Party",
              "count": 2876
            },
            {
              "name": "HeartlandNZ",
              "count": 6
            },
            {
              "name": "Labour Party",
              "count": 21315
            },
            {
              "name": "Māori Party",
              "count": 177
            },
            {
              "name": "National Party",
              "count": 8513
            },
            {
              "name": "New Conservative",
              "count": 704
            },
            {
              "name": "New Zealand First Party",
              "count": 1157
            },
            {
              "name": "NZ Outdoors Party",
              "count": 21
            },
            {
              "name": "ONE Party",
              "count": 112
            },
            {
              "name": "Social Credit",
              "count": 13
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 10
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 756
            },
            {
              "name": "Vision New Zealand",
              "count": 30
            }
          ]
        }
      },
      {
        "name": "PANMURE-ŌTĀHUHU",
        "candidate": {
          "informal": 1403,
          "votes": [
            {
              "name": "BAKSHI, Kanwaljit Singh",
              "count": 4192
            },
            {
              "name": "BRIDGE, Phillip",
              "count": 210
            },
            {
              "name": "BROWN, Patrick",
              "count": 81
            },
            {
              "name": "JOHNSTON, Ted",
              "count": 648
            },
            {
              "name": "JONES, Bryn",
              "count": 324
            },
            {
              "name": "SALESA, Jenny",
              "count": 22818
            },
            {
              "name": "SIMIONA, Mark",
              "count": 1366
            }
          ]
        },
        "party": {
          "informal": 485,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 744
            },
            {
              "name": "Advance NZ",
              "count": 259
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 136
            },
            {
              "name": "Green Party",
              "count": 1284
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 22929
            },
            {
              "name": "Māori Party",
              "count": 202
            },
            {
              "name": "National Party",
              "count": 3970
            },
            {
              "name": "New Conservative",
              "count": 383
            },
            {
              "name": "New Zealand First Party",
              "count": 772
            },
            {
              "name": "NZ Outdoors Party",
              "count": 33
            },
            {
              "name": "ONE Party",
              "count": 45
            },
            {
              "name": "Social Credit",
              "count": 2
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 9
            },
            {
              "name": "TEA Party",
              "count": 37
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 234
            },
            {
              "name": "Vision New Zealand",
              "count": 87
            }
          ]
        }
      },
      {
        "name": "PAPAKURA",
        "candidate": {
          "informal": 785,
          "votes": [
            {
              "name": "ARVIDSON, David",
              "count": 792
            },
            {
              "name": "COLLINS, Judith",
              "count": 19420
            },
            {
              "name": "COWIE, Sue",
              "count": 1953
            },
            {
              "name": "KANONGATA’A-SUISUIKI, Anahila",
              "count": 13837
            },
            {
              "name": "MONDS, Robert",
              "count": 828
            },
            {
              "name": "POMARE, Vikki-Lee",
              "count": 328
            },
            {
              "name": "SMITH, Teena",
              "count": 234
            },
            {
              "name": "WHITEHEAD, Bruce",
              "count": 1464
            }
          ]
        },
        "party": {
          "informal": 214,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3525
            },
            {
              "name": "Advance NZ",
              "count": 277
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 149
            },
            {
              "name": "Green Party",
              "count": 1618
            },
            {
              "name": "HeartlandNZ",
              "count": 25
            },
            {
              "name": "Labour Party",
              "count": 17106
            },
            {
              "name": "Māori Party",
              "count": 127
            },
            {
              "name": "National Party",
              "count": 14854
            },
            {
              "name": "New Conservative",
              "count": 631
            },
            {
              "name": "New Zealand First Party",
              "count": 1112
            },
            {
              "name": "NZ Outdoors Party",
              "count": 36
            },
            {
              "name": "ONE Party",
              "count": 56
            },
            {
              "name": "Social Credit",
              "count": 12
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 38
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 328
            },
            {
              "name": "Vision New Zealand",
              "count": 108
            }
          ]
        }
      },
      {
        "name": "PORT WAIKATO",
        "candidate": {
          "informal": 675,
          "votes": [
            {
              "name": "BALL, Mark Robert",
              "count": 8462
            },
            {
              "name": "BAYLY, Andrew",
              "count": 15635
            },
            {
              "name": "CUMMINGS, Ian James",
              "count": 285
            },
            {
              "name": "JOHNSON, Ian",
              "count": 176
            },
            {
              "name": "KAUR, Baljit",
              "count": 11322
            },
            {
              "name": "KING, Dave",
              "count": 1340
            },
            {
              "name": "MACGREGOR, Jamie",
              "count": 341
            },
            {
              "name": "PAK, Nick",
              "count": 40
            },
            {
              "name": "RUTHERFURD, Lucille",
              "count": 209
            },
            {
              "name": "SENN, Steven",
              "count": 529
            },
            {
              "name": "WILSON, Bill",
              "count": 1342
            }
          ]
        },
        "party": {
          "informal": 269,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4343
            },
            {
              "name": "Advance NZ",
              "count": 353
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 147
            },
            {
              "name": "Green Party",
              "count": 1357
            },
            {
              "name": "HeartlandNZ",
              "count": 574
            },
            {
              "name": "Labour Party",
              "count": 16369
            },
            {
              "name": "Māori Party",
              "count": 130
            },
            {
              "name": "National Party",
              "count": 14756
            },
            {
              "name": "New Conservative",
              "count": 684
            },
            {
              "name": "New Zealand First Party",
              "count": 1190
            },
            {
              "name": "NZ Outdoors Party",
              "count": 49
            },
            {
              "name": "ONE Party",
              "count": 135
            },
            {
              "name": "Social Credit",
              "count": 7
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 26
            },
            {
              "name": "TEA Party",
              "count": 17
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 374
            },
            {
              "name": "Vision New Zealand",
              "count": 22
            }
          ]
        }
      },
      {
        "name": "RANGITATA",
        "candidate": {
          "informal": 703,
          "votes": [
            {
              "name": "ASHTON, Lachie",
              "count": 1095
            },
            {
              "name": "FAVEL, Brannon",
              "count": 108
            },
            {
              "name": "HANDS, Megan",
              "count": 16739
            },
            {
              "name": "HUTTON, Hamish",
              "count": 1394
            },
            {
              "name": "KELYNACK, Grant William",
              "count": 315
            },
            {
              "name": "LIGTENBERG, Gerrie",
              "count": 852
            },
            {
              "name": "LUXTON, Jo",
              "count": 21147
            },
            {
              "name": "MARU, Aroha",
              "count": 292
            },
            {
              "name": "RAE, James",
              "count": 71
            }
          ]
        },
        "party": {
          "informal": 407,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4101
            },
            {
              "name": "Advance NZ",
              "count": 306
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 204
            },
            {
              "name": "Green Party",
              "count": 1325
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 20742
            },
            {
              "name": "Māori Party",
              "count": 55
            },
            {
              "name": "National Party",
              "count": 13368
            },
            {
              "name": "New Conservative",
              "count": 854
            },
            {
              "name": "New Zealand First Party",
              "count": 848
            },
            {
              "name": "NZ Outdoors Party",
              "count": 109
            },
            {
              "name": "ONE Party",
              "count": 53
            },
            {
              "name": "Social Credit",
              "count": 44
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 20
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 414
            },
            {
              "name": "Vision New Zealand",
              "count": 10
            }
          ]
        }
      },
      {
        "name": "RANGITĪKEI",
        "candidate": {
          "informal": 761,
          "votes": [
            {
              "name": "CRIBB, Ricky",
              "count": 879
            },
            {
              "name": "LEUNG WAI, Reuben",
              "count": 970
            },
            {
              "name": "McKELVIE, Ian",
              "count": 18592
            },
            {
              "name": "PEKE-MASON, Soraya",
              "count": 15631
            },
            {
              "name": "TILLEY, Ali Hale",
              "count": 1992
            },
            {
              "name": "WILSON, Neil John",
              "count": 1860
            },
            {
              "name": "WOOLLAMS, Antony",
              "count": 953
            }
          ]
        },
        "party": {
          "informal": 216,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4758
            },
            {
              "name": "Advance NZ",
              "count": 411
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 184
            },
            {
              "name": "Green Party",
              "count": 1927
            },
            {
              "name": "HeartlandNZ",
              "count": 7
            },
            {
              "name": "Labour Party",
              "count": 18610
            },
            {
              "name": "Māori Party",
              "count": 179
            },
            {
              "name": "National Party",
              "count": 12634
            },
            {
              "name": "New Conservative",
              "count": 827
            },
            {
              "name": "New Zealand First Party",
              "count": 1406
            },
            {
              "name": "NZ Outdoors Party",
              "count": 44
            },
            {
              "name": "ONE Party",
              "count": 129
            },
            {
              "name": "Social Credit",
              "count": 21
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 16
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 587
            },
            {
              "name": "Vision New Zealand",
              "count": 14
            }
          ]
        }
      },
      {
        "name": "REMUTAKA",
        "candidate": {
          "informal": 726,
          "votes": [
            {
              "name": "CROFSKEY, Mark",
              "count": 8720
            },
            {
              "name": "EIJGENRAAM, Frank",
              "count": 602
            },
            {
              "name": "HIPKINS, Chris",
              "count": 29217
            },
            {
              "name": "MEIKLE, Talani",
              "count": 635
            },
            {
              "name": "NORTON, Chris",
              "count": 1610
            },
            {
              "name": "OPTLAND, Hank",
              "count": 618
            },
            {
              "name": "O’SULLIVAN, Grae",
              "count": 952
            },
            {
              "name": "STACE, Michael Alexander",
              "count": 257
            }
          ]
        },
        "party": {
          "informal": 283,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2607
            },
            {
              "name": "Advance NZ",
              "count": 245
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 132
            },
            {
              "name": "Green Party",
              "count": 3105
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 25347
            },
            {
              "name": "Māori Party",
              "count": 171
            },
            {
              "name": "National Party",
              "count": 8274
            },
            {
              "name": "New Conservative",
              "count": 686
            },
            {
              "name": "New Zealand First Party",
              "count": 1296
            },
            {
              "name": "NZ Outdoors Party",
              "count": 27
            },
            {
              "name": "ONE Party",
              "count": 462
            },
            {
              "name": "Social Credit",
              "count": 16
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 822
            },
            {
              "name": "Vision New Zealand",
              "count": 70
            }
          ]
        }
      },
      {
        "name": "RONGOTAI",
        "candidate": {
          "informal": 779,
          "votes": [
            {
              "name": "ARNEIL, Taylor",
              "count": 472
            },
            {
              "name": "CROSSEN, Teall",
              "count": 6719
            },
            {
              "name": "EAGLE, Paul",
              "count": 25926
            },
            {
              "name": "McDONALD, Don S Newt",
              "count": 110
            },
            {
              "name": "McKEE, Nicole",
              "count": 965
            },
            {
              "name": "MIHAKA, Troy",
              "count": 162
            },
            {
              "name": "PATTERSON, David",
              "count": 6447
            },
            {
              "name": "SIMMONS, Geoff",
              "count": 3387
            },
            {
              "name": "WELSH, Bruce",
              "count": 431
            }
          ]
        },
        "party": {
          "informal": 259,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 1759
            },
            {
              "name": "Advance NZ",
              "count": 184
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 70
            },
            {
              "name": "Green Party",
              "count": 10765
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 23878
            },
            {
              "name": "Māori Party",
              "count": 201
            },
            {
              "name": "National Party",
              "count": 6013
            },
            {
              "name": "New Conservative",
              "count": 232
            },
            {
              "name": "New Zealand First Party",
              "count": 883
            },
            {
              "name": "NZ Outdoors Party",
              "count": 21
            },
            {
              "name": "ONE Party",
              "count": 45
            },
            {
              "name": "Social Credit",
              "count": 10
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 27
            },
            {
              "name": "TEA Party",
              "count": 7
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1272
            },
            {
              "name": "Vision New Zealand",
              "count": 21
            }
          ]
        }
      },
      {
        "name": "ROTORUA",
        "candidate": {
          "informal": 701,
          "votes": [
            {
              "name": "KIRKWOOD, Pete",
              "count": 1053
            },
            {
              "name": "McCLAY, Todd",
              "count": 16212
            },
            {
              "name": "MAHON, Claire",
              "count": 15387
            },
            {
              "name": "SOLOMON, Alan Tāne",
              "count": 564
            },
            {
              "name": "SPARKE, Kaya",
              "count": 1887
            },
            {
              "name": "TABUTEAU, Fletcher",
              "count": 1412
            },
            {
              "name": "VERCOE, Karri-Ann",
              "count": 372
            },
            {
              "name": "WARD, Kiri",
              "count": 563
            }
          ]
        },
        "party": {
          "informal": 260,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3463
            },
            {
              "name": "Advance NZ",
              "count": 518
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 179
            },
            {
              "name": "Green Party",
              "count": 1816
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 17845
            },
            {
              "name": "Māori Party",
              "count": 347
            },
            {
              "name": "National Party",
              "count": 10951
            },
            {
              "name": "New Conservative",
              "count": 703
            },
            {
              "name": "New Zealand First Party",
              "count": 1383
            },
            {
              "name": "NZ Outdoors Party",
              "count": 50
            },
            {
              "name": "ONE Party",
              "count": 177
            },
            {
              "name": "Social Credit",
              "count": 16
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 21
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 606
            },
            {
              "name": "Vision New Zealand",
              "count": 141
            }
          ]
        }
      },
      {
        "name": "SELWYN",
        "candidate": {
          "informal": 654,
          "votes": [
            {
              "name": "ARMSTRONG, Stuart",
              "count": 1890
            },
            {
              "name": "DAVIDSON, Reuben",
              "count": 16018
            },
            {
              "name": "GRIGG, Nicola",
              "count": 20986
            },
            {
              "name": "LARASON, Jerry",
              "count": 362
            },
            {
              "name": "LYELL, Bronwyn",
              "count": 910
            },
            {
              "name": "O’DONNELL, Abe",
              "count": 1749
            },
            {
              "name": "PAYNE, Calvin",
              "count": 436
            }
          ]
        },
        "party": {
          "informal": 219,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 5396
            },
            {
              "name": "Advance NZ",
              "count": 306
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 85
            },
            {
              "name": "Green Party",
              "count": 2092
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 18102
            },
            {
              "name": "Māori Party",
              "count": 59
            },
            {
              "name": "National Party",
              "count": 14616
            },
            {
              "name": "New Conservative",
              "count": 761
            },
            {
              "name": "New Zealand First Party",
              "count": 861
            },
            {
              "name": "NZ Outdoors Party",
              "count": 38
            },
            {
              "name": "ONE Party",
              "count": 49
            },
            {
              "name": "Social Credit",
              "count": 2
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 25
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 564
            },
            {
              "name": "Vision New Zealand",
              "count": 5
            }
          ]
        }
      },
      {
        "name": "SOUTHLAND",
        "candidate": {
          "informal": 679,
          "votes": [
            {
              "name": "DACKER, Elisabeth",
              "count": 139
            },
            {
              "name": "KENNEDY, David",
              "count": 2127
            },
            {
              "name": "MEYER, Fiona",
              "count": 664
            },
            {
              "name": "MITCHELL, Jon",
              "count": 14330
            },
            {
              "name": "MOONEY, Joseph",
              "count": 19975
            },
            {
              "name": "ROWLANDS, Joel",
              "count": 880
            },
            {
              "name": "TERRILL, Judith",
              "count": 130
            },
            {
              "name": "WILSON, Robert Douglas",
              "count": 508
            }
          ]
        },
        "party": {
          "informal": 164,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 5016
            },
            {
              "name": "Advance NZ",
              "count": 373
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 151
            },
            {
              "name": "Green Party",
              "count": 2085
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 15341
            },
            {
              "name": "Māori Party",
              "count": 60
            },
            {
              "name": "National Party",
              "count": 14244
            },
            {
              "name": "New Conservative",
              "count": 588
            },
            {
              "name": "New Zealand First Party",
              "count": 794
            },
            {
              "name": "NZ Outdoors Party",
              "count": 56
            },
            {
              "name": "ONE Party",
              "count": 78
            },
            {
              "name": "Social Credit",
              "count": 39
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 25
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 616
            },
            {
              "name": "Vision New Zealand",
              "count": 7
            }
          ]
        }
      },
      {
        "name": "TAIERI",
        "candidate": {
          "informal": 746,
          "votes": [
            {
              "name": "ANDREWS, Robert",
              "count": 1291
            },
            {
              "name": "KELLEHER, Ally",
              "count": 472
            },
            {
              "name": "KERNAGHAN, Liam",
              "count": 12865
            },
            {
              "name": "LEARY, Ingrid",
              "count": 25263
            },
            {
              "name": "LEQUEUX, Olivier",
              "count": 130
            },
            {
              "name": "PATTERSON, Mark William James",
              "count": 1309
            },
            {
              "name": "ROBERTS, Fred",
              "count": 256
            },
            {
              "name": "SMITH, Stan",
              "count": 201
            },
            {
              "name": "VOIGHT, Warren",
              "count": 107
            },
            {
              "name": "WEBBER, David",
              "count": 75
            },
            {
              "name": "WILLIS, Scott",
              "count": 2207
            }
          ]
        },
        "party": {
          "informal": 382,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3302
            },
            {
              "name": "Advance NZ",
              "count": 236
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 122
            },
            {
              "name": "Green Party",
              "count": 2440
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 26608
            },
            {
              "name": "Māori Party",
              "count": 55
            },
            {
              "name": "National Party",
              "count": 9531
            },
            {
              "name": "New Conservative",
              "count": 501
            },
            {
              "name": "New Zealand First Party",
              "count": 1179
            },
            {
              "name": "NZ Outdoors Party",
              "count": 34
            },
            {
              "name": "ONE Party",
              "count": 105
            },
            {
              "name": "Social Credit",
              "count": 45
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 27
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 571
            },
            {
              "name": "Vision New Zealand",
              "count": 10
            }
          ]
        }
      },
      {
        "name": "TAKANINI",
        "candidate": {
          "informal": 1321,
          "votes": [
            {
              "name": "GEORGE, Ngatai",
              "count": 361
            },
            {
              "name": "HONG, John",
              "count": 902
            },
            {
              "name": "IKILEI, Elliot",
              "count": 939
            },
            {
              "name": "KAGATHRA, Mit",
              "count": 215
            },
            {
              "name": "LEAVASA, Neru",
              "count": 19431
            },
            {
              "name": "McCORMICK, Mike",
              "count": 1307
            },
            {
              "name": "NAKHLE, Rima",
              "count": 11707
            }
          ]
        },
        "party": {
          "informal": 318,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 1688
            },
            {
              "name": "Advance NZ",
              "count": 223
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 97
            },
            {
              "name": "Green Party",
              "count": 1206
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 20022
            },
            {
              "name": "Māori Party",
              "count": 162
            },
            {
              "name": "National Party",
              "count": 10952
            },
            {
              "name": "New Conservative",
              "count": 618
            },
            {
              "name": "New Zealand First Party",
              "count": 722
            },
            {
              "name": "NZ Outdoors Party",
              "count": 15
            },
            {
              "name": "ONE Party",
              "count": 69
            },
            {
              "name": "Social Credit",
              "count": 2
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 13
            },
            {
              "name": "TEA Party",
              "count": 211
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 219
            },
            {
              "name": "Vision New Zealand",
              "count": 183
            }
          ]
        }
      },
      {
        "name": "TĀMAKI",
        "candidate": {
          "informal": 794,
          "votes": [
            {
              "name": "BOYS, Sylvia",
              "count": 3260
            },
            {
              "name": "BROWN, Shirin",
              "count": 13403
            },
            {
              "name": "CLARIDGE, Carmel",
              "count": 2151
            },
            {
              "name": "O’CONNOR, Simon",
              "count": 21471
            },
            {
              "name": "SOMMER, Paul",
              "count": 246
            },
            {
              "name": "TEPOU, Sarai",
              "count": 180
            }
          ]
        },
        "party": {
          "informal": 202,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4887
            },
            {
              "name": "Advance NZ",
              "count": 178
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 60
            },
            {
              "name": "Green Party",
              "count": 3200
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 16082
            },
            {
              "name": "Māori Party",
              "count": 127
            },
            {
              "name": "National Party",
              "count": 15435
            },
            {
              "name": "New Conservative",
              "count": 286
            },
            {
              "name": "New Zealand First Party",
              "count": 673
            },
            {
              "name": "NZ Outdoors Party",
              "count": 16
            },
            {
              "name": "ONE Party",
              "count": 31
            },
            {
              "name": "Social Credit",
              "count": 6
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 32
            },
            {
              "name": "TEA Party",
              "count": 62
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 558
            },
            {
              "name": "Vision New Zealand",
              "count": 16
            }
          ]
        }
      },
      {
        "name": "TARANAKI-KING COUNTRY",
        "candidate": {
          "informal": 938,
          "votes": [
            {
              "name": "GREY, Christopher",
              "count": 870
            },
            {
              "name": "KURIGER, Barbara",
              "count": 18702
            },
            {
              "name": "MILES, Brent",
              "count": 2376
            },
            {
              "name": "ROBERTS, Angela",
              "count": 15568
            },
            {
              "name": "SMITH, Lee Anne",
              "count": 2151
            }
          ]
        },
        "party": {
          "informal": 260,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4648
            },
            {
              "name": "Advance NZ",
              "count": 513
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 154
            },
            {
              "name": "Green Party",
              "count": 1962
            },
            {
              "name": "HeartlandNZ",
              "count": 8
            },
            {
              "name": "Labour Party",
              "count": 15341
            },
            {
              "name": "Māori Party",
              "count": 187
            },
            {
              "name": "National Party",
              "count": 14851
            },
            {
              "name": "New Conservative",
              "count": 1115
            },
            {
              "name": "New Zealand First Party",
              "count": 1123
            },
            {
              "name": "NZ Outdoors Party",
              "count": 121
            },
            {
              "name": "ONE Party",
              "count": 66
            },
            {
              "name": "Social Credit",
              "count": 21
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 26
            },
            {
              "name": "TEA Party",
              "count": 18
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 502
            },
            {
              "name": "Vision New Zealand",
              "count": 30
            }
          ]
        }
      },
      {
        "name": "TAUPŌ",
        "candidate": {
          "informal": 928,
          "votes": [
            {
              "name": "AL-BUSTANJI, Ala’",
              "count": 15983
            },
            {
              "name": "COFFIN, Gary Michael",
              "count": 415
            },
            {
              "name": "DOWNARD, Micheal",
              "count": 645
            },
            {
              "name": "FREEMAN, David",
              "count": 1848
            },
            {
              "name": "GLENDINING, Danna",
              "count": 2018
            },
            {
              "name": "JAMES, Antoinette",
              "count": 531
            },
            {
              "name": "QUINN, Jan-Marie",
              "count": 608
            },
            {
              "name": "UPSTON, Louise",
              "count": 21102
            }
          ]
        },
        "party": {
          "informal": 275,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4340
            },
            {
              "name": "Advance NZ",
              "count": 476
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 165
            },
            {
              "name": "Green Party",
              "count": 1677
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 19978
            },
            {
              "name": "Māori Party",
              "count": 164
            },
            {
              "name": "National Party",
              "count": 14452
            },
            {
              "name": "New Conservative",
              "count": 681
            },
            {
              "name": "New Zealand First Party",
              "count": 1227
            },
            {
              "name": "NZ Outdoors Party",
              "count": 123
            },
            {
              "name": "ONE Party",
              "count": 184
            },
            {
              "name": "Social Credit",
              "count": 14
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 23
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 592
            },
            {
              "name": "Vision New Zealand",
              "count": 23
            }
          ]
        }
      },
      {
        "name": "TAURANGA",
        "candidate": {
          "informal": 706,
          "votes": [
            {
              "name": "BRIDGES, Simon",
              "count": 18721
            },
            {
              "name": "CAIE, Andrew",
              "count": 935
            },
            {
              "name": "CAPAMAGIAN, James",
              "count": 83
            },
            {
              "name": "COLE, Josh",
              "count": 1901
            },
            {
              "name": "CROSA, Daniel Armando",
              "count": 471
            },
            {
              "name": "HARVEY, Erika",
              "count": 1379
            },
            {
              "name": "HIGNETT, Paul",
              "count": 725
            },
            {
              "name": "LAMARE, Yvette",
              "count": 63
            },
            {
              "name": "LIVINGSTON, Tracy",
              "count": 188
            },
            {
              "name": "LUXTON, Cameron",
              "count": 1739
            },
            {
              "name": "TINETTI, Jan",
              "count": 16865
            }
          ]
        },
        "party": {
          "informal": 334,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3952
            },
            {
              "name": "Advance NZ",
              "count": 540
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 139
            },
            {
              "name": "Green Party",
              "count": 2407
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 18547
            },
            {
              "name": "Māori Party",
              "count": 155
            },
            {
              "name": "National Party",
              "count": 14347
            },
            {
              "name": "New Conservative",
              "count": 1032
            },
            {
              "name": "New Zealand First Party",
              "count": 1595
            },
            {
              "name": "NZ Outdoors Party",
              "count": 53
            },
            {
              "name": "ONE Party",
              "count": 95
            },
            {
              "name": "Social Credit",
              "count": 11
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 31
            },
            {
              "name": "TEA Party",
              "count": 9
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 847
            },
            {
              "name": "Vision New Zealand",
              "count": 51
            }
          ]
        }
      },
      {
        "name": "TE ATATŪ",
        "candidate": {
          "informal": 1115,
          "votes": [
            {
              "name": "AMOAH, Frank",
              "count": 233
            },
            {
              "name": "COURT, Simon",
              "count": 1610
            },
            {
              "name": "HINDMAN, Scott",
              "count": 2088
            },
            {
              "name": "MONK, Brendon",
              "count": 538
            },
            {
              "name": "NGARO, Alfred",
              "count": 10745
            },
            {
              "name": "PASEKA, Okusitino",
              "count": 449
            },
            {
              "name": "TWYFORD, Phil",
              "count": 21253
            }
          ]
        },
        "party": {
          "informal": 319,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 1954
            },
            {
              "name": "Advance NZ",
              "count": 289
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 128
            },
            {
              "name": "Green Party",
              "count": 2480
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 22106
            },
            {
              "name": "Māori Party",
              "count": 256
            },
            {
              "name": "National Party",
              "count": 8653
            },
            {
              "name": "New Conservative",
              "count": 537
            },
            {
              "name": "New Zealand First Party",
              "count": 966
            },
            {
              "name": "NZ Outdoors Party",
              "count": 21
            },
            {
              "name": "ONE Party",
              "count": 110
            },
            {
              "name": "Social Credit",
              "count": 11
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 31
            },
            {
              "name": "TEA Party",
              "count": 114
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 403
            },
            {
              "name": "Vision New Zealand",
              "count": 22
            }
          ]
        }
      },
      {
        "name": "TUKITUKI",
        "candidate": {
          "informal": 737,
          "votes": [
            {
              "name": "DAFFERN, Jan",
              "count": 1051
            },
            {
              "name": "LORCK, Anna",
              "count": 19132
            },
            {
              "name": "McMINN-COLLARD, Nick",
              "count": 685
            },
            {
              "name": "MANNING, Romana (Marnz)",
              "count": 521
            },
            {
              "name": "PERLEY, Chris",
              "count": 1808
            },
            {
              "name": "PETERSON, Carl",
              "count": 336
            },
            {
              "name": "PETROWSKI, Melanie Lorraine",
              "count": 175
            },
            {
              "name": "TOSH, Allister David",
              "count": 50
            },
            {
              "name": "YULE, Lawrence",
              "count": 17542
            }
          ]
        },
        "party": {
          "informal": 309,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3745
            },
            {
              "name": "Advance NZ",
              "count": 396
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 204
            },
            {
              "name": "Green Party",
              "count": 2273
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 20300
            },
            {
              "name": "Māori Party",
              "count": 162
            },
            {
              "name": "National Party",
              "count": 12524
            },
            {
              "name": "New Conservative",
              "count": 902
            },
            {
              "name": "New Zealand First Party",
              "count": 869
            },
            {
              "name": "NZ Outdoors Party",
              "count": 33
            },
            {
              "name": "ONE Party",
              "count": 104
            },
            {
              "name": "Social Credit",
              "count": 20
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 25
            },
            {
              "name": "TEA Party",
              "count": 5
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 454
            },
            {
              "name": "Vision New Zealand",
              "count": 19
            }
          ]
        }
      },
      {
        "name": "UPPER HARBOUR",
        "candidate": {
          "informal": 1042,
          "votes": [
            {
              "name": "BEZZANT, Jake",
              "count": 15181
            },
            {
              "name": "CHHOUR, Karen",
              "count": 1712
            },
            {
              "name": "GIORZA, Catherine",
              "count": 157
            },
            {
              "name": "NICHOLLS, Ryan",
              "count": 2488
            },
            {
              "name": "SOARES, Bernadette",
              "count": 1265
            },
            {
              "name": "TAN, Winson",
              "count": 410
            },
            {
              "name": "THOMAS, Dion",
              "count": 127
            },
            {
              "name": "VAUGHAN, Peter",
              "count": 309
            },
            {
              "name": "WALTERS, Vanushi",
              "count": 17573
            }
          ]
        },
        "party": {
          "informal": 281,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3066
            },
            {
              "name": "Advance NZ",
              "count": 256
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 157
            },
            {
              "name": "Green Party",
              "count": 2151
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 19507
            },
            {
              "name": "Māori Party",
              "count": 144
            },
            {
              "name": "National Party",
              "count": 12675
            },
            {
              "name": "New Conservative",
              "count": 752
            },
            {
              "name": "New Zealand First Party",
              "count": 797
            },
            {
              "name": "NZ Outdoors Party",
              "count": 22
            },
            {
              "name": "ONE Party",
              "count": 81
            },
            {
              "name": "Social Credit",
              "count": 5
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 46
            },
            {
              "name": "TEA Party",
              "count": 136
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 559
            },
            {
              "name": "Vision New Zealand",
              "count": 20
            }
          ]
        }
      },
      {
        "name": "WAIKATO",
        "candidate": {
          "informal": 933,
          "votes": [
            {
              "name": "ANSELL, Caleb John",
              "count": 1551
            },
            {
              "name": "LEONI, Kerrin",
              "count": 15661
            },
            {
              "name": "McDOWALL, James",
              "count": 2084
            },
            {
              "name": "VAN DE MOLEN, Tim",
              "count": 20877
            }
          ]
        },
        "party": {
          "informal": 263,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4340
            },
            {
              "name": "Advance NZ",
              "count": 373
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 173
            },
            {
              "name": "Green Party",
              "count": 1454
            },
            {
              "name": "HeartlandNZ",
              "count": 16
            },
            {
              "name": "Labour Party",
              "count": 16288
            },
            {
              "name": "Māori Party",
              "count": 145
            },
            {
              "name": "National Party",
              "count": 15562
            },
            {
              "name": "New Conservative",
              "count": 1053
            },
            {
              "name": "New Zealand First Party",
              "count": 1117
            },
            {
              "name": "NZ Outdoors Party",
              "count": 30
            },
            {
              "name": "ONE Party",
              "count": 87
            },
            {
              "name": "Social Credit",
              "count": 19
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 20
            },
            {
              "name": "TEA Party",
              "count": 13
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 518
            },
            {
              "name": "Vision New Zealand",
              "count": 46
            }
          ]
        }
      },
      {
        "name": "WAIMAKARIRI",
        "candidate": {
          "informal": 763,
          "votes": [
            {
              "name": "BAKER, Leighton",
              "count": 2057
            },
            {
              "name": "DAVIES, James",
              "count": 1355
            },
            {
              "name": "DOOCEY, Matt",
              "count": 21416
            },
            {
              "name": "HYNDMAN, John",
              "count": 405
            },
            {
              "name": "McISAAC, Lawrence",
              "count": 128
            },
            {
              "name": "RICHARDSON, Shelley",
              "count": 363
            },
            {
              "name": "ROSEWARNE, Dan",
              "count": 19909
            },
            {
              "name": "SADLER, Bjorn",
              "count": 197
            }
          ]
        },
        "party": {
          "informal": 356,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4324
            },
            {
              "name": "Advance NZ",
              "count": 353
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 174
            },
            {
              "name": "Green Party",
              "count": 2168
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 23088
            },
            {
              "name": "Māori Party",
              "count": 74
            },
            {
              "name": "National Party",
              "count": 13245
            },
            {
              "name": "New Conservative",
              "count": 1399
            },
            {
              "name": "New Zealand First Party",
              "count": 989
            },
            {
              "name": "NZ Outdoors Party",
              "count": 37
            },
            {
              "name": "ONE Party",
              "count": 49
            },
            {
              "name": "Social Credit",
              "count": 40
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 55
            },
            {
              "name": "TEA Party",
              "count": 14
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 435
            },
            {
              "name": "Vision New Zealand",
              "count": 20
            }
          ]
        }
      },
      {
        "name": "WAIRARAPA",
        "candidate": {
          "informal": 636,
          "votes": [
            {
              "name": "BUTTERICK, Mike",
              "count": 15497
            },
            {
              "name": "BUTTERWORTH, Warren",
              "count": 587
            },
            {
              "name": "GRAY, Nigel Antony",
              "count": 443
            },
            {
              "name": "GREENSLADE, Roger",
              "count": 1076
            },
            {
              "name": "HAEATA, Aileen",
              "count": 109
            },
            {
              "name": "McANULTY, Kieran",
              "count": 22042
            },
            {
              "name": "MARK, Ron",
              "count": 3278
            },
            {
              "name": "WADE-BROWN, Celia",
              "count": 1359
            }
          ]
        },
        "party": {
          "informal": 279,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4132
            },
            {
              "name": "Advance NZ",
              "count": 464
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 167
            },
            {
              "name": "Green Party",
              "count": 2329
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 21673
            },
            {
              "name": "Māori Party",
              "count": 125
            },
            {
              "name": "National Party",
              "count": 13165
            },
            {
              "name": "New Conservative",
              "count": 670
            },
            {
              "name": "New Zealand First Party",
              "count": 1545
            },
            {
              "name": "NZ Outdoors Party",
              "count": 50
            },
            {
              "name": "ONE Party",
              "count": 137
            },
            {
              "name": "Social Credit",
              "count": 9
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 9
            },
            {
              "name": "TEA Party",
              "count": 5
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 490
            },
            {
              "name": "Vision New Zealand",
              "count": 12
            }
          ]
        }
      },
      {
        "name": "WAITAKI",
        "candidate": {
          "informal": 698,
          "votes": [
            {
              "name": "ALLAN, Troy",
              "count": 852
            },
            {
              "name": "BEAMISH, Sean",
              "count": 1808
            },
            {
              "name": "DEAN, Jacqui",
              "count": 19659
            },
            {
              "name": "KIURU, Sampsa",
              "count": 2482
            },
            {
              "name": "MOWAT-GAINSFORD, Brian Clifford",
              "count": 189
            },
            {
              "name": "ODERING, Anthony Vickers",
              "count": 518
            },
            {
              "name": "PENNYCOOK, Heather Meri",
              "count": 594
            },
            {
              "name": "SHAND, Daniel",
              "count": 1056
            },
            {
              "name": "WAIREPO, Liam",
              "count": 16378
            }
          ]
        },
        "party": {
          "informal": 273,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 5145
            },
            {
              "name": "Advance NZ",
              "count": 471
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 185
            },
            {
              "name": "Green Party",
              "count": 2501
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 19466
            },
            {
              "name": "Māori Party",
              "count": 54
            },
            {
              "name": "National Party",
              "count": 13805
            },
            {
              "name": "New Conservative",
              "count": 848
            },
            {
              "name": "New Zealand First Party",
              "count": 876
            },
            {
              "name": "NZ Outdoors Party",
              "count": 73
            },
            {
              "name": "ONE Party",
              "count": 71
            },
            {
              "name": "Social Credit",
              "count": 9
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 63
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 576
            },
            {
              "name": "Vision New Zealand",
              "count": 11
            }
          ]
        }
      },
      {
        "name": "WELLINGTON CENTRAL",
        "candidate": {
          "informal": 386,
          "votes": [
            {
              "name": "APPLEBY, Michael George",
              "count": 401
            },
            {
              "name": "BRUCE, Robert",
              "count": 76
            },
            {
              "name": "GRAY, Abe",
              "count": 1031
            },
            {
              "name": "GREALLY, Rose",
              "count": 108
            },
            {
              "name": "RICHARDSON, Jesse",
              "count": 385
            },
            {
              "name": "RICHFIELD, Liam",
              "count": 216
            },
            {
              "name": "ROBERTSON, Grant",
              "count": 27366
            },
            {
              "name": "SHAW, James",
              "count": 8381
            },
            {
              "name": "SUNDERLAND, Gina",
              "count": 84
            },
            {
              "name": "van VELDEN, Brooke",
              "count": 865
            },
            {
              "name": "WILLIS, Nicola",
              "count": 8488
            }
          ]
        },
        "party": {
          "informal": 176,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2339
            },
            {
              "name": "Advance NZ",
              "count": 103
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 132
            },
            {
              "name": "Green Party",
              "count": 14587
            },
            {
              "name": "HeartlandNZ",
              "count": 1
            },
            {
              "name": "Labour Party",
              "count": 20876
            },
            {
              "name": "Māori Party",
              "count": 255
            },
            {
              "name": "National Party",
              "count": 6937
            },
            {
              "name": "New Conservative",
              "count": 204
            },
            {
              "name": "New Zealand First Party",
              "count": 537
            },
            {
              "name": "NZ Outdoors Party",
              "count": 27
            },
            {
              "name": "ONE Party",
              "count": 56
            },
            {
              "name": "Social Credit",
              "count": 18
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 32
            },
            {
              "name": "TEA Party",
              "count": 12
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 1790
            },
            {
              "name": "Vision New Zealand",
              "count": 8
            }
          ]
        }
      },
      {
        "name": "WEST COAST-TASMAN",
        "candidate": {
          "informal": 596,
          "votes": [
            {
              "name": "AITKEN, Cory",
              "count": 201
            },
            {
              "name": "BARKLEY, Karl",
              "count": 606
            },
            {
              "name": "COLLIN, Jack",
              "count": 141
            },
            {
              "name": "EWEN, Peter",
              "count": 697
            },
            {
              "name": "FARRELLY, Jackie",
              "count": 685
            },
            {
              "name": "FITZSIMON, Anne",
              "count": 717
            },
            {
              "name": "GARDNER, William Stuart",
              "count": 1454
            },
            {
              "name": "KING, Luke",
              "count": 618
            },
            {
              "name": "O’CONNOR, Damien",
              "count": 20753
            },
            {
              "name": "OSMASTON, Richard",
              "count": 69
            },
            {
              "name": "PUGH, Maureen",
              "count": 14545
            },
            {
              "name": "RICHARDS, Steve",
              "count": 2341
            }
          ]
        },
        "party": {
          "informal": 288,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4237
            },
            {
              "name": "Advance NZ",
              "count": 747
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 250
            },
            {
              "name": "Green Party",
              "count": 3572
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 20521
            },
            {
              "name": "Māori Party",
              "count": 88
            },
            {
              "name": "National Party",
              "count": 10934
            },
            {
              "name": "New Conservative",
              "count": 730
            },
            {
              "name": "New Zealand First Party",
              "count": 1281
            },
            {
              "name": "NZ Outdoors Party",
              "count": 229
            },
            {
              "name": "ONE Party",
              "count": 60
            },
            {
              "name": "Social Credit",
              "count": 78
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 33
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 553
            },
            {
              "name": "Vision New Zealand",
              "count": 21
            }
          ]
        }
      },
      {
        "name": "WHANGANUI",
        "candidate": {
          "informal": 1035,
          "votes": [
            {
              "name": "CLAY, Alan",
              "count": 1491
            },
            {
              "name": "HIPANGO, Harete",
              "count": 14108
            },
            {
              "name": "KIEFT, Hillary",
              "count": 367
            },
            {
              "name": "LEWIS, Steph",
              "count": 22299
            },
            {
              "name": "MARSHALL, Jonathan",
              "count": 784
            },
            {
              "name": "SMITH, Heather Marion",
              "count": 188
            },
            {
              "name": "WEBER, Charlotte",
              "count": 773
            }
          ]
        },
        "party": {
          "informal": 253,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3260
            },
            {
              "name": "Advance NZ",
              "count": 531
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 198
            },
            {
              "name": "Green Party",
              "count": 2004
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 21228
            },
            {
              "name": "Māori Party",
              "count": 222
            },
            {
              "name": "National Party",
              "count": 10828
            },
            {
              "name": "New Conservative",
              "count": 690
            },
            {
              "name": "New Zealand First Party",
              "count": 1368
            },
            {
              "name": "NZ Outdoors Party",
              "count": 43
            },
            {
              "name": "ONE Party",
              "count": 189
            },
            {
              "name": "Social Credit",
              "count": 63
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 5
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 390
            },
            {
              "name": "Vision New Zealand",
              "count": 10
            }
          ]
        }
      },
      {
        "name": "WHANGAPARĀOA",
        "candidate": {
          "informal": 705,
          "votes": [
            {
              "name": "CHEEL, Tricia",
              "count": 400
            },
            {
              "name": "DAVIES, John",
              "count": 605
            },
            {
              "name": "FERGUSON, Lorayne Frances",
              "count": 15999
            },
            {
              "name": "FLAY, Kathryn",
              "count": 659
            },
            {
              "name": "GRACE, Paul",
              "count": 1757
            },
            {
              "name": "MACKENZIE, Fiona",
              "count": 1486
            },
            {
              "name": "MITCHELL, Mark",
              "count": 23822
            }
          ]
        },
        "party": {
          "informal": 180,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 4827
            },
            {
              "name": "Advance NZ",
              "count": 525
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 124
            },
            {
              "name": "Green Party",
              "count": 2227
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 18217
            },
            {
              "name": "Māori Party",
              "count": 91
            },
            {
              "name": "National Party",
              "count": 16696
            },
            {
              "name": "New Conservative",
              "count": 1028
            },
            {
              "name": "New Zealand First Party",
              "count": 979
            },
            {
              "name": "NZ Outdoors Party",
              "count": 48
            },
            {
              "name": "ONE Party",
              "count": 55
            },
            {
              "name": "Social Credit",
              "count": 11
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 65
            },
            {
              "name": "TEA Party",
              "count": 39
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 554
            },
            {
              "name": "Vision New Zealand",
              "count": 9
            }
          ]
        }
      },
      {
        "name": "WHANGĀREI",
        "candidate": {
          "informal": 611,
          "votes": [
            {
              "name": "ARMSTRONG, Moea",
              "count": 1635
            },
            {
              "name": "CAMPBELL, Kerry",
              "count": 544
            },
            {
              "name": "de JONGE, Jennifer",
              "count": 597
            },
            {
              "name": "HENDERSON, Emily",
              "count": 17823
            },
            {
              "name": "LEITCH, Chris",
              "count": 356
            },
            {
              "name": "RETI, Shane",
              "count": 17392
            },
            {
              "name": "SEYMOUR, David",
              "count": 2153
            },
            {
              "name": "SWORDS, Ciara",
              "count": 705
            },
            {
              "name": "WATTS, Daniel",
              "count": 287
            },
            {
              "name": "WETERE, Chris",
              "count": 654
            },
            {
              "name": "WILSON, David Ashley",
              "count": 1527
            }
          ]
        },
        "party": {
          "informal": 297,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 3650
            },
            {
              "name": "Advance NZ",
              "count": 686
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 274
            },
            {
              "name": "Green Party",
              "count": 2738
            },
            {
              "name": "HeartlandNZ",
              "count": 4
            },
            {
              "name": "Labour Party",
              "count": 20942
            },
            {
              "name": "Māori Party",
              "count": 149
            },
            {
              "name": "National Party",
              "count": 11602
            },
            {
              "name": "New Conservative",
              "count": 814
            },
            {
              "name": "New Zealand First Party",
              "count": 2268
            },
            {
              "name": "NZ Outdoors Party",
              "count": 42
            },
            {
              "name": "ONE Party",
              "count": 249
            },
            {
              "name": "Social Credit",
              "count": 133
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 23
            },
            {
              "name": "TEA Party",
              "count": 10
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 663
            },
            {
              "name": "Vision New Zealand",
              "count": 44
            }
          ]
        }
      },
      {
        "name": "WIGRAM",
        "candidate": {
          "informal": 842,
          "votes": [
            {
              "name": "ALLINGTON, Douglas John",
              "count": 308
            },
            {
              "name": "CAMPBELL, Hamish",
              "count": 9416
            },
            {
              "name": "HANSEN, Tubby",
              "count": 75
            },
            {
              "name": "LANDRETH, Deane",
              "count": 76
            },
            {
              "name": "McCONWAY, Miles Jacin",
              "count": 1072
            },
            {
              "name": "McLAUGHLIN, Linda",
              "count": 244
            },
            {
              "name": "McTAGUE, Geoff",
              "count": 81
            },
            {
              "name": "NUTTALL, Averil",
              "count": 638
            },
            {
              "name": "WESLEY, Richard",
              "count": 2022
            },
            {
              "name": "WOODS, Megan",
              "count": 24186
            }
          ]
        },
        "party": {
          "informal": 404,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 2247
            },
            {
              "name": "Advance NZ",
              "count": 282
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 165
            },
            {
              "name": "Green Party",
              "count": 3541
            },
            {
              "name": "HeartlandNZ",
              "count": 5
            },
            {
              "name": "Labour Party",
              "count": 21454
            },
            {
              "name": "Māori Party",
              "count": 124
            },
            {
              "name": "National Party",
              "count": 8388
            },
            {
              "name": "New Conservative",
              "count": 639
            },
            {
              "name": "New Zealand First Party",
              "count": 791
            },
            {
              "name": "NZ Outdoors Party",
              "count": 28
            },
            {
              "name": "ONE Party",
              "count": 162
            },
            {
              "name": "Social Credit",
              "count": 31
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 24
            },
            {
              "name": "TEA Party",
              "count": 37
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 957
            },
            {
              "name": "Vision New Zealand",
              "count": 15
            }
          ]
        }
      },
      {
        "name": "HAURAKI-WAIKATO",
        "candidate": {
          "informal": 985,
          "votes": [
            {
              "name": "HILL, Richard",
              "count": 426
            },
            {
              "name": "LAMBERT, Phillip Stephen",
              "count": 820
            },
            {
              "name": "MAHUTA, Nanaia",
              "count": 15885
            },
            {
              "name": "POKERE-PHILLIPS, Donna",
              "count": 6225
            }
          ]
        },
        "party": {
          "informal": 525,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 322
            },
            {
              "name": "Advance NZ",
              "count": 685
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 486
            },
            {
              "name": "Green Party",
              "count": 1557
            },
            {
              "name": "HeartlandNZ",
              "count": 21
            },
            {
              "name": "Labour Party",
              "count": 15884
            },
            {
              "name": "Māori Party",
              "count": 3008
            },
            {
              "name": "National Party",
              "count": 915
            },
            {
              "name": "New Conservative",
              "count": 100
            },
            {
              "name": "New Zealand First Party",
              "count": 898
            },
            {
              "name": "NZ Outdoors Party",
              "count": 28
            },
            {
              "name": "ONE Party",
              "count": 69
            },
            {
              "name": "Social Credit",
              "count": 3
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 9
            },
            {
              "name": "TEA Party",
              "count": 1
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 250
            },
            {
              "name": "Vision New Zealand",
              "count": 301
            }
          ]
        }
      },
      {
        "name": "IKAROA-RĀWHITI",
        "candidate": {
          "informal": 820,
          "votes": [
            {
              "name": "HILL, Melissa",
              "count": 245
            },
            {
              "name": "KEREKERE, Elizabeth",
              "count": 2080
            },
            {
              "name": "KUPENGA, Waitangi",
              "count": 791
            },
            {
              "name": "TE AU-SKIPWORTH, Heather",
              "count": 7597
            },
            {
              "name": "THURSTON, Kelly",
              "count": 196
            },
            {
              "name": "WHAITIRI, Meka",
              "count": 13642
            }
          ]
        },
        "party": {
          "informal": 472,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 223
            },
            {
              "name": "Advance NZ",
              "count": 638
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 486
            },
            {
              "name": "Green Party",
              "count": 1647
            },
            {
              "name": "HeartlandNZ",
              "count": 0
            },
            {
              "name": "Labour Party",
              "count": 17111
            },
            {
              "name": "Māori Party",
              "count": 3089
            },
            {
              "name": "National Party",
              "count": 593
            },
            {
              "name": "New Conservative",
              "count": 96
            },
            {
              "name": "New Zealand First Party",
              "count": 956
            },
            {
              "name": "NZ Outdoors Party",
              "count": 44
            },
            {
              "name": "ONE Party",
              "count": 164
            },
            {
              "name": "Social Credit",
              "count": 3
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 6
            },
            {
              "name": "TEA Party",
              "count": 6
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 189
            },
            {
              "name": "Vision New Zealand",
              "count": 161
            }
          ]
        }
      },
      {
        "name": "TE TAI HAUĀURU",
        "candidate": {
          "informal": 709,
          "votes": [
            {
              "name": "APIATA, Noeline",
              "count": 797
            },
            {
              "name": "BAILEY-TAURUA, Korrallie",
              "count": 333
            },
            {
              "name": "McKEE, Kiri",
              "count": 316
            },
            {
              "name": "MORGAN, Joshua",
              "count": 248
            },
            {
              "name": "NGAREWA-PACKER, Debbie",
              "count": 11112
            },
            {
              "name": "RURAWHE, Adrian",
              "count": 12147
            }
          ]
        },
        "party": {
          "informal": 407,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 313
            },
            {
              "name": "Advance NZ",
              "count": 705
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 549
            },
            {
              "name": "Green Party",
              "count": 1762
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 16022
            },
            {
              "name": "Māori Party",
              "count": 4058
            },
            {
              "name": "National Party",
              "count": 785
            },
            {
              "name": "New Conservative",
              "count": 86
            },
            {
              "name": "New Zealand First Party",
              "count": 922
            },
            {
              "name": "NZ Outdoors Party",
              "count": 44
            },
            {
              "name": "ONE Party",
              "count": 208
            },
            {
              "name": "Social Credit",
              "count": 9
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 4
            },
            {
              "name": "TEA Party",
              "count": 4
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 260
            },
            {
              "name": "Vision New Zealand",
              "count": 118
            }
          ]
        }
      },
      {
        "name": "TE TAI TOKERAU",
        "candidate": {
          "informal": 812,
          "votes": [
            {
              "name": "ARAHANGA-EPIHA, Janice",
              "count": 433
            },
            {
              "name": "DAVIS, Kelvin",
              "count": 14932
            },
            {
              "name": "DEARLOVE, Clinton",
              "count": 265
            },
            {
              "name": "HERBERT, Maki",
              "count": 2131
            },
            {
              "name": "KAPA-KINGI, Mariameno",
              "count": 6768
            },
            {
              "name": "MOHOAWHENUA, Moemoea",
              "count": 129
            },
            {
              "name": "SHORTLAND, Daniel",
              "count": 296
            },
            {
              "name": "TE KAHIKA, Billy",
              "count": 1349
            }
          ]
        },
        "party": {
          "informal": 474,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 363
            },
            {
              "name": "Advance NZ",
              "count": 771
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 791
            },
            {
              "name": "Green Party",
              "count": 2044
            },
            {
              "name": "HeartlandNZ",
              "count": 3
            },
            {
              "name": "Labour Party",
              "count": 16692
            },
            {
              "name": "Māori Party",
              "count": 2828
            },
            {
              "name": "National Party",
              "count": 1002
            },
            {
              "name": "New Conservative",
              "count": 108
            },
            {
              "name": "New Zealand First Party",
              "count": 1974
            },
            {
              "name": "NZ Outdoors Party",
              "count": 25
            },
            {
              "name": "ONE Party",
              "count": 284
            },
            {
              "name": "Social Credit",
              "count": 3
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 9
            },
            {
              "name": "TEA Party",
              "count": 1
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 279
            },
            {
              "name": "Vision New Zealand",
              "count": 141
            }
          ]
        }
      },
      {
        "name": "TE TAI TONGA",
        "candidate": {
          "informal": 879,
          "votes": [
            {
              "name": "FERRIS, Tākuta",
              "count": 7422
            },
            {
              "name": "McDONALD, Anituhia Rebel",
              "count": 1732
            },
            {
              "name": "PARETUTANGANUI-TAMATI, Ariana",
              "count": 3324
            },
            {
              "name": "THOMS, Matiu",
              "count": 965
            },
            {
              "name": "TIRIKATENE, Rino",
              "count": 14277
            },
            {
              "name": "TUHAKA, Raymond",
              "count": 606
            }
          ]
        },
        "party": {
          "informal": 417,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 822
            },
            {
              "name": "Advance NZ",
              "count": 635
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 631
            },
            {
              "name": "Green Party",
              "count": 3448
            },
            {
              "name": "HeartlandNZ",
              "count": 7
            },
            {
              "name": "Labour Party",
              "count": 17543
            },
            {
              "name": "Māori Party",
              "count": 2596
            },
            {
              "name": "National Party",
              "count": 1685
            },
            {
              "name": "New Conservative",
              "count": 208
            },
            {
              "name": "New Zealand First Party",
              "count": 1030
            },
            {
              "name": "NZ Outdoors Party",
              "count": 58
            },
            {
              "name": "ONE Party",
              "count": 98
            },
            {
              "name": "Social Credit",
              "count": 7
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 15
            },
            {
              "name": "TEA Party",
              "count": 5
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 547
            },
            {
              "name": "Vision New Zealand",
              "count": 130
            }
          ]
        }
      },
      {
        "name": "WAIARIKI",
        "candidate": {
          "informal": 639,
          "votes": [
            {
              "name": "BROUGHTON, Riki Wayne",
              "count": 198
            },
            {
              "name": "COFFEY, Tamati Gerald",
              "count": 11553
            },
            {
              "name": "TAMAKI, Hannah",
              "count": 1171
            },
            {
              "name": "TEKOWHAI, Rawiri",
              "count": 428
            },
            {
              "name": "WAITITI, Rawiri",
              "count": 12389
            },
            {
              "name": "WILLIAMS, Ema",
              "count": 747
            }
          ]
        },
        "party": {
          "informal": 417,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 257
            },
            {
              "name": "Advance NZ",
              "count": 733
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 421
            },
            {
              "name": "Green Party",
              "count": 1622
            },
            {
              "name": "HeartlandNZ",
              "count": 1
            },
            {
              "name": "Labour Party",
              "count": 16552
            },
            {
              "name": "Māori Party",
              "count": 4843
            },
            {
              "name": "National Party",
              "count": 651
            },
            {
              "name": "New Conservative",
              "count": 105
            },
            {
              "name": "New Zealand First Party",
              "count": 1001
            },
            {
              "name": "NZ Outdoors Party",
              "count": 67
            },
            {
              "name": "ONE Party",
              "count": 142
            },
            {
              "name": "Social Credit",
              "count": 4
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 4
            },
            {
              "name": "TEA Party",
              "count": 7
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 246
            },
            {
              "name": "Vision New Zealand",
              "count": 626
            }
          ]
        }
      },
      {
        "name": "TĀMAKI MAKAURAU",
        "candidate": {
          "informal": 910,
          "votes": [
            {
              "name": "ANDERSON, Erina",
              "count": 427
            },
            {
              "name": "DAVIDSON, Marama",
              "count": 5006
            },
            {
              "name": "HENARE, Peeni",
              "count": 10256
            },
            {
              "name": "TAMIHERE, John",
              "count": 9329
            }
          ]
        },
        "party": {
          "informal": 485,
          "votes": [
            {
              "name": "ACT New Zealand",
              "count": 284
            },
            {
              "name": "Advance NZ",
              "count": 490
            },
            {
              "name": "Aotearoa Legalise Cannabis Party",
              "count": 393
            },
            {
              "name": "Green Party",
              "count": 2801
            },
            {
              "name": "HeartlandNZ",
              "count": 2
            },
            {
              "name": "Labour Party",
              "count": 16066
            },
            {
              "name": "Māori Party",
              "count": 3401
            },
            {
              "name": "National Party",
              "count": 832
            },
            {
              "name": "New Conservative",
              "count": 138
            },
            {
              "name": "New Zealand First Party",
              "count": 1126
            },
            {
              "name": "NZ Outdoors Party",
              "count": 16
            },
            {
              "name": "ONE Party",
              "count": 64
            },
            {
              "name": "Social Credit",
              "count": 5
            },
            {
              "name": "Sustainable New Zealand Party",
              "count": 13
            },
            {
              "name": "TEA Party",
              "count": 8
            },
            {
              "name": "The Opportunities Party (TOP)",
              "count": 274
            },
            {
              "name": "Vision New Zealand",
              "count": 388
            }
          ]
        }
      }
    ],
    "party": {
      "informal": 21375,
      "votes": [
        {
          "name": "ACT New Zealand",
          "count": 219028
        },
        {
          "name": "Advance NZ",
          "count": 28434
        },
        {
          "name": "Aotearoa Legalise Cannabis Party",
          "count": 13328
        },
        {
          "name": "Green Party",
          "count": 226757
        },
        {
          "name": "HeartlandNZ",
          "count": 914
        },
        {
          "name": "Labour Party",
          "count": 1443545
        },
        {
          "name": "Māori Party",
          "count": 33633
        },
        {
          "name": "National Party",
          "count": 738274
        },
        {
          "name": "New Conservative",
          "count": 42614
        },
        {
          "name": "New Zealand First Party",
          "count": 75020
        },
        {
          "name": "NZ Outdoors Party",
          "count": 3256
        },
        {
          "name": "ONE Party",
          "count": 8121
        },
        {
          "name": "Social Credit",
          "count": 1520
        },
        {
          "name": "Sustainable New Zealand Party",
          "count": 1880
        },
        {
          "name": "TEA Party",
          "count": 2414
        },
        {
          "name": "The Opportunities Party (TOP)",
          "count": 43449
        },
        {
          "name": "Vision New Zealand",
          "count": 4236
        }
      ]
    }
  }
  