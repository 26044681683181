import { FC } from "react";
import { AppStateProps } from "../../types/AppState";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { parties } from "../../live-election/election-data/party";


type PartyRow = {
    name: string;
    votes: number;
    percent: number;
}

export const PartyPageOverviewTable: FC<AppStateProps> = (props: AppStateProps) => {

    const { appState } = props;

    const rows : PartyRow[] = [];

    parties.forEach(partyName => {
        rows.push({
            name: partyName,
            votes: 0,
            percent: 0
        });
    });

    let TOTAL_VOTES = 0;
    
    appState.liveResults?.electorateResults.forEach((electorateResult) => {
        electorateResult.partyVotes.forEach((partyResult) => {
            const partyRow = rows.find(row => row.name === partyResult.partyName);
            if (partyRow) {
                partyRow.votes += partyResult.votes;
            }

            TOTAL_VOTES += partyResult.votes;
        });
    });

    // sort by votes
    rows.sort((a, b) => b.votes - a.votes);

    
    // calculate percentages
    rows.forEach((r) => {
        r.percent = r.votes / TOTAL_VOTES || 0;
    })


    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Party</TableCell>
                        <TableCell align="right">Total Votes</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.votes.toLocaleString()}</TableCell>
                            <TableCell align="right">{(Math.round(row.percent * 10000) / 100).toFixed(2)}%</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
