import { FC } from "react";
import { Container } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Electorate } from "../live-election/election-data/electorate";
import { AppStateProps } from "../types/AppState";
import { previous_election_data_2020 } from "../previous-election/2020";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ElectorateCandidateVote } from "../types/LiveElectionData";
import { findCandidateParty } from "../live-election";


export type ElectorateDetailsProps = AppStateProps & {
    electorate: Electorate;
};

export const ElectorateDetailsComponent: FC<ElectorateDetailsProps> = (props: ElectorateDetailsProps) => {

    const { appState, electorate } = props;

    // LIVE RESULTS
    const liveElection = appState.liveResults?.electorateResults.find((e) => e.electorateName.toUpperCase() === electorate.toUpperCase());
    console.log(liveElection);

    const liveCandidates: ElectorateCandidateVote[] = liveElection?.candidateVotes || [];
    // sort live candidates by votes
    liveCandidates.sort((a, b) => b.votes - a.votes);

    // current leader
    const currentLeader = liveCandidates[0];

    // make sure votes are not 0
    let winningPartySeatName = '';
    let winningCandidateName = 'No votes yet...';

    if(currentLeader?.votes > 0) {
        winningPartySeatName = "(" + (findCandidateParty(currentLeader.candidateName) || 'No party')+ ")";
        winningCandidateName = currentLeader.candidateName;
    }



    // PREVIOUS ELECTION RESULTS
    const previousElection = previous_election_data_2020.electorates.find((e) => e.name.toUpperCase() === electorate.toUpperCase());
    console.log(previousElection);

    const previousElectionResultsCandidates: { name: string, totalVotes: number }[] = [];

    previousElection?.candidate.votes.forEach((c) => {
        previousElectionResultsCandidates.push({ name: c.name, totalVotes: c.count });
    });

    previousElectionResultsCandidates.sort((a, b) => b.totalVotes - a.totalVotes);
    // add in informal votes at the end
    previousElectionResultsCandidates.push({ name: 'Informal Votes', totalVotes: previousElection?.candidate.informal || 0 });

    return (
        <>
            <Container>
                <Typography variant="h2">{electorate}</Typography>
            </Container>


            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Live Results</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Alert severity="info">Election results will show here at 7pm</Alert> */}

                    <Container>
                        <Typography variant="h5">Current Seat Leader: {winningCandidateName} {winningPartySeatName}</Typography>
                    </Container>

                    <Container>
                        <Divider></Divider>
                    </Container>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Candidate</TableCell>
                                    <TableCell align="right">Total Votes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {liveCandidates.map((row) => (
                                    <TableRow
                                        key={row.candidateName}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.candidateName}
                                        </TableCell>
                                        <TableCell align="right">{row.votes}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Previous Election (2020)</Typography>
                </AccordionSummary>
                <AccordionDetails>



                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Candidate</TableCell>
                                    <TableCell align="right">Total Votes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {previousElectionResultsCandidates.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.totalVotes}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </AccordionDetails>
            </Accordion>
        </>
    )
}
