
import axios from "axios";
import packageInfo from "../../package.json";
import { AppState } from "../types/AppState";
import EventEmitter from "events";
import { Electorate } from "./election-data/electorate";
import { Party } from "./election-data/party";
import { ElectoratePartyVote } from "../types/LiveElectionData";
import { candidates } from "./election-data/candidates";

const SECONDS_TO_WAIT = (process.env.NODE_ENV === 'development' ? 5 : 20)

/** How long to request latest data from server */
const UPDATE_INTERVAL = 1000 * SECONDS_TO_WAIT; // 20 seconds

/** Endpoint to get latest app state */
const APP_STATE_URL = '/data/app.json';

class ElectionNight2023 extends EventEmitter {

    /** What version this app is. If we receive a different version from the server we will refresh the page */
    appVersion: string = packageInfo.version;

    /** Last etag from server so we don't need to get the full app state */
    lastEtag: string | null = null;

    /** Last app state we got from the server */
    appState: AppState = {};

    constructor() {

        super();

        console.log(`ElectionNight2023 v${this.appVersion} is running...`);

        this.updateLoop();
    }

    /** Schedules an update of the state in UPDATE_INTERVAL seconds */
    scheduleUpdate() {

        // if(process.env.NODE_ENV === 'development') {
        //     console.log("NOT SCHEDULING UPDATE");
        //     return;
        // }

        // call this function again in UPDATE_INTERVAL seconds
        setTimeout(() => {
            this.updateLoop();
        }, UPDATE_INTERVAL);

    }


    refreshPage() {

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    }

    async syncAppLatestState() {

        const headers: { [key: string]: string } = {};
        const appStateHeadRequest = await axios.head<AppState>(APP_STATE_URL, { headers });

        if (appStateHeadRequest.status !== 200) {
            console.error(`Error getting app GET: ${appStateHeadRequest.status}`);
            return;
        }

        // check if the etag has changed
        if (appStateHeadRequest.headers['etag'] === this.lastEtag) {
            // console.log(`Etag has not changed. Not updating`);
            return;
        }

        // else it has changed, go get the full app state
        console.log(`Etag has changed. Getting full app state...`);
        const appStateRequest = await axios.get<AppState>(APP_STATE_URL, { headers });

        const appState = appStateRequest.data;
        this.appState = appState;
        // console.log(`Got app state: ${JSON.stringify(appState)}`);

        // update the last etag
        this.lastEtag = appStateRequest.headers['etag'];

        if(appState.minimumVersion !== this.appVersion) {
            console.log(`App version has changed from ${this.appVersion} to ${appState.minimumVersion}. Refreshing page...`);
            this.refreshPage();
            return;
        }

        this.emit('update', appState);
    }

    subscribe(listener: (appState: AppState) => void) {
        this.on('update', listener)
    }


    async updateLoop() {

        // console.log(`UpdateLoop`);

        await this.syncAppLatestState();


        this.scheduleUpdate();
    }
}

export const ElectionApp = new ElectionNight2023();


type ElectorateSeatPrediction = {
    electorateName: Electorate;
    winningPartySeat?: Party; // there might not be a winning party here
    partyVotes: ElectoratePartyVote[];
}


/**
 * Figures out who will win a seat in a given electorate
 */
export function predictElectorateSeat(appState: AppState, electorateName: Electorate) : ElectorateSeatPrediction|undefined {

    const electorate = appState.liveResults?.electorateResults?.find(e => e.electorateName === electorateName);
    if(!electorate) {
        return;
    }

    // get the person who has the most votes
    const winner = electorate.candidateVotes.sort((a, b) => b.votes - a.votes)[0];
    // find their party
    const winnerParty = findCandidateParty(winner.candidateName);

    return {
        electorateName,
        winningPartySeat: winnerParty,
        partyVotes: electorate.partyVotes
    }
}


export function findCandidateParty(candidateName: string) : Party|undefined {

    const foundParty = candidates.find(c => c.name.toUpperCase() === candidateName.toUpperCase())?.party;
    if(foundParty) {
        return foundParty;
    }

    // swap the name around
    const swappedName = candidateName.split(', ').reverse().join(' ').toUpperCase();
    console.log(swappedName);
    return candidates.find(c => c.name.toUpperCase() === swappedName)?.party;

}
