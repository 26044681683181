import { Electorate } from "./electorate";

export const parties_from_electorates = ['ACT New Zealand',
    'Animal Justice Party',
    'Aotearoa Legalise Cannabis Party',
    'DemocracyNZ',
    'Economic Euthenics',
    'Future Youth Party',
    'Green Party',
    'Human Rights Party',
    'Independent',
    'Labour Party',
    'Leighton Baker Party',
    'Money Free Party NZ',
    'NAP',
    'National Party',
    'New Conservatives',
    'New Nation Party',
    'New World Order McCann Party',
    'NewZeal',
    'New Zealand First Party',
    'New Zealand Loyal',
    'New Zealand Sovereignty Party',
    'Northland Party',
    'NZ Outdoors & Freedom Party',
    'Progressive Aotearoa New Zealand',
    'Protect and Prosper New Zealand',
    'Republic of New Zealand Party',
    'Rock the Vote NZ',
    'Te Pāti Māori',
    'The Opportunities Party (TOP)',
    'Vision New Zealand',
    'Workers Now',
] as const;

export const parties_from_list = ['ACT New Zealand',
    'Animal Justice Party',
    'Aotearoa Legalise Cannabis Party',
    'DemocracyNZ',
    'Freedoms NZ',
    'Green Party',
    'Labour Party',
    'Leighton Baker Party',
    'National Party',
    'New Conservatives',
    'New Nation Party',
    'NewZeal',
    'New Zealand First Party',
    'New Zealand Loyal',
    'Te Pāti Māori',
    'The Opportunities Party (TOP)',
    'Women’s Rights Party']



export const parties = Array.from(new Set([...parties_from_electorates, ...parties_from_list]));

export type Party = typeof parties[number];


export function getPartyColourHex(party: Party) : string|undefined {
    switch(party) {
        case 'Labour Party': return '#D82A20';
        case 'National Party': return '#00529F';
        case 'ACT New Zealand': return '#FDE401';
        case 'Green Party': return '#098137';
        case 'Te Pāti Māori': return '#344054';
    }
}


export type PartyListSeat = {
    party: Party,
    listNo: number,
    ballotLastName: string,
    ballotFirstName: string,
    electorate: Electorate
}

export const partyListSeats : PartyListSeat[] = [
    {
        "party": "ACT New Zealand",
        "listNo": 1,
        "ballotLastName": "SEYMOUR",
        "ballotFirstName": "David Breen",
        "electorate": "Epsom"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 2,
        "ballotLastName": "VAN VELDEN",
        "ballotFirstName": "Brooke",
        "electorate": "Tāmaki"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 3,
        "ballotLastName": "McKEE",
        "ballotFirstName": "Nicole",
        "electorate": "Rongotai"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 4,
        "ballotLastName": "STEPHENSON",
        "ballotFirstName": "Todd",
        "electorate": "Southland"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 5,
        "ballotLastName": "HOGGARD",
        "ballotFirstName": "Andrew John",
        "electorate": "Rangitīkei"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 6,
        "ballotLastName": "CHHOUR",
        "ballotFirstName": "Karen",
        "electorate": "Upper Harbour"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 7,
        "ballotLastName": "CAMERON",
        "ballotFirstName": "Mark",
        "electorate": "Northland"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 8,
        "ballotLastName": "COURT",
        "ballotFirstName": "Simon",
        "electorate": "Te Atatū"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 9,
        "ballotLastName": "PARMAR",
        "ballotFirstName": "Parmjeet",
        "electorate": "Pakuranga"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 10,
        "ballotLastName": "TRASK",
        "ballotFirstName": "Laura",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 11,
        "ballotLastName": "LUXTON",
        "ballotFirstName": "Cameron",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 12,
        "ballotLastName": "MODKOVA",
        "ballotFirstName": "Antonia",
        "electorate": "Panmure-Ōtāhuhu"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 13,
        "ballotLastName": "PARMAR",
        "ballotFirstName": "Himanshu",
        "electorate": "Hamilton East"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 14,
        "ballotLastName": "SEVERIN",
        "ballotFirstName": "Toni",
        "electorate": "Christchurch East"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 15,
        "ballotLastName": "HARVEY",
        "ballotFirstName": "Ben",
        "electorate": "Selwyn"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 16,
        "ballotLastName": "DOUGLAS",
        "ballotFirstName": "Rob",
        "electorate": "Tukituki"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 17,
        "ballotLastName": "BAILLIE",
        "ballotFirstName": "Chris",
        "electorate": "Nelson"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 18,
        "ballotLastName": "YOUNG",
        "ballotFirstName": "Christine",
        "electorate": "Tauranga"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 19,
        "ballotLastName": "COZENS",
        "ballotFirstName": "Zane",
        "electorate": "Taupō"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 20,
        "ballotLastName": "FOLEY",
        "ballotFirstName": "Leo George",
        "electorate": "Northcote"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 21,
        "ballotLastName": "YALLOP",
        "ballotFirstName": "Anna",
        "electorate": "North Shore"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 22,
        "ballotLastName": "BROWN",
        "ballotFirstName": "Lily",
        "electorate": "Mana"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 23,
        "ballotLastName": "McCORMICK",
        "ballotFirstName": "Mike",
        "electorate": "Papakura"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 24,
        "ballotLastName": "PARKINS",
        "ballotFirstName": "Andy",
        "electorate": "Hutt South"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 25,
        "ballotLastName": "POOLE",
        "ballotFirstName": "Felix",
        "electorate": "Auckland Central"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 26,
        "ballotLastName": "CHOPRA",
        "ballotFirstName": "Rahul",
        "electorate": "Mt Roskill"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 27,
        "ballotLastName": "HOWE",
        "ballotFirstName": "Michael",
        "electorate": "East Coast"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 28,
        "ballotLastName": "MURPHY",
        "ballotFirstName": "Ollie",
        "electorate": "Mt Albert"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 29,
        "ballotLastName": "GRIFFITHS",
        "ballotFirstName": "Keith",
        "electorate": "Kaikōura"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 30,
        "ballotLastName": "AH CHEE",
        "ballotFirstName": "Rae",
        "electorate": "Takanini"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 31,
        "ballotLastName": "STEVENSON",
        "ballotFirstName": "Susan",
        "electorate": "Hamilton West"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 32,
        "ballotLastName": "McCOOK",
        "ballotFirstName": "Michael",
        "electorate": "East Coast Bays"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 33,
        "ballotLastName": "ALVAREZ DE LUGO",
        "ballotFirstName": "Juan",
        "electorate": "New Lynn"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 34,
        "ballotLastName": "MILEWSKI",
        "ballotFirstName": "Pawel",
        "electorate": "Napier"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 35,
        "ballotLastName": "CHRISTENSEN",
        "ballotFirstName": "Neil",
        "electorate": "Port Waikato"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 36,
        "ballotLastName": "ANDERSON",
        "ballotFirstName": "Dion",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 37,
        "ballotLastName": "ANGELO",
        "ballotFirstName": "Simon",
        "electorate": "Whangaparāoa"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 38,
        "ballotLastName": "BEAMISH",
        "ballotFirstName": "Sean",
        "electorate": "Waitaki"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 39,
        "ballotLastName": "BRETHERTON",
        "ballotFirstName": "Susy",
        "electorate": "Whangārei"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 40,
        "ballotLastName": "CAMPBELL",
        "ballotFirstName": "Ross Eric",
        "electorate": "Waimakariri"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 41,
        "ballotLastName": "CASEY",
        "ballotFirstName": "Simon Francis",
        "electorate": "Wairarapa"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 42,
        "ballotLastName": "CURRAN",
        "ballotFirstName": "Jacob",
        "electorate": "Kelston"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 43,
        "ballotLastName": "DONALDSON",
        "ballotFirstName": "Scott Ian",
        "electorate": "Invercargill"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 44,
        "ballotLastName": "FISKEN",
        "ballotFirstName": "Matthew Hunter",
        "electorate": "Christchurch Central"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 45,
        "ballotLastName": "HARNETT",
        "ballotFirstName": "Mike",
        "electorate": "Palmerston North"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 46,
        "ballotLastName": "JOSEPH",
        "ballotFirstName": "Pothen",
        "electorate": "Māngere"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 47,
        "ballotLastName": "LILLEY",
        "ballotFirstName": "Kelly",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 48,
        "ballotLastName": "MATTHEWS",
        "ballotFirstName": "Kevin",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 49,
        "ballotLastName": "MEFFAN",
        "ballotFirstName": "Burty",
        "electorate": "Taieri"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 50,
        "ballotLastName": "ROZEBOOM",
        "ballotFirstName": "Marten",
        "electorate": "Rotorua"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 51,
        "ballotLastName": "RUSH",
        "ballotFirstName": "Sean Edward",
        "electorate": "Ōtaki"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 52,
        "ballotLastName": "DREDGE",
        "ballotFirstName": "Craig",
        "electorate": "Whanganui"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 53,
        "ballotLastName": "NEWMAN",
        "ballotFirstName": "Tim",
        "electorate": "Dunedin"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 54,
        "ballotLastName": "BURNS",
        "ballotFirstName": "Bo",
        "electorate": "Botany"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 55,
        "ballotLastName": "VERBURG",
        "ballotFirstName": "Joanna",
        "electorate": "Coromandel"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 56,
        "ballotLastName": "LYNN",
        "ballotFirstName": "Ankita",
        "electorate": "Wigram"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 57,
        "ballotLastName": "BAILEY",
        "ballotFirstName": "Brent",
        "electorate": "Kaipara ki Mahurangi"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 58,
        "ballotLastName": "ONISHCHENKO",
        "ballotFirstName": "Margo",
        "electorate": "Maungakiekie"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 59,
        "ballotLastName": "HURLE",
        "ballotFirstName": "Michael",
        "electorate": "Remutaka"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 60,
        "ballotLastName": "DAY",
        "ballotFirstName": "Paul",
        "electorate": "Ōhāriu"
    },
    {
        "party": "ACT New Zealand",
        "listNo": 61,
        "ballotLastName": "McGECHAN",
        "ballotFirstName": "Bruce",
        "electorate": "New Plymouth"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 1,
        "ballotLastName": "McNEIL",
        "ballotFirstName": "Robert",
        "electorate": "Botany"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 2,
        "ballotLastName": "RIPPON",
        "ballotFirstName": "Anna Joy",
        "electorate": "Port Waikato"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 3,
        "ballotLastName": "EMET",
        "ballotFirstName": "Atom",
        "electorate": "Rongotai"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 4,
        "ballotLastName": "HANCOCK",
        "ballotFirstName": "Nicholas",
        "electorate": "Pakuranga"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 5,
        "ballotLastName": "WERETA",
        "ballotFirstName": "Danette",
        "electorate": "Christchurch East"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 6,
        "ballotLastName": "WATKINS",
        "ballotFirstName": "Hamish",
        "electorate": "New Plymouth"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 7,
        "ballotLastName": "JACKSON",
        "ballotFirstName": "Sarah",
        "electorate": "Christchurch Central"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 8,
        "ballotLastName": "CARRINGTON",
        "ballotFirstName": "Lily",
        "electorate": "Hamilton East"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 9,
        "ballotLastName": "KYLE",
        "ballotFirstName": "Sandra",
        "electorate": "Whanganui"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 10,
        "ballotLastName": "JEET",
        "ballotFirstName": "Paran",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 11,
        "ballotLastName": "GORDON",
        "ballotFirstName": "Christopher",
        "electorate": "Wellington Central"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 12,
        "ballotLastName": "BEGG",
        "ballotFirstName": "Douglas",
        "electorate": "Palmerston North"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 13,
        "ballotLastName": "STOKMAN",
        "ballotFirstName": "Chelsea Jordan",
        "electorate": "Tauranga"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 14,
        "ballotLastName": "GRATTAN",
        "ballotFirstName": "Caitlin",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 15,
        "ballotLastName": "KANE",
        "ballotFirstName": "Madeleine",
        "electorate": "Auckland Central"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 16,
        "ballotLastName": "WILSON",
        "ballotFirstName": "Melanie",
        "electorate": "Hamilton West"
    },
    {
        "party": "Animal Justice Party",
        "listNo": 17,
        "ballotLastName": "TULLOCH",
        "ballotFirstName": "Lynley Kim",
        "electorate": "Takanini"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 1,
        "ballotLastName": "HERBERT",
        "ballotFirstName": "Maki",
        "electorate": "Te Tai Tokerau"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 2,
        "ballotLastName": "APPLEBY",
        "ballotFirstName": "Michael George",
        "electorate": "Wellington Central"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 3,
        "ballotLastName": "BRITNELL",
        "ballotFirstName": "Michael",
        "electorate": "Christchurch Central"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 4,
        "ballotLastName": "LAMBERT",
        "ballotFirstName": "Paula",
        "electorate": "Christchurch East"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 5,
        "ballotLastName": "O'CONNELL",
        "ballotFirstName": "Kevin Patrick",
        "electorate": "Invercargill"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 6,
        "ballotLastName": "BRITNELL",
        "ballotFirstName": "Irinka",
        "electorate": "Ilam"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 7,
        "ballotLastName": "LYE",
        "ballotFirstName": "Jeffrey",
        "electorate": "Northland"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 8,
        "ballotLastName": "de JONGE",
        "ballotFirstName": "Jeni",
        "electorate": "Whangārei"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 9,
        "ballotLastName": "COKER",
        "ballotFirstName": "Christopher",
        "electorate": "Auckland Central"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 10,
        "ballotLastName": "ANDERSON",
        "ballotFirstName": "Blair",
        "electorate": "Wigram"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 11,
        "ballotLastName": "MANNING",
        "ballotFirstName": "Romana (Marnz)",
        "electorate": "Tukituki"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 12,
        "ballotLastName": "GRUMBALL",
        "ballotFirstName": "Anntwinette",
        "electorate": "Southland"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 13,
        "ballotLastName": "ROBIN",
        "ballotFirstName": "Rebecca",
        "electorate": "Te Tai Tonga"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 14,
        "ballotLastName": "McDERMOTT",
        "ballotFirstName": "Adrian",
        "electorate": "Dunedin"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 15,
        "ballotLastName": "McDONALD",
        "ballotFirstName": "Anituhia",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Aotearoa Legalise Cannabis Party",
        "listNo": 16,
        "ballotLastName": "BROWN",
        "ballotFirstName": "Tony",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 1,
        "ballotLastName": "KING",
        "ballotFirstName": "Matt",
        "electorate": "Northland"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 2,
        "ballotLastName": "MALCOLM",
        "ballotFirstName": "Gordon",
        "electorate": "Waimakariri"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 3,
        "ballotLastName": "TILDSLEY",
        "ballotFirstName": "Leao",
        "electorate": "Kelston"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 4,
        "ballotLastName": "LANGFORD",
        "ballotFirstName": "Martin",
        "electorate": "Napier"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 5,
        "ballotLastName": "BRIGHT",
        "ballotFirstName": "Scotty",
        "electorate": "Port Waikato"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 6,
        "ballotLastName": "O'CONNELL",
        "ballotFirstName": "Juanita",
        "electorate": "Ilam"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 7,
        "ballotLastName": "SMALL",
        "ballotFirstName": "Roger",
        "electorate": "Waitaki"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 8,
        "ballotLastName": "BURGESS",
        "ballotFirstName": "Diana",
        "electorate": "Whangārei"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 9,
        "ballotLastName": "ALLINGTON",
        "ballotFirstName": "Doug",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 10,
        "ballotLastName": "BREWER",
        "ballotFirstName": "Sarah",
        "electorate": "Kaipara ki Mahurangi"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 11,
        "ballotLastName": "CAMPBELL",
        "ballotFirstName": "Sandra",
        "electorate": "Kaikōura"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 12,
        "ballotLastName": "ROBINSON",
        "ballotFirstName": "Chris",
        "electorate": "East Coast"
    },
    {
        "party": "DemocracyNZ",
        "listNo": 13,
        "ballotLastName": "LAYBOURN",
        "ballotFirstName": "Craig",
        "electorate": "Whangaparāoa"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 1,
        "ballotLastName": "TAMAKI",
        "ballotFirstName": "Brian",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 2,
        "ballotLastName": "GREY",
        "ballotFirstName": "Sue",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 3,
        "ballotLastName": "TAMAKI",
        "ballotFirstName": "Hannah",
        "electorate": "Tāmaki Makaurau"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 4,
        "ballotLastName": "POKERE-PHILLIPS",
        "ballotFirstName": "Donna",
        "electorate": "Hauraki-Waikato"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 5,
        "ballotLastName": "ROBERTSON",
        "ballotFirstName": "Heker",
        "electorate": "Remutaka"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 6,
        "ballotLastName": "COOK",
        "ballotFirstName": "Aly",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 7,
        "ballotLastName": "DAVIE",
        "ballotFirstName": "Paul",
        "electorate": "Auckland Central"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 8,
        "ballotLastName": "MANN",
        "ballotFirstName": "Phineas",
        "electorate": "New Lynn"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 9,
        "ballotLastName": "WARREN",
        "ballotFirstName": "Caine",
        "electorate": "Manurewa"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 10,
        "ballotLastName": "ALCOCK",
        "ballotFirstName": "John",
        "electorate": "Pakuranga"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 11,
        "ballotLastName": "TEPOU",
        "ballotFirstName": "Sarai",
        "electorate": "Coromandel"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 12,
        "ballotLastName": "HUNIA",
        "ballotFirstName": "Charles Tiki",
        "electorate": "Waiariki"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 13,
        "ballotLastName": "DONALDSON",
        "ballotFirstName": "Mark",
        "electorate": "Northcote"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 14,
        "ballotLastName": "CHUAH",
        "ballotFirstName": "Eric",
        "electorate": "Maungakiekie"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 15,
        "ballotLastName": "SIMMONS",
        "ballotFirstName": "Alan",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 16,
        "ballotLastName": "NGAHUKA",
        "ballotFirstName": "Michael",
        "electorate": "Tukituki"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 17,
        "ballotLastName": "SUDHAMALLA",
        "ballotFirstName": "Vijay",
        "electorate": "Port Waikato"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 18,
        "ballotLastName": "CLARKSON",
        "ballotFirstName": "Michael",
        "electorate": "Rangitata"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 19,
        "ballotLastName": "MACLEAN",
        "ballotFirstName": "Naomi",
        "electorate": "Southland"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 20,
        "ballotLastName": "ALAILIMA",
        "ballotFirstName": "Fuiavailili",
        "electorate": "Māngere"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 21,
        "ballotLastName": "MOUNTJOY",
        "ballotFirstName": "Grant",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 22,
        "ballotLastName": "DU PLOOY",
        "ballotFirstName": "Rudi",
        "electorate": "Hamilton West"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 23,
        "ballotLastName": "MOKARAKA",
        "ballotFirstName": "Karl",
        "electorate": "Panmure-Ōtāhuhu"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 24,
        "ballotLastName": "HARVEY",
        "ballotFirstName": "Kathy",
        "electorate": "Papakura"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 25,
        "ballotLastName": "ROA",
        "ballotFirstName": "Merania",
        "electorate": "Rongotai"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 26,
        "ballotLastName": "WINIATA",
        "ballotFirstName": "Paris",
        "electorate": "Te Tai Hauāuru"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 27,
        "ballotLastName": "SAMUELS",
        "ballotFirstName": "Leon",
        "electorate": "Tauranga"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 28,
        "ballotLastName": "LIM",
        "ballotFirstName": "Patrick",
        "electorate": "Ōhāriu"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 29,
        "ballotLastName": "DORNAN",
        "ballotFirstName": "Lois",
        "electorate": "Waikato"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 30,
        "ballotLastName": "LIM",
        "ballotFirstName": "Meg",
        "electorate": "Wellington Central"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 31,
        "ballotLastName": "PACKER",
        "ballotFirstName": "Leighton",
        "electorate": "East Coast"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 32,
        "ballotLastName": "RANGITUTIA",
        "ballotFirstName": "Max",
        "electorate": "Hutt South"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 33,
        "ballotLastName": "RAISON",
        "ballotFirstName": "Daryl",
        "electorate": "Taranaki-King Country"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 34,
        "ballotLastName": "TERRILL",
        "ballotFirstName": "Judith",
        "electorate": "Invercargill"
    },
    {
        "party": "Freedoms NZ",
        "listNo": 35,
        "ballotLastName": "TUHAKARAINA",
        "ballotFirstName": "Ata",
        "electorate": "Ikaroa-Rāwhiti"
    },
    {
        "party": "Green Party",
        "listNo": 1,
        "ballotLastName": "DAVIDSON",
        "ballotFirstName": "Marama Mere-Ana",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Green Party",
        "listNo": 2,
        "ballotLastName": "SHAW",
        "ballotFirstName": "James",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Green Party",
        "listNo": 3,
        "ballotLastName": "SWARBRICK",
        "ballotFirstName": "Chlöe",
        "electorate": "Auckland Central"
    },
    {
        "party": "Green Party",
        "listNo": 4,
        "ballotLastName": "GENTER",
        "ballotFirstName": "Julie Anne",
        "electorate": "Rongotai"
    },
    {
        "party": "Green Party",
        "listNo": 5,
        "ballotLastName": "TUIONO",
        "ballotFirstName": "Teanau",
        "electorate": "Palmerston North"
    },
    {
        "party": "Green Party",
        "listNo": 6,
        "ballotLastName": "PHAM",
        "ballotFirstName": "Lan",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "Green Party",
        "listNo": 7,
        "ballotLastName": "GHAHRAMAN",
        "ballotFirstName": "Golriz",
        "electorate": "Kelston"
    },
    {
        "party": "Green Party",
        "listNo": 8,
        "ballotLastName": "MENENDEZ MARCH",
        "ballotFirstName": "Ricardo",
        "electorate": "Mt Albert"
    },
    {
        "party": "Green Party",
        "listNo": 9,
        "ballotLastName": "ABEL",
        "ballotFirstName": "Steve",
        "electorate": "New Lynn"
    },
    {
        "party": "Green Party",
        "listNo": 10,
        "ballotLastName": "LYNDON",
        "ballotFirstName": "Hūhana",
        "electorate": "Te Tai Tokerau"
    },
    {
        "party": "Green Party",
        "listNo": 11,
        "ballotLastName": "COLLINS",
        "ballotFirstName": "Fa'anānā Efeso",
        "electorate": "Panmure-Ōtāhuhu"
    },
    {
        "party": "Green Party",
        "listNo": 12,
        "ballotLastName": "WILLIS",
        "ballotFirstName": "Scott",
        "electorate": "Taieri"
    },
    {
        "party": "Green Party",
        "listNo": 13,
        "ballotLastName": "TANA",
        "ballotFirstName": "Darleen",
        "electorate": "Tāmaki Makaurau"
    },
    {
        "party": "Green Party",
        "listNo": 14,
        "ballotLastName": "CARTER",
        "ballotFirstName": "Kahurangi",
        "electorate": "Christchurch Central"
    },
    {
        "party": "Green Party",
        "listNo": 15,
        "ballotLastName": "WADE-BROWN",
        "ballotFirstName": "Celia",
        "electorate": "Wairarapa"
    },
    {
        "party": "Green Party",
        "listNo": 16,
        "ballotLastName": "XU-NAN",
        "ballotFirstName": "Lawrence Xi",
        "electorate": "Epsom"
    },
    {
        "party": "Green Party",
        "listNo": 17,
        "ballotLastName": "HERNANDEZ",
        "ballotFirstName": "Francisco",
        "electorate": "Dunedin"
    },
    {
        "party": "Green Party",
        "listNo": 18,
        "ballotLastName": "DOYLE",
        "ballotFirstName": "Benjamin",
        "electorate": "Hamilton West"
    },
    {
        "party": "Green Party",
        "listNo": 19,
        "ballotLastName": "DAVIDSON",
        "ballotFirstName": "Mike",
        "electorate": "Ilam"
    },
    {
        "party": "Green Party",
        "listNo": 20,
        "ballotLastName": "RODGERS",
        "ballotFirstName": "Stephanie",
        "electorate": "Ōhāriu"
    },
    {
        "party": "Green Party",
        "listNo": 21,
        "ballotLastName": "SANIS WALGAMPOLA",
        "ballotFirstName": "Suveen",
        "electorate": "Mt Roskill"
    },
    {
        "party": "Green Party",
        "listNo": 22,
        "ballotLastName": "KENNEDY",
        "ballotFirstName": "Dave",
        "electorate": "Southland"
    },
    {
        "party": "Green Party",
        "listNo": 23,
        "ballotLastName": "DAO-MCLAY",
        "ballotFirstName": "Gina",
        "electorate": "Mana"
    },
    {
        "party": "Green Party",
        "listNo": 24,
        "ballotLastName": "PENNEY",
        "ballotFirstName": "Reina Tuai",
        "electorate": "Northland"
    },
    {
        "party": "Green Party",
        "listNo": 25,
        "ballotLastName": "RATCLIFFE",
        "ballotFirstName": "Nick",
        "electorate": "Tukituki"
    },
    {
        "party": "Green Party",
        "listNo": 26,
        "ballotLastName": "WESLEY",
        "ballotFirstName": "Richard",
        "electorate": "Wigram"
    },
    {
        "party": "Green Party",
        "listNo": 27,
        "ballotLastName": "JENNINGS",
        "ballotFirstName": "Neelu",
        "electorate": "Hutt South"
    },
    {
        "party": "Green Party",
        "listNo": 28,
        "ballotLastName": "SAMANT",
        "ballotFirstName": "Sapna",
        "electorate": "Maungakiekie"
    },
    {
        "party": "Green Party",
        "listNo": 29,
        "ballotLastName": "BASKETT",
        "ballotFirstName": "Pat",
        "electorate": "North Shore"
    },
    {
        "party": "Green Party",
        "listNo": 30,
        "ballotLastName": "BAZELEY",
        "ballotFirstName": "Rick",
        "electorate": "Whangārei"
    },
    {
        "party": "Green Party",
        "listNo": 31,
        "ballotLastName": "BROWN",
        "ballotFirstName": "Zephyr",
        "electorate": "Kaipara ki Mahurangi"
    },
    {
        "party": "Green Party",
        "listNo": 32,
        "ballotLastName": "CROOKS",
        "ballotFirstName": "Justin",
        "electorate": "Tauranga"
    },
    {
        "party": "Green Party",
        "listNo": 33,
        "ballotLastName": "DOYLE",
        "ballotFirstName": "Thea",
        "electorate": "Upper Harbour"
    },
    {
        "party": "Green Party",
        "listNo": 34,
        "ballotLastName": "GILCHRIST",
        "ballotFirstName": "Barbara",
        "electorate": "Rangitata"
    },
    {
        "party": "Green Party",
        "listNo": 35,
        "ballotLastName": "GREALEY",
        "ballotFirstName": "Pamela",
        "electorate": "Coromandel"
    },
    {
        "party": "Green Party",
        "listNo": 36,
        "ballotLastName": "HANSEN",
        "ballotFirstName": "Pleasance",
        "electorate": "Waitaki"
    },
    {
        "party": "Green Party",
        "listNo": 37,
        "ballotLastName": "LONG",
        "ballotFirstName": "Bernard",
        "electorate": "Rangitīkei"
    },
    {
        "party": "Green Party",
        "listNo": 38,
        "ballotLastName": "MACMILLAN",
        "ballotFirstName": "Matthew",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "Green Party",
        "listNo": 39,
        "ballotLastName": "McCUBBIN",
        "ballotFirstName": "Richard",
        "electorate": "Kaikōura"
    },
    {
        "party": "Green Party",
        "listNo": 40,
        "ballotLastName": "MUHAMMAD",
        "ballotFirstName": "Ali",
        "electorate": "Ōtaki"
    },
    {
        "party": "Green Party",
        "listNo": 41,
        "ballotLastName": "NEUMANN",
        "ballotFirstName": "Zooey",
        "electorate": "Te Atatū"
    },
    {
        "party": "Green Party",
        "listNo": 42,
        "ballotLastName": "NEWMAN",
        "ballotFirstName": "Lorraine",
        "electorate": "Whangaparāoa"
    },
    {
        "party": "Green Party",
        "listNo": 43,
        "ballotLastName": "NORTON",
        "ballotFirstName": "Chris",
        "electorate": "Remutaka"
    },
    {
        "party": "Green Party",
        "listNo": 44,
        "ballotLastName": "O'CONNOR PATENA",
        "ballotFirstName": "George",
        "electorate": "Taupō"
    },
    {
        "party": "Green Party",
        "listNo": 45,
        "ballotLastName": "RICHARDS",
        "ballotFirstName": "Steve",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "Green Party",
        "listNo": 46,
        "ballotLastName": "SANSON",
        "ballotFirstName": "Marion",
        "electorate": "Whanganui"
    },
    {
        "party": "Green Party",
        "listNo": 47,
        "ballotLastName": "SHAW",
        "ballotFirstName": "Andrew",
        "electorate": "Northcote"
    },
    {
        "party": "Green Party",
        "listNo": 48,
        "ballotLastName": "SYKES",
        "ballotFirstName": "Peter",
        "electorate": "Māngere"
    },
    {
        "party": "Green Party",
        "listNo": 49,
        "ballotLastName": "WALKER",
        "ballotFirstName": "Jordan",
        "electorate": "East Coast"
    },
    {
        "party": "Labour Party",
        "listNo": 1,
        "ballotLastName": "HIPKINS",
        "ballotFirstName": "Chris",
        "electorate": "Remutaka"
    },
    {
        "party": "Labour Party",
        "listNo": 2,
        "ballotLastName": "DAVIS",
        "ballotFirstName": "Kelvin",
        "electorate": "Te Tai Tokerau"
    },
    {
        "party": "Labour Party",
        "listNo": 3,
        "ballotLastName": "SEPULONI",
        "ballotFirstName": "Carmel",
        "electorate": "Kelston"
    },
    {
        "party": "Labour Party",
        "listNo": 4,
        "ballotLastName": "ROBERTSON",
        "ballotFirstName": "Grant",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 5,
        "ballotLastName": "WOODS",
        "ballotFirstName": "Megan",
        "electorate": "Wigram"
    },
    {
        "party": "Labour Party",
        "listNo": 6,
        "ballotLastName": "TINETTI",
        "ballotFirstName": "Jan",
        "electorate": "Tauranga"
    },
    {
        "party": "Labour Party",
        "listNo": 7,
        "ballotLastName": "VERRALL",
        "ballotFirstName": "Ayesha",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 8,
        "ballotLastName": "JACKSON",
        "ballotFirstName": "Willie",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 9,
        "ballotLastName": "PRIME",
        "ballotFirstName": "Willow-Jean",
        "electorate": "Northland"
    },
    {
        "party": "Labour Party",
        "listNo": 10,
        "ballotLastName": "O'CONNOR",
        "ballotFirstName": "Damien",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "Labour Party",
        "listNo": 11,
        "ballotLastName": "RURAWHE",
        "ballotFirstName": "Adrian",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 12,
        "ballotLastName": "LITTLE",
        "ballotFirstName": "Andrew",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 13,
        "ballotLastName": "PARKER",
        "ballotFirstName": "David",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 14,
        "ballotLastName": "HENARE",
        "ballotFirstName": "Peeni",
        "electorate": "Tāmaki Makaurau"
    },
    {
        "party": "Labour Party",
        "listNo": 15,
        "ballotLastName": "RADHAKRISHNAN",
        "ballotFirstName": "Priyanca",
        "electorate": "Maungakiekie"
    },
    {
        "party": "Labour Party",
        "listNo": 16,
        "ballotLastName": "McANULTY",
        "ballotFirstName": "Kieran",
        "electorate": "Wairarapa"
    },
    {
        "party": "Labour Party",
        "listNo": 17,
        "ballotLastName": "ANDERSEN",
        "ballotFirstName": "Ginny",
        "electorate": "Hutt South"
    },
    {
        "party": "Labour Party",
        "listNo": 18,
        "ballotLastName": "EDMONDS",
        "ballotFirstName": "Barbara",
        "electorate": "Mana"
    },
    {
        "party": "Labour Party",
        "listNo": 19,
        "ballotLastName": "LUXTON",
        "ballotFirstName": "Jo",
        "electorate": "Rangitata"
    },
    {
        "party": "Labour Party",
        "listNo": 20,
        "ballotLastName": "WEBB",
        "ballotFirstName": "Duncan",
        "electorate": "Christchurch Central"
    },
    {
        "party": "Labour Party",
        "listNo": 21,
        "ballotLastName": "TIRIKATENE",
        "ballotFirstName": "Rino",
        "electorate": "Te Tai Tonga"
    },
    {
        "party": "Labour Party",
        "listNo": 22,
        "ballotLastName": "RUSSELL",
        "ballotFirstName": "Deborah",
        "electorate": "New Lynn"
    },
    {
        "party": "Labour Party",
        "listNo": 23,
        "ballotLastName": "BROOKING",
        "ballotFirstName": "Rachel",
        "electorate": "Dunedin"
    },
    {
        "party": "Labour Party",
        "listNo": 24,
        "ballotLastName": "SALESA",
        "ballotFirstName": "Jenny",
        "electorate": "Panmure-Ōtāhuhu"
    },
    {
        "party": "Labour Party",
        "listNo": 25,
        "ballotLastName": "UTIKERE",
        "ballotFirstName": "Tangi",
        "electorate": "Palmerston North"
    },
    {
        "party": "Labour Party",
        "listNo": 26,
        "ballotLastName": "BELICH",
        "ballotFirstName": "Camilla",
        "electorate": "Epsom"
    },
    {
        "party": "Labour Party",
        "listNo": 27,
        "ballotLastName": "McLELLAN",
        "ballotFirstName": "Tracey",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "Labour Party",
        "listNo": 28,
        "ballotLastName": "HALBERT",
        "ballotFirstName": "Shanan",
        "electorate": "Northcote"
    },
    {
        "party": "Labour Party",
        "listNo": 29,
        "ballotLastName": "BENNETT",
        "ballotFirstName": "Glen",
        "electorate": "New Plymouth"
    },
    {
        "party": "Labour Party",
        "listNo": 30,
        "ballotLastName": "WALTERS",
        "ballotFirstName": "Vanushi",
        "electorate": "Upper Harbour"
    },
    {
        "party": "Labour Party",
        "listNo": 31,
        "ballotLastName": "DANSEY",
        "ballotFirstName": "Georgie",
        "electorate": "Hamilton East"
    },
    {
        "party": "Labour Party",
        "listNo": 32,
        "ballotLastName": "ROSEWARNE",
        "ballotFirstName": "Dan",
        "electorate": "Waimakariri"
    },
    {
        "party": "Labour Party",
        "listNo": 33,
        "ballotLastName": "CHEN",
        "ballotFirstName": "Naisi",
        "electorate": "East Coast Bays"
    },
    {
        "party": "Labour Party",
        "listNo": 34,
        "ballotLastName": "KANONGATA'A",
        "ballotFirstName": "Anahila",
        "electorate": "Papakura"
    },
    {
        "party": "Labour Party",
        "listNo": 35,
        "ballotLastName": "ROBERTS",
        "ballotFirstName": "Angela",
        "electorate": "Taranaki-King Country"
    },
    {
        "party": "Labour Party",
        "listNo": 36,
        "ballotLastName": "COFFEY",
        "ballotFirstName": "Tamati",
        "electorate": "East Coast"
    },
    {
        "party": "Labour Party",
        "listNo": 37,
        "ballotLastName": "OMER",
        "ballotFirstName": "Ibrahim",
        "electorate": "Wellington Central"
    },
    {
        "party": "Labour Party",
        "listNo": 38,
        "ballotLastName": "LEAVASA",
        "ballotFirstName": "Anae Neru",
        "electorate": "Takanini"
    },
    {
        "party": "Labour Party",
        "listNo": 39,
        "ballotLastName": "BOYNTON",
        "ballotFirstName": "Toni",
        "electorate": "Waiariki"
    },
    {
        "party": "Labour Party",
        "listNo": 40,
        "ballotLastName": "LORCK",
        "ballotFirstName": "Anna",
        "electorate": "Tukituki"
    },
    {
        "party": "Labour Party",
        "listNo": 41,
        "ballotLastName": "HAMPTON",
        "ballotFirstName": "George",
        "electorate": "North Shore"
    },
    {
        "party": "Labour Party",
        "listNo": 42,
        "ballotLastName": "BOYACK",
        "ballotFirstName": "Rachel Elizabeth",
        "electorate": "Nelson"
    },
    {
        "party": "Labour Party",
        "listNo": 43,
        "ballotLastName": "WARREN-CLARK",
        "ballotFirstName": "Angie",
        "electorate": "Whangārei"
    },
    {
        "party": "Labour Party",
        "listNo": 44,
        "ballotLastName": "CRAIG",
        "ballotFirstName": "Liz",
        "electorate": "Invercargill"
    },
    {
        "party": "Labour Party",
        "listNo": 45,
        "ballotLastName": "WOOD",
        "ballotFirstName": "Michael Philip",
        "electorate": "Mt Roskill"
    },
    {
        "party": "Labour Party",
        "listNo": 46,
        "ballotLastName": "NGOBI",
        "ballotFirstName": "Terisa",
        "electorate": "Ōtaki"
    },
    {
        "party": "Labour Party",
        "listNo": 47,
        "ballotLastName": "WHITE",
        "ballotFirstName": "Helen",
        "electorate": "Mt Albert"
    },
    {
        "party": "Labour Party",
        "listNo": 48,
        "ballotLastName": "WILLIAMS",
        "ballotFirstName": "Arena",
        "electorate": "Manurewa"
    },
    {
        "party": "Labour Party",
        "listNo": 49,
        "ballotLastName": "TWYFORD",
        "ballotFirstName": "Phil",
        "electorate": "Te Atatū"
    },
    {
        "party": "Labour Party",
        "listNo": 50,
        "ballotLastName": "LEWIS",
        "ballotFirstName": "Steph",
        "electorate": "Whanganui"
    },
    {
        "party": "Labour Party",
        "listNo": 51,
        "ballotLastName": "PALLETT",
        "ballotFirstName": "Sarah",
        "electorate": "Ilam"
    },
    {
        "party": "Labour Party",
        "listNo": 52,
        "ballotLastName": "LEARY",
        "ballotFirstName": "Ingrid",
        "electorate": "Taieri"
    },
    {
        "party": "Labour Party",
        "listNo": 53,
        "ballotLastName": "SOSENE",
        "ballotFirstName": "Lemauga Lydia",
        "electorate": "Māngere"
    },
    {
        "party": "Labour Party",
        "listNo": 54,
        "ballotLastName": "TAIKATO",
        "ballotFirstName": "Pare",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "Labour Party",
        "listNo": 55,
        "ballotLastName": "MULLER PALLARÈS",
        "ballotFirstName": "Estefania",
        "electorate": "Whangaparāoa"
    },
    {
        "party": "Labour Party",
        "listNo": 56,
        "ballotLastName": "FITZSIMONS",
        "ballotFirstName": "Fleur",
        "electorate": "Rongotai"
    },
    {
        "party": "Labour Party",
        "listNo": 57,
        "ballotLastName": "DAVIDSON",
        "ballotFirstName": "Reuben",
        "electorate": "Christchurch East"
    },
    {
        "party": "Labour Party",
        "listNo": 58,
        "ballotLastName": "RUANE",
        "ballotFirstName": "Nick",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Labour Party",
        "listNo": 59,
        "ballotLastName": "SOLOMONE",
        "ballotFirstName": "Fesaitu",
        "electorate": "Tāmaki"
    },
    {
        "party": "Labour Party",
        "listNo": 60,
        "ballotLastName": "HUTCHINSON",
        "ballotFirstName": "Mark",
        "electorate": "Napier"
    },
    {
        "party": "Labour Party",
        "listNo": 61,
        "ballotLastName": "HENRY",
        "ballotFirstName": "Nerissa",
        "electorate": "Pakuranga"
    },
    {
        "party": "Labour Party",
        "listNo": 62,
        "ballotLastName": "WILLIAMSON",
        "ballotFirstName": "Myra",
        "electorate": "Hamilton West"
    },
    {
        "party": "Labour Party",
        "listNo": 63,
        "ballotLastName": "SIMS",
        "ballotFirstName": "Oscar",
        "electorate": "Auckland Central"
    },
    {
        "party": "Labour Party",
        "listNo": 64,
        "ballotLastName": "AL-BUSTANJI",
        "ballotFirstName": "Aladdin",
        "electorate": "Taupō"
    },
    {
        "party": "Labour Party",
        "listNo": 65,
        "ballotLastName": "KEEL",
        "ballotFirstName": "Gwendoline",
        "electorate": "Port Waikato"
    },
    {
        "party": "Leighton Baker Party",
        "listNo": 1,
        "ballotLastName": "BAKER",
        "ballotFirstName": "Leighton",
        "electorate": "Waimakariri"
    },
    {
        "party": "Leighton Baker Party",
        "listNo": 2,
        "ballotLastName": "GILLESPIE",
        "ballotFirstName": "Wendy",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "Leighton Baker Party",
        "listNo": 3,
        "ballotLastName": "CULLIMORE",
        "ballotFirstName": "Debra Marie",
        "electorate": "Wigram"
    },
    {
        "party": "National Party",
        "listNo": 1,
        "ballotLastName": "LUXON",
        "ballotFirstName": "Christopher",
        "electorate": "Botany"
    },
    {
        "party": "National Party",
        "listNo": 2,
        "ballotLastName": "WILLIS",
        "ballotFirstName": "Nicola",
        "electorate": "Ōhāriu"
    },
    {
        "party": "National Party",
        "listNo": 3,
        "ballotLastName": "BISHOP",
        "ballotFirstName": "Chris",
        "electorate": "Hutt South"
    },
    {
        "party": "National Party",
        "listNo": 4,
        "ballotLastName": "RETI",
        "ballotFirstName": "Shane",
        "electorate": "Whangārei"
    },
    {
        "party": "National Party",
        "listNo": 5,
        "ballotLastName": "GOLDSMITH",
        "ballotFirstName": "Paul",
        "electorate": "Epsom"
    },
    {
        "party": "National Party",
        "listNo": 6,
        "ballotLastName": "UPSTON",
        "ballotFirstName": "Louise",
        "electorate": "Taupō"
    },
    {
        "party": "National Party",
        "listNo": 7,
        "ballotLastName": "STANFORD",
        "ballotFirstName": "Erica",
        "electorate": "East Coast Bays"
    },
    {
        "party": "National Party",
        "listNo": 8,
        "ballotLastName": "DOOCEY",
        "ballotFirstName": "Matt",
        "electorate": "Waimakariri"
    },
    {
        "party": "National Party",
        "listNo": 9,
        "ballotLastName": "BROWN",
        "ballotFirstName": "Simeon",
        "electorate": "Pakuranga"
    },
    {
        "party": "National Party",
        "listNo": 10,
        "ballotLastName": "COLLINS",
        "ballotFirstName": "Judith",
        "electorate": "Papakura"
    },
    {
        "party": "National Party",
        "listNo": 11,
        "ballotLastName": "MITCHELL",
        "ballotFirstName": "Mark",
        "electorate": "Whangaparāoa"
    },
    {
        "party": "National Party",
        "listNo": 12,
        "ballotLastName": "McCLAY",
        "ballotFirstName": "Todd",
        "electorate": "Rotorua"
    },
    {
        "party": "National Party",
        "listNo": 13,
        "ballotLastName": "LEE",
        "ballotFirstName": "Melissa",
        "electorate": "Mt Albert"
    },
    {
        "party": "National Party",
        "listNo": 14,
        "ballotLastName": "BROWNLEE",
        "ballotFirstName": "Gerry",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "National Party",
        "listNo": 15,
        "ballotLastName": "BAYLY",
        "ballotFirstName": "Andrew",
        "electorate": "Port Waikato"
    },
    {
        "party": "National Party",
        "listNo": 16,
        "ballotLastName": "SIMMONDS",
        "ballotFirstName": "Penny",
        "electorate": "Invercargill"
    },
    {
        "party": "National Party",
        "listNo": 17,
        "ballotLastName": "WATTS",
        "ballotFirstName": "Simon",
        "electorate": "North Shore"
    },
    {
        "party": "National Party",
        "listNo": 18,
        "ballotLastName": "PENK",
        "ballotFirstName": "Chris",
        "electorate": "Kaipara ki Mahurangi"
    },
    {
        "party": "National Party",
        "listNo": 19,
        "ballotLastName": "GRIGG",
        "ballotFirstName": "Nicola",
        "electorate": "Selwyn"
    },
    {
        "party": "National Party",
        "listNo": 20,
        "ballotLastName": "LU",
        "ballotFirstName": "Nancy",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "National Party",
        "listNo": 21,
        "ballotLastName": "REDMAYNE",
        "ballotFirstName": "Suze",
        "electorate": "Rangitīkei"
    },
    {
        "party": "National Party",
        "listNo": 22,
        "ballotLastName": "NIMON",
        "ballotFirstName": "Katie",
        "electorate": "Napier"
    },
    {
        "party": "National Party",
        "listNo": 23,
        "ballotLastName": "WEDD",
        "ballotFirstName": "Catherine",
        "electorate": "Tukituki"
    },
    {
        "party": "National Party",
        "listNo": 24,
        "ballotLastName": "POTAKA",
        "ballotFirstName": "Tama William",
        "electorate": "Hamilton West"
    },
    {
        "party": "National Party",
        "listNo": 25,
        "ballotLastName": "LOHENI",
        "ballotFirstName": "Akenese Loreta",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "National Party",
        "listNo": 26,
        "ballotLastName": "PUGH",
        "ballotFirstName": "Maureen",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "National Party",
        "listNo": 27,
        "ballotLastName": "CHATTERTON",
        "ballotFirstName": "Emma",
        "electorate": "Remutaka"
    },
    {
        "party": "National Party",
        "listNo": 28,
        "ballotLastName": "CHRISTMAS",
        "ballotFirstName": "James",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "National Party",
        "listNo": 29,
        "ballotLastName": "STEPHENS",
        "ballotFirstName": "Dale Aotea",
        "electorate": "Christchurch Central"
    },
    {
        "party": "National Party",
        "listNo": 30,
        "ballotLastName": "KILARI",
        "ballotFirstName": "Siva",
        "electorate": "Manurewa"
    },
    {
        "party": "National Party",
        "listNo": 31,
        "ballotLastName": "HIPANGO",
        "ballotFirstName": "Harete",
        "electorate": "Te Tai Hauāuru"
    },
    {
        "party": "National Party",
        "listNo": 32,
        "ballotLastName": "BOURKE",
        "ballotFirstName": "Rosemary",
        "electorate": "Māngere"
    },
    {
        "party": "National Party",
        "listNo": 33,
        "ballotLastName": "HUGHES",
        "ballotFirstName": "Frances",
        "electorate": "Mana"
    },
    {
        "party": "National Party",
        "listNo": 34,
        "ballotLastName": "GARCIA",
        "ballotFirstName": "Paulo",
        "electorate": "New Lynn"
    },
    {
        "party": "National Party",
        "listNo": 35,
        "ballotLastName": "CAMERON",
        "ballotFirstName": "Blair",
        "electorate": "Nelson"
    },
    {
        "party": "National Party",
        "listNo": 36,
        "ballotLastName": "KURIGER",
        "ballotFirstName": "Barbara",
        "electorate": "Taranaki-King Country"
    },
    {
        "party": "National Party",
        "listNo": 37,
        "ballotLastName": "SUMMERFIELD",
        "ballotFirstName": "Tracy",
        "electorate": "Wigram"
    },
    {
        "party": "National Party",
        "listNo": 38,
        "ballotLastName": "TE HAU",
        "ballotFirstName": "Hinurewa",
        "electorate": "Tāmaki Makaurau"
    },
    {
        "party": "National Party",
        "listNo": 39,
        "ballotLastName": "NICHOLAS",
        "ballotFirstName": "Angee",
        "electorate": "Te Atatū"
    },
    {
        "party": "National Party",
        "listNo": 40,
        "ballotLastName": "WEENINK",
        "ballotFirstName": "Vanessa",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "National Party",
        "listNo": 41,
        "ballotLastName": "NAKHLE",
        "ballotFirstName": "Rima",
        "electorate": "Takanini"
    },
    {
        "party": "National Party",
        "listNo": 42,
        "ballotLastName": "SCHAUMKEL",
        "ballotFirstName": "Ruby",
        "electorate": "Kelston"
    },
    {
        "party": "National Party",
        "listNo": 43,
        "ballotLastName": "MURALIDHAR",
        "ballotFirstName": "Mahesh",
        "electorate": "Auckland Central"
    },
    {
        "party": "National Party",
        "listNo": 44,
        "ballotLastName": "KIRKPATRICK",
        "ballotFirstName": "Dana",
        "electorate": "East Coast"
    },
    {
        "party": "National Party",
        "listNo": 45,
        "ballotLastName": "SHEERAN",
        "ballotFirstName": "Scott",
        "electorate": "Wellington Central"
    },
    {
        "party": "National Party",
        "listNo": 46,
        "ballotLastName": "RANDHAWA",
        "ballotFirstName": "Navtej",
        "electorate": "Panmure-Ōtāhuhu"
    },
    {
        "party": "National Party",
        "listNo": 47,
        "ballotLastName": "BATES",
        "ballotFirstName": "Carl",
        "electorate": "Whanganui"
    },
    {
        "party": "National Party",
        "listNo": 48,
        "ballotLastName": "CHEUNG",
        "ballotFirstName": "Carlos",
        "electorate": "Mt Roskill"
    },
    {
        "party": "National Party",
        "listNo": 49,
        "ballotLastName": "FRENCH",
        "ballotFirstName": "Matthew",
        "electorate": "Taieri"
    },
    {
        "party": "National Party",
        "listNo": 50,
        "ballotLastName": "STOCK",
        "ballotFirstName": "Matt",
        "electorate": "Christchurch East"
    },
    {
        "party": "National Party",
        "listNo": 51,
        "ballotLastName": "MUTHU",
        "ballotFirstName": "Karunā",
        "electorate": "Rongotai"
    },
    {
        "party": "National Party",
        "listNo": 52,
        "ballotLastName": "BANSAL",
        "ballotFirstName": "Ankit",
        "electorate": "Palmerston North"
    },
    {
        "party": "National Party",
        "listNo": 53,
        "ballotLastName": "MOONEY",
        "ballotFirstName": "Joseph",
        "electorate": "Southland"
    },
    {
        "party": "National Party",
        "listNo": 54,
        "ballotLastName": "O'CONNOR",
        "ballotFirstName": "Simon",
        "electorate": "Tāmaki"
    },
    {
        "party": "National Party",
        "listNo": 55,
        "ballotLastName": "SIMPSON",
        "ballotFirstName": "Scott",
        "electorate": "Coromandel"
    },
    {
        "party": "National Party",
        "listNo": 56,
        "ballotLastName": "SMITH",
        "ballotFirstName": "Stuart",
        "electorate": "Kaikōura"
    },
    {
        "party": "National Party",
        "listNo": 57,
        "ballotLastName": "UFFINDELL",
        "ballotFirstName": "Sam",
        "electorate": "Tauranga"
    },
    {
        "party": "National Party",
        "listNo": 58,
        "ballotLastName": "VAN DE MOLEN",
        "ballotFirstName": "Tim",
        "electorate": "Waikato"
    },
    {
        "party": "National Party",
        "listNo": 59,
        "ballotLastName": "ANDERSON",
        "ballotFirstName": "Miles",
        "electorate": "Waitaki"
    },
    {
        "party": "National Party",
        "listNo": 60,
        "ballotLastName": "BIDOIS",
        "ballotFirstName": "Dan",
        "electorate": "Northcote"
    },
    {
        "party": "National Party",
        "listNo": 61,
        "ballotLastName": "BUTTERICK",
        "ballotFirstName": "Mike",
        "electorate": "Wairarapa"
    },
    {
        "party": "National Party",
        "listNo": 62,
        "ballotLastName": "BREWER",
        "ballotFirstName": "Cameron",
        "electorate": "Upper Harbour"
    },
    {
        "party": "National Party",
        "listNo": 63,
        "ballotLastName": "CAMPBELL",
        "ballotFirstName": "Hamish",
        "electorate": "Ilam"
    },
    {
        "party": "National Party",
        "listNo": 64,
        "ballotLastName": "COSTLEY",
        "ballotFirstName": "Tim",
        "electorate": "Ōtaki"
    },
    {
        "party": "National Party",
        "listNo": 65,
        "ballotLastName": "FLEMING",
        "ballotFirstName": "Greg",
        "electorate": "Maungakiekie"
    },
    {
        "party": "New Conservatives",
        "listNo": 1,
        "ballotLastName": "HOUGHTON",
        "ballotFirstName": "Helen",
        "electorate": "Christchurch East"
    },
    {
        "party": "New Conservatives",
        "listNo": 2,
        "ballotLastName": "DE BOER",
        "ballotFirstName": "Dieuwe",
        "electorate": "Botany"
    },
    {
        "party": "New Conservatives",
        "listNo": 3,
        "ballotLastName": "THOMAS",
        "ballotFirstName": "Karl",
        "electorate": "Rangitata"
    },
    {
        "party": "New Conservatives",
        "listNo": 4,
        "ballotLastName": "DEACON",
        "ballotFirstName": "Paul Kenneth",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Conservatives",
        "listNo": 5,
        "ballotLastName": "O'BRIEN",
        "ballotFirstName": "Chris",
        "electorate": "Ilam"
    },
    {
        "party": "New Conservatives",
        "listNo": 6,
        "ballotLastName": "HOOD",
        "ballotFirstName": "Alister",
        "electorate": "Kelston"
    },
    {
        "party": "New Conservatives",
        "listNo": 7,
        "ballotLastName": "ELDER",
        "ballotFirstName": "Cyndee",
        "electorate": "Dunedin"
    },
    {
        "party": "New Conservatives",
        "listNo": 8,
        "ballotLastName": "LANGRIDGE",
        "ballotFirstName": "Jono",
        "electorate": "Tauranga"
    },
    {
        "party": "New Conservatives",
        "listNo": 9,
        "ballotLastName": "COULTER",
        "ballotFirstName": "Abe",
        "electorate": "Selwyn"
    },
    {
        "party": "New Conservatives",
        "listNo": 10,
        "ballotLastName": "SENN",
        "ballotFirstName": "Steven",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 1,
        "ballotLastName": "JACOMB",
        "ballotFirstName": "Michael",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 2,
        "ballotLastName": "SLOCUM",
        "ballotFirstName": "Guy Dennis",
        "electorate": "Auckland Central"
    },
    {
        "party": "New Nation Party",
        "listNo": 3,
        "ballotLastName": "ROBINSON",
        "ballotFirstName": "Greg",
        "electorate": "New Plymouth"
    },
    {
        "party": "New Nation Party",
        "listNo": 4,
        "ballotLastName": "Van AMERSFOORT",
        "ballotFirstName": "Dolf",
        "electorate": "Rangitata"
    },
    {
        "party": "New Nation Party",
        "listNo": 5,
        "ballotLastName": "ELVIDGE",
        "ballotFirstName": "Jeremy",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 6,
        "ballotLastName": "BARNES",
        "ballotFirstName": "Nathan Lee",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 7,
        "ballotLastName": "NEWBOULD",
        "ballotFirstName": "Jan",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 8,
        "ballotLastName": "de VRIES",
        "ballotFirstName": "Anthony",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 9,
        "ballotLastName": "MACKIE",
        "ballotFirstName": "Angela Joan B",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Nation Party",
        "listNo": 10,
        "ballotLastName": "JOHNSTON",
        "ballotFirstName": "Brian",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 1,
        "ballotLastName": "PETERS",
        "ballotFirstName": "Winston",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 2,
        "ballotLastName": "JONES",
        "ballotFirstName": "Shane Geoffrey",
        "electorate": "Northland"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 3,
        "ballotLastName": "COSTELLO",
        "ballotFirstName": "Casey",
        "electorate": "Port Waikato"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 4,
        "ballotLastName": "PATTERSON",
        "ballotFirstName": "Mark",
        "electorate": "Taieri"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 5,
        "ballotLastName": "MARCROFT",
        "ballotFirstName": "Jenny",
        "electorate": "Kaipara ki Mahurangi"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 6,
        "ballotLastName": "ARBUCKLE",
        "ballotFirstName": "Jamie Arthur",
        "electorate": "Kaikōura"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 7,
        "ballotLastName": "FOSTER",
        "ballotFirstName": "Andy",
        "electorate": "Mana"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 8,
        "ballotLastName": "UNKOVICH",
        "ballotFirstName": "Tanya Marie",
        "electorate": "Epsom"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 9,
        "ballotLastName": "WILSON",
        "ballotFirstName": "David Ashley",
        "electorate": "Upper Harbour"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 10,
        "ballotLastName": "HARVEY",
        "ballotFirstName": "Erika",
        "electorate": "Tauranga"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 11,
        "ballotLastName": "MURFITT",
        "ballotFirstName": "Kirsten",
        "electorate": "Bay of Plenty"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 12,
        "ballotLastName": "DONOGHUE",
        "ballotFirstName": "Lee",
        "electorate": "Hutt South"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 13,
        "ballotLastName": "HUSBAND",
        "ballotFirstName": "Stuart",
        "electorate": "Waikato"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 14,
        "ballotLastName": "BENNEY",
        "ballotFirstName": "Gavin",
        "electorate": "Whangārei"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 15,
        "ballotLastName": "DEGIA-PALA",
        "ballotFirstName": "Anne",
        "electorate": "Kelston"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 16,
        "ballotLastName": "BALLANTYNE",
        "ballotFirstName": "Robert",
        "electorate": "Rangitata"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 17,
        "ballotLastName": "VERMEULEN",
        "ballotFirstName": "Helma",
        "electorate": "Rangitīkei"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 18,
        "ballotLastName": "TURNBULL",
        "ballotFirstName": "Laurie",
        "electorate": "Napier"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 19,
        "ballotLastName": "ARNEIL",
        "ballotFirstName": "Taylor",
        "electorate": "Wellington Central"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 20,
        "ballotLastName": "LANGEVELD",
        "ballotFirstName": "Keegan",
        "electorate": "Dunedin"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 21,
        "ballotLastName": "PEHI",
        "ballotFirstName": "Tira",
        "electorate": "Taupō"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 22,
        "ballotLastName": "WIREMU",
        "ballotFirstName": "Shane",
        "electorate": "Christchurch East"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 23,
        "ballotLastName": "ARNEIL",
        "ballotFirstName": "Mark",
        "electorate": "Christchurch Central"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 24,
        "ballotLastName": "WARREN",
        "ballotFirstName": "Michelle",
        "electorate": "Northcote"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 25,
        "ballotLastName": "MONDS",
        "ballotFirstName": "Robert",
        "electorate": "Papakura"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 26,
        "ballotLastName": "STONE",
        "ballotFirstName": "Kevin",
        "electorate": "Hamilton West"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 27,
        "ballotLastName": "FARRELLY",
        "ballotFirstName": "Jackie",
        "electorate": "West Coast-Tasman"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 28,
        "ballotLastName": "MILLS",
        "ballotFirstName": "Geoff",
        "electorate": "Rongotai"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 29,
        "ballotLastName": "ODERING",
        "ballotFirstName": "Anthony",
        "electorate": "Waitaki"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 30,
        "ballotLastName": "ARNOLD",
        "ballotFirstName": "William",
        "electorate": "Whanganui"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 31,
        "ballotLastName": "SINCLAIR",
        "ballotFirstName": "Craig",
        "electorate": "East Coast"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 32,
        "ballotLastName": "KNAAP",
        "ballotFirstName": "Russelle",
        "electorate": "Hamilton East"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 33,
        "ballotLastName": "KERSLAKE",
        "ballotFirstName": "Lindsay",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 34,
        "ballotLastName": "HOGG",
        "ballotFirstName": "Andrew",
        "electorate": "Maungakiekie"
    },
    {
        "party": "New Zealand First Party",
        "listNo": 35,
        "ballotLastName": "ANSELL",
        "ballotFirstName": "Caleb",
        "electorate": "Coromandel"
    },
    {
        "party": "New Zealand Loyal",
        "listNo": 1,
        "ballotLastName": "GUNN",
        "ballotFirstName": "Liz",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Zealand Loyal",
        "listNo": 2,
        "ballotLastName": "DREW",
        "ballotFirstName": "Peter",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "New Zealand Loyal",
        "listNo": 3,
        "ballotLastName": "ENGEL",
        "ballotFirstName": "Philip George",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 1,
        "ballotLastName": "NGARO",
        "ballotFirstName": "Alf Metuakore",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 2,
        "ballotLastName": "ADAMS",
        "ballotFirstName": "Paul",
        "electorate": "East Coast Bays"
    },
    {
        "party": "NewZeal",
        "listNo": 3,
        "ballotLastName": "BLACK-VERCOE",
        "ballotFirstName": "Kariana",
        "electorate": "Rotorua"
    },
    {
        "party": "NewZeal",
        "listNo": 4,
        "ballotLastName": "MEAD",
        "ballotFirstName": "Lisa Marie",
        "electorate": "Banks Peninsula"
    },
    {
        "party": "NewZeal",
        "listNo": 5,
        "ballotLastName": "PITIROI",
        "ballotFirstName": "Tony",
        "electorate": "Remutaka"
    },
    {
        "party": "NewZeal",
        "listNo": 6,
        "ballotLastName": "NIMMO",
        "ballotFirstName": "Charles",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 7,
        "ballotLastName": "WINITANA",
        "ballotFirstName": "Waipatu",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 8,
        "ballotLastName": "PITA",
        "ballotFirstName": "Tracey",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 9,
        "ballotLastName": "COLLING",
        "ballotFirstName": "Hayley",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 10,
        "ballotLastName": "PITA",
        "ballotFirstName": "Watson",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "NewZeal",
        "listNo": 11,
        "ballotLastName": "GREAVES",
        "ballotFirstName": "Marlene Angilene",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 1,
        "ballotLastName": "NGAREWA-PACKER",
        "ballotFirstName": "Debbie",
        "electorate": "Te Tai Hauāuru"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 2,
        "ballotLastName": "WAITITI",
        "ballotFirstName": "Rawiri",
        "electorate": "Waiariki"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 3,
        "ballotLastName": "WHAITIRI",
        "ballotFirstName": "Meka",
        "electorate": "Ikaroa-Rāwhiti"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 4,
        "ballotLastName": "MAIPI-CLARKE",
        "ballotFirstName": "Hana-Rawhiti",
        "electorate": "Hauraki-Waikato"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 5,
        "ballotLastName": "FERRIS",
        "ballotFirstName": "Tākuta",
        "electorate": "Te Tai Tonga"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 6,
        "ballotLastName": "KEMP",
        "ballotFirstName": "Takutai Tarsh",
        "electorate": "Tāmaki Makaurau"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 7,
        "ballotLastName": "KAPA-KINGI",
        "ballotFirstName": "Mariameno",
        "electorate": "Te Tai Tokerau"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 8,
        "ballotLastName": "RAUKAWA-TAIT",
        "ballotFirstName": "Merepeka",
        "electorate": "Rotorua"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 9,
        "ballotLastName": "KAPA-KINGI",
        "ballotFirstName": "Eru",
        "electorate": "Whangārei"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 10,
        "ballotLastName": "FLAVELL",
        "ballotFirstName": "Keanu",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 11,
        "ballotLastName": "PETERS",
        "ballotFirstName": "Hilda",
        "electorate": "Māngere"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 12,
        "ballotLastName": "POIHEGATAMA",
        "ballotFirstName": "Arabela",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 13,
        "ballotLastName": "HURIWAI-SEGER",
        "ballotFirstName": "Pere",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 14,
        "ballotLastName": "KEREAMA",
        "ballotFirstName": "Hoera",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 15,
        "ballotLastName": "KAPA",
        "ballotFirstName": "Te Ao",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 16,
        "ballotLastName": "BELL",
        "ballotFirstName": "Bridget Frances",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 17,
        "ballotLastName": "FLAVELL",
        "ballotFirstName": "Fallyn",
        "electorate": "East Coast"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 18,
        "ballotLastName": "HURA",
        "ballotFirstName": "Rivah",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 19,
        "ballotLastName": "O'SULLIVAN",
        "ballotFirstName": "Conor",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 20,
        "ballotLastName": "MOXON",
        "ballotFirstName": "Tureiti Haromi",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 21,
        "ballotLastName": "TUAINE",
        "ballotFirstName": "Nancy Teurumanao",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 22,
        "ballotLastName": "BUTLER",
        "ballotFirstName": "Teresa",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 23,
        "ballotLastName": "CAMPBELL-KAMARIERA",
        "ballotFirstName": "Kyla",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 24,
        "ballotLastName": "HAREMA",
        "ballotFirstName": "Jacqui",
        "electorate": "Kelston"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 25,
        "ballotLastName": "MCLEOD",
        "ballotFirstName": "Te Waka Ruapounamu",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 26,
        "ballotLastName": "TAMIHERE",
        "ballotFirstName": "John",
        "electorate": "Te Atatū"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 27,
        "ballotLastName": "PUE",
        "ballotFirstName": "Elijah",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 28,
        "ballotLastName": "MCLEAN",
        "ballotFirstName": "Rangi",
        "electorate": "Manurewa"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 29,
        "ballotLastName": "SYDNEY",
        "ballotFirstName": "Mikaere Taingahue",
        "electorate": "Tauranga"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 30,
        "ballotLastName": "PARKER",
        "ballotFirstName": "Awatea",
        "electorate": "Hamilton East"
    },
    {
        "party": "Te Pāti Māori",
        "listNo": 31,
        "ballotLastName": "WAIKARE",
        "ballotFirstName": "Te Whakapono",
        "electorate": "Wairarapa"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 1,
        "ballotLastName": "MANJI",
        "ballotFirstName": "Raf",
        "electorate": "Ilam"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 2,
        "ballotLastName": "ALBERT",
        "ballotFirstName": "Natalia",
        "electorate": "Wellington Central"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 3,
        "ballotLastName": "PETERS",
        "ballotFirstName": "Ben",
        "electorate": "Dunedin"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 4,
        "ballotLastName": "SU",
        "ballotFirstName": "Nina",
        "electorate": "Epsom"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 5,
        "ballotLastName": "NAVOT",
        "ballotFirstName": "Shai",
        "electorate": "Upper Harbour"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 6,
        "ballotLastName": "HAMMOND",
        "ballotFirstName": "Jessica",
        "electorate": "Ōhāriu"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 7,
        "ballotLastName": "SWORDS",
        "ballotFirstName": "Ciara",
        "electorate": "Mt Albert"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 8,
        "ballotLastName": "SYCAMORE",
        "ballotFirstName": "Damian",
        "electorate": "Auckland Central"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 9,
        "ballotLastName": "POCOCK",
        "ballotFirstName": "Naomi",
        "electorate": "Hamilton West"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 10,
        "ballotLastName": "GRAY",
        "ballotFirstName": "Abe",
        "electorate": "North Shore"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 11,
        "ballotLastName": "WYLIE-VAN EERD",
        "ballotFirstName": "Ben",
        "electorate": "Hutt South"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 12,
        "ballotLastName": "CORKIN",
        "ballotFirstName": "Alex",
        "electorate": "Hamilton East"
    },
    {
        "party": "The Opportunities Party (TOP)",
        "listNo": 13,
        "ballotLastName": "OWEN",
        "ballotFirstName": "Megan",
        "electorate": "Waikato"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 1,
        "ballotLastName": "OVENS",
        "ballotFirstName": "Jill",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 2,
        "ballotLastName": "DEL LA VARIS",
        "ballotFirstName": "Chimene",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 3,
        "ballotLastName": "GUILLILAND",
        "ballotFirstName": "Karen",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 4,
        "ballotLastName": "FORNUSEK",
        "ballotFirstName": "Marnie",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 5,
        "ballotLastName": "HYMAN",
        "ballotFirstName": "Prue",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 6,
        "ballotLastName": "ORMSBY",
        "ballotFirstName": "Catherine",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 7,
        "ballotLastName": "ROSE",
        "ballotFirstName": "Linde",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 8,
        "ballotLastName": "HOSKINS",
        "ballotFirstName": "Sue Maree",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 9,
        "ballotLastName": "MANN",
        "ballotFirstName": "Catherine",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 10,
        "ballotLastName": "CLARK",
        "ballotFirstName": "MacKenzie",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 11,
        "ballotLastName": "LAUDERDALE",
        "ballotFirstName": "Kathleen",
        "electorate": "Non-electorate candidate"
    },
    {
        "party": "Women’s Rights Party",
        "listNo": 12,
        "ballotLastName": "OWEN-JONES",
        "ballotFirstName": "Adrienne",
        "electorate": "Non-electorate candidate"
    }
];