import { Container, Divider } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AppStateProps } from "../../types/AppState";
import HighchartsGraph from "../../components/HighchartsGraph";
import { getPartyColourHex, parties } from "../../live-election/election-data/party";
import { PartyPageOverviewTable } from "./PartyOverviewTable";

export const PartyPageOverview: FC<AppStateProps> = (props: AppStateProps) => {

    const { appState } = props;

    const [graphOptions, setGraphOptions] = useState<Highcharts.Options>({});

    useEffect(() => {
        const liveResults = appState.liveResults?.electorateResults || [];


        const seriesData = parties.map((partyName) => {

            let totalVotes = 0;

            // for each party, get the total count of votes
            liveResults.forEach((electorateResult) => {
                electorateResult.partyVotes.forEach((partyResult) => {
                    if(partyResult.partyName === partyName) {
                        totalVotes += partyResult.votes;
                    }
                });
            });

            return {
                name: partyName,
                type: 'column',
                data: [totalVotes],
                color: getPartyColourHex(partyName),
            }
        });

        seriesData.sort((a, b) => b.data[0] - a.data[0]);

        // trim the data to top 8
        const top8 = seriesData.slice(0, 8);
        const other = seriesData.slice(8);
        const otherTotal = other.reduce((acc, curr) => acc + curr.data[0], 0);

        const otherData = {
            name: 'Others',
            type: 'column',
            data: [otherTotal],
            color: undefined
        }

        const trimmedData = [...top8, otherData];

        const options = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Total Party Votes',
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                // pointFormat: 'Total Percentage: <b>{point.y:.2f}%</b>'
                pointFormat: 'Total Votes: <b>{point.y:.0f}</b>'
            },
            yAxis: {
                min: 0,
                title: {
                    // text: '% of Votes'
                    text: 'Total # Votes'
                },
            },
            series: [{
                name: 'Votes',
                colors: trimmedData.map((data) => data.color),
                colorByPoint: true,
                groupPadding: 0,
                data: trimmedData.map((data) => {
                    return [data.name, data.data[0]]
                })
            }]
        };

        //@ts-ignore
        setGraphOptions(options);

        }, [appState]);


    return (
        <>
            <Container>
                <HighchartsGraph options={graphOptions} />
            </Container>

            <Divider sx={{padding: '5px', paddingBottom: '5px'}} />

            <Container sx={{padding: '2px'}}>
                <PartyPageOverviewTable appState={appState} />
            </Container>
        </>
    )
}
