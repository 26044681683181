import { FC, useState } from "react";
import { AppStateProps } from "../../types/AppState";
import { Container } from "@mui/system";
import { Autocomplete, TextField } from "@mui/material";
import { Electorate, electorates } from "../../live-election/election-data/electorate";
import { ElectorateDetailsComponent } from "../../components/ElectorateDetails";



export const ElectorateDetailsPage: FC<AppStateProps> = (props: AppStateProps) => {

    const { appState } = props;

    const [selectedElectorate, setSelectedElectorate] = useState<Electorate | null>(null);

    return (
        <>

            <Container>
                <Autocomplete
                    disablePortal
                    id="combo-box-electorate-details-selector"
                    options={electorates}
                    renderInput={(params) => <TextField {...params} label="Select Electorate" />}
                    onChange={(event, newValue) => { setSelectedElectorate(newValue); }}
                />

            </Container>

            {selectedElectorate && (

            <Container>
                <ElectorateDetailsComponent appState={appState} electorate={selectedElectorate}></ElectorateDetailsComponent>
            </Container>
            )}

        </>
    )
}
