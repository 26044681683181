import { Electorate } from "./electorate";
import { Party } from "./party";

export type Candidate = {
    name: string;
    electorate: Electorate;
    party: Party;
}

export const candidates : Candidate[] = [
    {
        "name": "COKER, Christopher",
        "electorate": "Auckland Central",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "DAVIE, Paul",
        "electorate": "Auckland Central",
        "party": "Rock the Vote NZ"
    },
    {
        "name": "KANE, Madeleine",
        "electorate": "Auckland Central",
        "party": "Animal Justice Party"
    },
    {
        "name": "LIU, Andi Shen",
        "electorate": "Auckland Central",
        "party": "Independent"
    },
    {
        "name": "MURALIDHAR, Mahesh",
        "electorate": "Auckland Central",
        "party": "National Party"
    },
    {
        "name": "POOLE, Felix",
        "electorate": "Auckland Central",
        "party": "ACT New Zealand"
    },
    {
        "name": "SIMS, Oscar",
        "electorate": "Auckland Central",
        "party": "Labour Party"
    },
    {
        "name": "SLOCUM, Guy Dennis",
        "electorate": "Auckland Central",
        "party": "New Nation Party"
    },
    {
        "name": "SWARBRICK, Chlöe",
        "electorate": "Auckland Central",
        "party": "Green Party"
    },
    {
        "name": "SYCAMORE, Damian",
        "electorate": "Auckland Central",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "ALLINGTON, Doug",
        "electorate": "Banks Peninsula",
        "party": "DemocracyNZ"
    },
    {
        "name": "JEET, Paran",
        "electorate": "Banks Peninsula",
        "party": "Animal Justice Party"
    },
    {
        "name": "KERSLAKE, Lindsay",
        "electorate": "Banks Peninsula",
        "party": "New Zealand First Party"
    },
    {
        "name": "McLELLAN, Tracey",
        "electorate": "Banks Peninsula",
        "party": "Labour Party"
    },
    {
        "name": "MEAD, Lisa Marie",
        "electorate": "Banks Peninsula",
        "party": "NewZeal"
    },
    {
        "name": "PHAM, Lan",
        "electorate": "Banks Peninsula",
        "party": "Green Party"
    },
    {
        "name": "TRASK, Laura",
        "electorate": "Banks Peninsula",
        "party": "ACT New Zealand"
    },
    {
        "name": "WAKEMAN, Peter Keith",
        "electorate": "Banks Peninsula",
        "party": "Independent"
    },
    {
        "name": "WEENINK, Vanessa",
        "electorate": "Banks Peninsula",
        "party": "National Party"
    },
    {
        "name": "GILLESPIE, Wendy",
        "electorate": "Bay of Plenty",
        "party": "Leighton Baker Party"
    },
    {
        "name": "GRATTAN, Caitlin",
        "electorate": "Bay of Plenty",
        "party": "Animal Justice Party"
    },
    {
        "name": "LUXTON, Cameron",
        "electorate": "Bay of Plenty",
        "party": "ACT New Zealand"
    },
    {
        "name": "MACMILLAN, Matthew",
        "electorate": "Bay of Plenty",
        "party": "Green Party"
    },
    {
        "name": "MURFITT, Kirsten",
        "electorate": "Bay of Plenty",
        "party": "New Zealand First Party"
    },
    {
        "name": "RUTHERFORD, Tom",
        "electorate": "Bay of Plenty",
        "party": "National Party"
    },
    {
        "name": "TAIKATO, Pare",
        "electorate": "Bay of Plenty",
        "party": "Labour Party"
    },
    {
        "name": "WAHED, Taupo",
        "electorate": "Bay of Plenty",
        "party": "Independent"
    },
    {
        "name": "ARMSTRONG, John",
        "electorate": "Botany",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BURNS, Bo",
        "electorate": "Botany",
        "party": "ACT New Zealand"
    },
    {
        "name": "DE BOER, Dieuwe",
        "electorate": "Botany",
        "party": "New Conservatives"
    },
    {
        "name": "LUXON, Christopher",
        "electorate": "Botany",
        "party": "National Party"
    },
    {
        "name": "McNEIL, Robert",
        "electorate": "Botany",
        "party": "Animal Justice Party"
    },
    {
        "name": "SINGH, Kharag",
        "electorate": "Botany",
        "party": "Labour Party"
    },
    {
        "name": "ARNEIL, Mark",
        "electorate": "Christchurch Central",
        "party": "New Zealand First Party"
    },
    {
        "name": "BRITNELL, Michael",
        "electorate": "Christchurch Central",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "CARTER, Kahurangi",
        "electorate": "Christchurch Central",
        "party": "Green Party"
    },
    {
        "name": "FISKEN, Matthew Hunter",
        "electorate": "Christchurch Central",
        "party": "ACT New Zealand"
    },
    {
        "name": "JACKSON, Sarah",
        "electorate": "Christchurch Central",
        "party": "Animal Justice Party"
    },
    {
        "name": "STEPHENS, Dale Aotea",
        "electorate": "Christchurch Central",
        "party": "National Party"
    },
    {
        "name": "WEBB, Duncan",
        "electorate": "Christchurch Central",
        "party": "Labour Party"
    },
    {
        "name": "AHMED, Sahra",
        "electorate": "Christchurch East",
        "party": "Green Party"
    },
    {
        "name": "DAVIDSON, Reuben",
        "electorate": "Christchurch East",
        "party": "Labour Party"
    },
    {
        "name": "HOUGHTON, Helen",
        "electorate": "Christchurch East",
        "party": "New Conservatives"
    },
    {
        "name": "LAMBERT, Paula",
        "electorate": "Christchurch East",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "PARK, Sam",
        "electorate": "Christchurch East",
        "party": "Independent"
    },
    {
        "name": "SEVERIN, Toni",
        "electorate": "Christchurch East",
        "party": "ACT New Zealand"
    },
    {
        "name": "STOCK, Matt",
        "electorate": "Christchurch East",
        "party": "National Party"
    },
    {
        "name": "WERETA, Danette",
        "electorate": "Christchurch East",
        "party": "Animal Justice Party"
    },
    {
        "name": "WIREMU, Shane",
        "electorate": "Christchurch East",
        "party": "New Zealand First Party"
    },
    {
        "name": "ANSELL, Caleb",
        "electorate": "Coromandel",
        "party": "New Zealand First Party"
    },
    {
        "name": "COBB, Ray",
        "electorate": "Coromandel",
        "party": "New Zealand Loyal"
    },
    {
        "name": "GREALEY, Pamela",
        "electorate": "Coromandel",
        "party": "Green Party"
    },
    {
        "name": "RILEY, Beryl",
        "electorate": "Coromandel",
        "party": "Labour Party"
    },
    {
        "name": "SIMPSON, Scott",
        "electorate": "Coromandel",
        "party": "National Party"
    },
    {
        "name": "TEPOU, Sarai",
        "electorate": "Coromandel",
        "party": "NZ Outdoors & Freedom Party"
    },
    {
        "name": "VERBURG, Joanna",
        "electorate": "Coromandel",
        "party": "ACT New Zealand"
    },
    {
        "name": "BROOKING, Rachel",
        "electorate": "Dunedin",
        "party": "Labour Party"
    },
    {
        "name": "ELDER, Cyndee",
        "electorate": "Dunedin",
        "party": "New Conservatives"
    },
    {
        "name": "HERNANDEZ, Francisco",
        "electorate": "Dunedin",
        "party": "Green Party"
    },
    {
        "name": "LANGEVELD, Keegan",
        "electorate": "Dunedin",
        "party": "New Zealand First Party"
    },
    {
        "name": "LAWTON, Steve",
        "electorate": "Dunedin",
        "party": "New Zealand Loyal"
    },
    {
        "name": "McDERMOTT, Adrian",
        "electorate": "Dunedin",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "NEWMAN, Tim",
        "electorate": "Dunedin",
        "party": "ACT New Zealand"
    },
    {
        "name": "O'MALLEY, Jim",
        "electorate": "Dunedin",
        "party": "Independent"
    },
    {
        "name": "PETERS, Ben",
        "electorate": "Dunedin",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "TAYLOR, Pamela",
        "electorate": "Dunedin",
        "party": "Independent"
    },
    {
        "name": "WOODHOUSE, Michael",
        "electorate": "Dunedin",
        "party": "National Party"
    },
    {
        "name": "CARACAS, Monaco",
        "electorate": "East Coast",
        "party": "Independent"
    },
    {
        "name": "COFFEY, Tamati",
        "electorate": "East Coast",
        "party": "Labour Party"
    },
    {
        "name": "DICKSON, Gordon John",
        "electorate": "East Coast",
        "party": "Independent"
    },
    {
        "name": "FLAVELL, Fallyn",
        "electorate": "East Coast",
        "party": "Te Pāti Māori"
    },
    {
        "name": "HOWE, Michael",
        "electorate": "East Coast",
        "party": "ACT New Zealand"
    },
    {
        "name": "KIRKPATRICK, Dana",
        "electorate": "East Coast",
        "party": "National Party"
    },
    {
        "name": "PACKER, Leighton",
        "electorate": "East Coast",
        "party": "Vision New Zealand"
    },
    {
        "name": "RICHARDS, Don",
        "electorate": "East Coast",
        "party": "Independent"
    },
    {
        "name": "ROBINSON, Chris",
        "electorate": "East Coast",
        "party": "DemocracyNZ"
    },
    {
        "name": "SINCLAIR, Craig",
        "electorate": "East Coast",
        "party": "New Zealand First Party"
    },
    {
        "name": "WALKER, Jordan",
        "electorate": "East Coast",
        "party": "Green Party"
    },
    {
        "name": "ADAMS, Paul",
        "electorate": "East Coast Bays",
        "party": "NewZeal"
    },
    {
        "name": "CHEN, Naisi",
        "electorate": "East Coast Bays",
        "party": "Labour Party"
    },
    {
        "name": "DYET, Bill",
        "electorate": "East Coast Bays",
        "party": "New Zealand Loyal"
    },
    {
        "name": "McCOOK, Michael",
        "electorate": "East Coast Bays",
        "party": "ACT New Zealand"
    },
    {
        "name": "STANFORD, Erica",
        "electorate": "East Coast Bays",
        "party": "National Party"
    },
    {
        "name": "BELICH, Camilla",
        "electorate": "Epsom",
        "party": "Labour Party"
    },
    {
        "name": "GOLDSMITH, Paul",
        "electorate": "Epsom",
        "party": "National Party"
    },
    {
        "name": "ROTHERAY, Anna",
        "electorate": "Epsom",
        "party": "New Zealand Loyal"
    },
    {
        "name": "SEYMOUR, David Breen",
        "electorate": "Epsom",
        "party": "ACT New Zealand"
    },
    {
        "name": "SU, Nina",
        "electorate": "Epsom",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "UNKOVICH, Tanya Marie",
        "electorate": "Epsom",
        "party": "New Zealand First Party"
    },
    {
        "name": "XU-NAN, Lawrence Xi",
        "electorate": "Epsom",
        "party": "Green Party"
    },
    {
        "name": "BAN, Tanya",
        "electorate": "Hamilton East",
        "party": "New Zealand Loyal"
    },
    {
        "name": "CARRINGTON, Lily",
        "electorate": "Hamilton East",
        "party": "Animal Justice Party"
    },
    {
        "name": "CORKIN, Alex",
        "electorate": "Hamilton East",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "COUPER, Nathan Lee",
        "electorate": "Hamilton East",
        "party": "New World Order McCann Party"
    },
    {
        "name": "DANSEY, Georgie",
        "electorate": "Hamilton East",
        "party": "Labour Party"
    },
    {
        "name": "GIELEN, Jacobus",
        "electorate": "Hamilton East",
        "party": "Republic of New Zealand Party"
    },
    {
        "name": "HAMILTON, Ryan",
        "electorate": "Hamilton East",
        "party": "National Party"
    },
    {
        "name": "KNAAP, Russelle",
        "electorate": "Hamilton East",
        "party": "New Zealand First Party"
    },
    {
        "name": "PARKER, Awatea",
        "electorate": "Hamilton East",
        "party": "Te Pāti Māori"
    },
    {
        "name": "PARMAR, Himanshu",
        "electorate": "Hamilton East",
        "party": "ACT New Zealand"
    },
    {
        "name": "DOYLE, Benjamin",
        "electorate": "Hamilton West",
        "party": "Green Party"
    },
    {
        "name": "DU PLOOY, Rudi",
        "electorate": "Hamilton West",
        "party": "Vision New Zealand"
    },
    {
        "name": "POCOCK, Naomi",
        "electorate": "Hamilton West",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "POTAKA, Tama William",
        "electorate": "Hamilton West",
        "party": "National Party"
    },
    {
        "name": "STEVENSON, Susan",
        "electorate": "Hamilton West",
        "party": "ACT New Zealand"
    },
    {
        "name": "STONE, Kevin",
        "electorate": "Hamilton West",
        "party": "New Zealand First Party"
    },
    {
        "name": "WILLIAMSON, Myra",
        "electorate": "Hamilton West",
        "party": "Labour Party"
    },
    {
        "name": "WILSON, Melanie",
        "electorate": "Hamilton West",
        "party": "Animal Justice Party"
    },
    {
        "name": "ANDERSEN, Ginny",
        "electorate": "Hutt South",
        "party": "Labour Party"
    },
    {
        "name": "BISHOP, Chris",
        "electorate": "Hutt South",
        "party": "National Party"
    },
    {
        "name": "BLAKE, Jordan",
        "electorate": "Hutt South",
        "party": "New Zealand Loyal"
    },
    {
        "name": "DONOGHUE, Lee",
        "electorate": "Hutt South",
        "party": "New Zealand First Party"
    },
    {
        "name": "JENNINGS, Neelu",
        "electorate": "Hutt South",
        "party": "Green Party"
    },
    {
        "name": "PARKINS, Andy",
        "electorate": "Hutt South",
        "party": "ACT New Zealand"
    },
    {
        "name": "RANGITUTIA, Max",
        "electorate": "Hutt South",
        "party": "Vision New Zealand"
    },
    {
        "name": "WYLIE-VAN EERD, Ben",
        "electorate": "Hutt South",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "BRITNELL, Irinka",
        "electorate": "Ilam",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "CAMPBELL, Hamish",
        "electorate": "Ilam",
        "party": "National Party"
    },
    {
        "name": "DAVIDSON, Mike",
        "electorate": "Ilam",
        "party": "Green Party"
    },
    {
        "name": "MANJI, Raf",
        "electorate": "Ilam",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "O'BRIEN, Chris",
        "electorate": "Ilam",
        "party": "New Conservatives"
    },
    {
        "name": "O'CONNELL, Juanita",
        "electorate": "Ilam",
        "party": "DemocracyNZ"
    },
    {
        "name": "PALLETT, Sarah",
        "electorate": "Ilam",
        "party": "Labour Party"
    },
    {
        "name": "CRAIG, Liz",
        "electorate": "Invercargill",
        "party": "Labour Party"
    },
    {
        "name": "DONALDSON, Scott Ian",
        "electorate": "Invercargill",
        "party": "ACT New Zealand"
    },
    {
        "name": "KOWALEWSKI, David",
        "electorate": "Invercargill",
        "party": "New Zealand Loyal"
    },
    {
        "name": "O'CONNELL, Kevin Patrick",
        "electorate": "Invercargill",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "SIMMONDS, Penny",
        "electorate": "Invercargill",
        "party": "National Party"
    },
    {
        "name": "TERRILL, Judith",
        "electorate": "Invercargill",
        "party": "Vision New Zealand"
    },
    {
        "name": "ARBUCKLE, Jamie Arthur",
        "electorate": "Kaikōura",
        "party": "New Zealand First Party"
    },
    {
        "name": "BROWN, Shaun",
        "electorate": "Kaikōura",
        "party": "Money Free Party NZ"
    },
    {
        "name": "CAMPBELL, Sandra",
        "electorate": "Kaikōura",
        "party": "DemocracyNZ"
    },
    {
        "name": "COLELLO, Natalie",
        "electorate": "Kaikōura",
        "party": "New Zealand Loyal"
    },
    {
        "name": "DEWHIRST, Emma",
        "electorate": "Kaikōura",
        "party": "Labour Party"
    },
    {
        "name": "GREENSLADE, David",
        "electorate": "Kaikōura",
        "party": "New Conservatives"
    },
    {
        "name": "GRIFFITHS, Keith",
        "electorate": "Kaikōura",
        "party": "ACT New Zealand"
    },
    {
        "name": "HOWARD, Ted",
        "electorate": "Kaikōura",
        "party": "Independent"
    },
    {
        "name": "McCASKEY, John",
        "electorate": "Kaikōura",
        "party": "Independent"
    },
    {
        "name": "McCUBBIN, Richard",
        "electorate": "Kaikōura",
        "party": "Green Party"
    },
    {
        "name": "SMITH, Stuart",
        "electorate": "Kaikōura",
        "party": "National Party"
    },
    {
        "name": "BAILEY, Brent",
        "electorate": "Kaipara ki Mahurangi",
        "party": "ACT New Zealand"
    },
    {
        "name": "BREWER, Sarah",
        "electorate": "Kaipara ki Mahurangi",
        "party": "DemocracyNZ"
    },
    {
        "name": "BROWN, Zephyr",
        "electorate": "Kaipara ki Mahurangi",
        "party": "Green Party"
    },
    {
        "name": "MARCROFT, Jenny",
        "electorate": "Kaipara ki Mahurangi",
        "party": "New Zealand First Party"
    },
    {
        "name": "PENK, Chris",
        "electorate": "Kaipara ki Mahurangi",
        "party": "National Party"
    },
    {
        "name": "WISHART, Guy",
        "electorate": "Kaipara ki Mahurangi",
        "party": "Labour Party"
    },
    {
        "name": "CURRAN, Jacob",
        "electorate": "Kelston",
        "party": "ACT New Zealand"
    },
    {
        "name": "DEGIA-PALA, Anne",
        "electorate": "Kelston",
        "party": "New Zealand First Party"
    },
    {
        "name": "GHAHRAMAN, Golriz",
        "electorate": "Kelston",
        "party": "Green Party"
    },
    {
        "name": "HAREMA, Jacqui",
        "electorate": "Kelston",
        "party": "Te Pāti Māori"
    },
    {
        "name": "HOOD, Alister",
        "electorate": "Kelston",
        "party": "New Conservatives"
    },
    {
        "name": "SCHAUMKEL, Ruby",
        "electorate": "Kelston",
        "party": "National Party"
    },
    {
        "name": "SEPULONI, Carmel",
        "electorate": "Kelston",
        "party": "Labour Party"
    },
    {
        "name": "TILDSLEY, Leao",
        "electorate": "Kelston",
        "party": "DemocracyNZ"
    },
    {
        "name": "BHARGAVA, Kush",
        "electorate": "Mana",
        "party": "Protect and Prosper New Zealand"
    },
    {
        "name": "BROWN, Lily",
        "electorate": "Mana",
        "party": "ACT New Zealand"
    },
    {
        "name": "DAO-MCLAY, Gina",
        "electorate": "Mana",
        "party": "Green Party"
    },
    {
        "name": "EDMONDS, Barbara",
        "electorate": "Mana",
        "party": "Labour Party"
    },
    {
        "name": "FOSTER, Andy",
        "electorate": "Mana",
        "party": "New Zealand First Party"
    },
    {
        "name": "GOODE, Richard",
        "electorate": "Mana",
        "party": "NAP"
    },
    {
        "name": "HUGHES, Frances",
        "electorate": "Mana",
        "party": "National Party"
    },
    {
        "name": "TEMPERTON, Lisa",
        "electorate": "Mana",
        "party": "New Zealand Loyal"
    },
    {
        "name": "ALAILIMA, Fuiavailili",
        "electorate": "Māngere",
        "party": "Vision New Zealand"
    },
    {
        "name": "BOURKE, Rosemary",
        "electorate": "Māngere",
        "party": "National Party"
    },
    {
        "name": "JOSEPH, Pothen",
        "electorate": "Māngere",
        "party": "ACT New Zealand"
    },
    {
        "name": "PAO STANLEY, Brooke",
        "electorate": "Māngere",
        "party": "Independent"
    },
    {
        "name": "PETERS, Hilda",
        "electorate": "Māngere",
        "party": "Te Pāti Māori"
    },
    {
        "name": "SOSENE, Lemauga Lydia",
        "electorate": "Māngere",
        "party": "Labour Party"
    },
    {
        "name": "SYKES, Peter",
        "electorate": "Māngere",
        "party": "Green Party"
    },
    {
        "name": "KILARI, Siva",
        "electorate": "Manurewa",
        "party": "National Party"
    },
    {
        "name": "MCLEAN, Rangi",
        "electorate": "Manurewa",
        "party": "Te Pāti Māori"
    },
    {
        "name": "WARREN, Caine",
        "electorate": "Manurewa",
        "party": "Vision New Zealand"
    },
    {
        "name": "WILLIAMS, Arena",
        "electorate": "Manurewa",
        "party": "Labour Party"
    },
    {
        "name": "BRIDGE, Phillip",
        "electorate": "Maungakiekie",
        "party": "Independent"
    },
    {
        "name": "CHUAH, Eric",
        "electorate": "Maungakiekie",
        "party": "Rock the Vote NZ"
    },
    {
        "name": "FLEMING, Greg",
        "electorate": "Maungakiekie",
        "party": "National Party"
    },
    {
        "name": "HOGG, Andrew",
        "electorate": "Maungakiekie",
        "party": "New Zealand First Party"
    },
    {
        "name": "ONISHCHENKO, Margo",
        "electorate": "Maungakiekie",
        "party": "ACT New Zealand"
    },
    {
        "name": "RADHAKRISHNAN, Priyanca",
        "electorate": "Maungakiekie",
        "party": "Labour Party"
    },
    {
        "name": "SAMANT, Sapna",
        "electorate": "Maungakiekie",
        "party": "Green Party"
    },
    {
        "name": "LEE, Melissa",
        "electorate": "Mt Albert",
        "party": "National Party"
    },
    {
        "name": "MENENDEZ MARCH, Ricardo",
        "electorate": "Mt Albert",
        "party": "Green Party"
    },
    {
        "name": "MURPHY, Ollie",
        "electorate": "Mt Albert",
        "party": "ACT New Zealand"
    },
    {
        "name": "NAUFAHU, Tesi",
        "electorate": "Mt Albert",
        "party": "Independent"
    },
    {
        "name": "SWORDS, Ciara",
        "electorate": "Mt Albert",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "VAN DEN HEUVEL, Anthony Joseph J",
        "electorate": "Mt Albert",
        "party": "Human Rights Party"
    },
    {
        "name": "WHITE, Helen",
        "electorate": "Mt Albert",
        "party": "Labour Party"
    },
    {
        "name": "CHEUNG, Carlos",
        "electorate": "Mt Roskill",
        "party": "National Party"
    },
    {
        "name": "CHOPRA, Rahul",
        "electorate": "Mt Roskill",
        "party": "ACT New Zealand"
    },
    {
        "name": "SANIS WALGAMPOLA, Suveen",
        "electorate": "Mt Roskill",
        "party": "Green Party"
    },
    {
        "name": "WOOD, Michael Philip",
        "electorate": "Mt Roskill",
        "party": "Labour Party"
    },
    {
        "name": "DICKEY, Julienne",
        "electorate": "Napier",
        "party": "Green Party"
    },
    {
        "name": "HUTCHINSON, Mark",
        "electorate": "Napier",
        "party": "Labour Party"
    },
    {
        "name": "LANGFORD, Martin",
        "electorate": "Napier",
        "party": "DemocracyNZ"
    },
    {
        "name": "MILEWSKI, Pawel",
        "electorate": "Napier",
        "party": "ACT New Zealand"
    },
    {
        "name": "NIMON, Katie",
        "electorate": "Napier",
        "party": "National Party"
    },
    {
        "name": "SMITH, John Clive",
        "electorate": "Napier",
        "party": "Independent"
    },
    {
        "name": "TURNBULL, Laurie",
        "electorate": "Napier",
        "party": "New Zealand First Party"
    },
    {
        "name": "BAILLIE, Chris",
        "electorate": "Nelson",
        "party": "ACT New Zealand"
    },
    {
        "name": "BOYACK, Rachel Elizabeth",
        "electorate": "Nelson",
        "party": "Labour Party"
    },
    {
        "name": "CAMERON, Blair",
        "electorate": "Nelson",
        "party": "National Party"
    },
    {
        "name": "DYER, Bruce",
        "electorate": "Nelson",
        "party": "Progressive Aotearoa New Zealand"
    },
    {
        "name": "HOBBS, Jace",
        "electorate": "Nelson",
        "party": "Green Party"
    },
    {
        "name": "VAUGHAN, Peter",
        "electorate": "Nelson",
        "party": "New Zealand Loyal"
    },
    {
        "name": "ABEL, Steve",
        "electorate": "New Lynn",
        "party": "Green Party"
    },
    {
        "name": "ALVAREZ DE LUGO, Juan",
        "electorate": "New Lynn",
        "party": "ACT New Zealand"
    },
    {
        "name": "GARCIA, Paulo",
        "electorate": "New Lynn",
        "party": "National Party"
    },
    {
        "name": "MANN, Phineas",
        "electorate": "New Lynn",
        "party": "Vision New Zealand"
    },
    {
        "name": "OLIVER, Steve",
        "electorate": "New Lynn",
        "party": "New Zealand Loyal"
    },
    {
        "name": "RUSSELL, Deborah",
        "electorate": "New Lynn",
        "party": "Labour Party"
    },
    {
        "name": "BENNETT, Glen",
        "electorate": "New Plymouth",
        "party": "Labour Party"
    },
    {
        "name": "MACLEOD, David",
        "electorate": "New Plymouth",
        "party": "National Party"
    },
    {
        "name": "McGECHAN, Bruce",
        "electorate": "New Plymouth",
        "party": "ACT New Zealand"
    },
    {
        "name": "ROBINSON, Greg",
        "electorate": "New Plymouth",
        "party": "New Nation Party"
    },
    {
        "name": "WATKINS, Hamish",
        "electorate": "New Plymouth",
        "party": "Animal Justice Party"
    },
    {
        "name": "WILLETTS, Warren",
        "electorate": "New Plymouth",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BASKETT, Pat",
        "electorate": "North Shore",
        "party": "Green Party"
    },
    {
        "name": "GRAY, Abe",
        "electorate": "North Shore",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "HAMPTON, George",
        "electorate": "North Shore",
        "party": "Labour Party"
    },
    {
        "name": "WATTS, Simon",
        "electorate": "North Shore",
        "party": "National Party"
    },
    {
        "name": "YALLOP, Anna",
        "electorate": "North Shore",
        "party": "ACT New Zealand"
    },
    {
        "name": "BIDOIS, Dan",
        "electorate": "Northcote",
        "party": "National Party"
    },
    {
        "name": "DONALDSON, Mark",
        "electorate": "Northcote",
        "party": "Vision New Zealand"
    },
    {
        "name": "FOLEY, Leo George",
        "electorate": "Northcote",
        "party": "ACT New Zealand"
    },
    {
        "name": "HALBERT, Shanan",
        "electorate": "Northcote",
        "party": "Labour Party"
    },
    {
        "name": "SHAW, Andrew",
        "electorate": "Northcote",
        "party": "Green Party"
    },
    {
        "name": "WARREN, Michelle",
        "electorate": "Northcote",
        "party": "New Zealand First Party"
    },
    {
        "name": "CAMERON, Mark",
        "electorate": "Northland",
        "party": "ACT New Zealand"
    },
    {
        "name": "FEYEN, Michael",
        "electorate": "Northland",
        "party": "New Zealand Loyal"
    },
    {
        "name": "FINLAYSON, Mike",
        "electorate": "Northland",
        "party": "Northland Party"
    },
    {
        "name": "JONES, Shane Geoffrey",
        "electorate": "Northland",
        "party": "New Zealand First Party"
    },
    {
        "name": "KING, Matt",
        "electorate": "Northland",
        "party": "DemocracyNZ"
    },
    {
        "name": "LYE, Jeffrey",
        "electorate": "Northland",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "McCALLUM, Grant",
        "electorate": "Northland",
        "party": "National Party"
    },
    {
        "name": "PENNEY, Reina Tuai",
        "electorate": "Northland",
        "party": "Green Party"
    },
    {
        "name": "PRIME, Willow-Jean",
        "electorate": "Northland",
        "party": "Labour Party"
    },
    {
        "name": "DAY, Paul",
        "electorate": "Ōhāriu",
        "party": "ACT New Zealand"
    },
    {
        "name": "HAMMOND, Jessica",
        "electorate": "Ōhāriu",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "JENKINS, Martin",
        "electorate": "Ōhāriu",
        "party": "Protect and Prosper New Zealand"
    },
    {
        "name": "LIM, Patrick",
        "electorate": "Ōhāriu",
        "party": "Vision New Zealand"
    },
    {
        "name": "O'CONNOR, Greg",
        "electorate": "Ōhāriu",
        "party": "Labour Party"
    },
    {
        "name": "RODGERS, Stephanie",
        "electorate": "Ōhāriu",
        "party": "Green Party"
    },
    {
        "name": "WILLIS, Nicola",
        "electorate": "Ōhāriu",
        "party": "National Party"
    },
    {
        "name": "COSTLEY, Tim",
        "electorate": "Ōtaki",
        "party": "National Party"
    },
    {
        "name": "MUHAMMAD, Ali",
        "electorate": "Ōtaki",
        "party": "Green Party"
    },
    {
        "name": "NGOBI, Terisa",
        "electorate": "Ōtaki",
        "party": "Labour Party"
    },
    {
        "name": "RUSH, Sean Edward",
        "electorate": "Ōtaki",
        "party": "ACT New Zealand"
    },
    {
        "name": "TEN-HAVE, Bryan",
        "electorate": "Ōtaki",
        "party": "New Zealand Loyal"
    },
    {
        "name": "WESSEX, Bob",
        "electorate": "Ōtaki",
        "party": "NAP"
    },
    {
        "name": "ALCOCK, John",
        "electorate": "Pakuranga",
        "party": "Rock the Vote NZ"
    },
    {
        "name": "BROWN, Simeon",
        "electorate": "Pakuranga",
        "party": "National Party"
    },
    {
        "name": "HANCOCK, Nicholas",
        "electorate": "Pakuranga",
        "party": "Animal Justice Party"
    },
    {
        "name": "HENRY, Nerissa",
        "electorate": "Pakuranga",
        "party": "Labour Party"
    },
    {
        "name": "PARMAR, Parmjeet",
        "electorate": "Pakuranga",
        "party": "ACT New Zealand"
    },
    {
        "name": "SCOTHERN, Phil",
        "electorate": "Pakuranga",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BANSAL, Ankit",
        "electorate": "Palmerston North",
        "party": "National Party"
    },
    {
        "name": "BEGG, Douglas",
        "electorate": "Palmerston North",
        "party": "Animal Justice Party"
    },
    {
        "name": "GRANT, Dean",
        "electorate": "Palmerston North",
        "party": "New Zealand Loyal"
    },
    {
        "name": "HARNETT, Mike",
        "electorate": "Palmerston North",
        "party": "ACT New Zealand"
    },
    {
        "name": "TUIONO, Teanau",
        "electorate": "Palmerston North",
        "party": "Green Party"
    },
    {
        "name": "UTIKERE, Tangi",
        "electorate": "Palmerston North",
        "party": "Labour Party"
    },
    {
        "name": "COLLINS, Fa'anānā Efeso",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "Green Party"
    },
    {
        "name": "MODKOVA, Antonia",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "ACT New Zealand"
    },
    {
        "name": "MOKARAKA, Karl",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "Vision New Zealand"
    },
    {
        "name": "RANDHAWA, Navtej",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "National Party"
    },
    {
        "name": "ROBB, James",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "Workers Now"
    },
    {
        "name": "SALESA, Jenny",
        "electorate": "Panmure-Ōtāhuhu",
        "party": "Labour Party"
    },
    {
        "name": "COLLINS, Judith",
        "electorate": "Papakura",
        "party": "National Party"
    },
    {
        "name": "HARVEY, Kathy",
        "electorate": "Papakura",
        "party": "Vision New Zealand"
    },
    {
        "name": "KANONGATA'A, Anahila",
        "electorate": "Papakura",
        "party": "Labour Party"
    },
    {
        "name": "KERR, Karin",
        "electorate": "Papakura",
        "party": "Independent"
    },
    {
        "name": "KOUKA, Donna",
        "electorate": "Papakura",
        "party": "New Zealand Loyal"
    },
    {
        "name": "McCORMICK, Mike",
        "electorate": "Papakura",
        "party": "ACT New Zealand"
    },
    {
        "name": "MONDS, Robert",
        "electorate": "Papakura",
        "party": "New Zealand First Party"
    },
    {
        "name": "BALLANTYNE, Robert",
        "electorate": "Rangitata",
        "party": "New Zealand First Party"
    },
    {
        "name": "CLARKSON, Michael",
        "electorate": "Rangitata",
        "party": "Rock the Vote NZ"
    },
    {
        "name": "GILCHRIST, Barbara",
        "electorate": "Rangitata",
        "party": "Green Party"
    },
    {
        "name": "LUXTON, Jo",
        "electorate": "Rangitata",
        "party": "Labour Party"
    },
    {
        "name": "MEAGER, James",
        "electorate": "Rangitata",
        "party": "National Party"
    },
    {
        "name": "SHEARER, Wayne",
        "electorate": "Rangitata",
        "party": "New Zealand Loyal"
    },
    {
        "name": "THOMAS, Karl",
        "electorate": "Rangitata",
        "party": "New Conservatives"
    },
    {
        "name": "Van AMERSFOORT, Dolf",
        "electorate": "Rangitata",
        "party": "New Nation Party"
    },
    {
        "name": "BUTT, Zulfiqar",
        "electorate": "Rangitīkei",
        "party": "Labour Party"
    },
    {
        "name": "HOGGARD, Andrew John",
        "electorate": "Rangitīkei",
        "party": "ACT New Zealand"
    },
    {
        "name": "LONG, Bernard",
        "electorate": "Rangitīkei",
        "party": "Green Party"
    },
    {
        "name": "REDMAYNE, Suze",
        "electorate": "Rangitīkei",
        "party": "National Party"
    },
    {
        "name": "VERMEULEN, Helma",
        "electorate": "Rangitīkei",
        "party": "New Zealand First Party"
    },
    {
        "name": "CHATTERTON, Emma",
        "electorate": "Remutaka",
        "party": "National Party"
    },
    {
        "name": "HIPKINS, Chris",
        "electorate": "Remutaka",
        "party": "Labour Party"
    },
    {
        "name": "HURLE, Michael",
        "electorate": "Remutaka",
        "party": "ACT New Zealand"
    },
    {
        "name": "NORTON, Chris",
        "electorate": "Remutaka",
        "party": "Green Party"
    },
    {
        "name": "PITIROI, Tony",
        "electorate": "Remutaka",
        "party": "NewZeal"
    },
    {
        "name": "ROBERTSON, Heker",
        "electorate": "Remutaka",
        "party": "Vision New Zealand"
    },
    {
        "name": "EMET, Atom",
        "electorate": "Rongotai",
        "party": "Animal Justice Party"
    },
    {
        "name": "FITZSIMONS, Fleur",
        "electorate": "Rongotai",
        "party": "Labour Party"
    },
    {
        "name": "GENTER, Julie Anne",
        "electorate": "Rongotai",
        "party": "Green Party"
    },
    {
        "name": "McDONALD, Don S",
        "electorate": "Rongotai",
        "party": "Independent"
    },
    {
        "name": "McKEE, Nicole",
        "electorate": "Rongotai",
        "party": "ACT New Zealand"
    },
    {
        "name": "MILLS, Geoff",
        "electorate": "Rongotai",
        "party": "New Zealand First Party"
    },
    {
        "name": "MUTHU, Karunā",
        "electorate": "Rongotai",
        "party": "National Party"
    },
    {
        "name": "ROA, Merania",
        "electorate": "Rongotai",
        "party": "Vision New Zealand"
    },
    {
        "name": "WELSH, Bruce",
        "electorate": "Rongotai",
        "party": "New Conservatives"
    },
    {
        "name": "BLACK-VERCOE, Kariana",
        "electorate": "Rotorua",
        "party": "NewZeal"
    },
    {
        "name": "McCLAY, Todd",
        "electorate": "Rotorua",
        "party": "National Party"
    },
    {
        "name": "NAERA, Jonn",
        "electorate": "Rotorua",
        "party": "Independent"
    },
    {
        "name": "RAUKAWA-TAIT, Merepeka",
        "electorate": "Rotorua",
        "party": "Te Pāti Māori"
    },
    {
        "name": "ROZEBOOM, Marten",
        "electorate": "Rotorua",
        "party": "ACT New Zealand"
    },
    {
        "name": "SANDFORD, Ben",
        "electorate": "Rotorua",
        "party": "Labour Party"
    },
    {
        "name": "COULTER, Abe",
        "electorate": "Selwyn",
        "party": "New Conservatives"
    },
    {
        "name": "COURTNEY, Logan",
        "electorate": "Selwyn",
        "party": "New Zealand Loyal"
    },
    {
        "name": "GRIGG, Nicola",
        "electorate": "Selwyn",
        "party": "National Party"
    },
    {
        "name": "HARVEY, Ben",
        "electorate": "Selwyn",
        "party": "ACT New Zealand"
    },
    {
        "name": "JONES, Luke",
        "electorate": "Selwyn",
        "party": "Labour Party"
    },
    {
        "name": "EVANS, Logan",
        "electorate": "Southland",
        "party": "New Zealand Loyal"
    },
    {
        "name": "GRUMBALL, Anntwinette",
        "electorate": "Southland",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "KENNEDY, Dave",
        "electorate": "Southland",
        "party": "Green Party"
    },
    {
        "name": "MACLEAN, Naomi",
        "electorate": "Southland",
        "party": "Vision New Zealand"
    },
    {
        "name": "McCALLUM, Simon",
        "electorate": "Southland",
        "party": "Labour Party"
    },
    {
        "name": "MOONEY, Joseph",
        "electorate": "Southland",
        "party": "National Party"
    },
    {
        "name": "STEPHENSON, Todd",
        "electorate": "Southland",
        "party": "ACT New Zealand"
    },
    {
        "name": "DONG, Changrong",
        "electorate": "Taieri",
        "party": "Independent"
    },
    {
        "name": "FRENCH, Matthew",
        "electorate": "Taieri",
        "party": "National Party"
    },
    {
        "name": "GARDNER, Mac",
        "electorate": "Taieri",
        "party": "Independent"
    },
    {
        "name": "LEARY, Ingrid",
        "electorate": "Taieri",
        "party": "Labour Party"
    },
    {
        "name": "MEFFAN, Burty",
        "electorate": "Taieri",
        "party": "ACT New Zealand"
    },
    {
        "name": "PATTERSON, Mark",
        "electorate": "Taieri",
        "party": "New Zealand First Party"
    },
    {
        "name": "ROBERTS, Fred",
        "electorate": "Taieri",
        "party": "New Zealand Loyal"
    },
    {
        "name": "WEBBER, David",
        "electorate": "Taieri",
        "party": "Independent"
    },
    {
        "name": "WILLIS, Scott",
        "electorate": "Taieri",
        "party": "Green Party"
    },
    {
        "name": "AH CHEE, Rae",
        "electorate": "Takanini",
        "party": "ACT New Zealand"
    },
    {
        "name": "LEAVASA, Anae Neru",
        "electorate": "Takanini",
        "party": "Labour Party"
    },
    {
        "name": "NAKHLE, Rima",
        "electorate": "Takanini",
        "party": "National Party"
    },
    {
        "name": "TULLOCH, Lynley Kim",
        "electorate": "Takanini",
        "party": "Animal Justice Party"
    },
    {
        "name": "O'CONNOR, Simon",
        "electorate": "Tāmaki",
        "party": "National Party"
    },
    {
        "name": "PERRATT, Anne",
        "electorate": "Tāmaki",
        "party": "New Zealand Loyal"
    },
    {
        "name": "SOLOMONE, Fesaitu",
        "electorate": "Tāmaki",
        "party": "Labour Party"
    },
    {
        "name": "VAN VELDEN, Brooke",
        "electorate": "Tāmaki",
        "party": "ACT New Zealand"
    },
    {
        "name": "BURR, Bill",
        "electorate": "Taranaki-King Country",
        "party": "Independent"
    },
    {
        "name": "KURIGER, Barbara",
        "electorate": "Taranaki-King Country",
        "party": "National Party"
    },
    {
        "name": "RAISON, Daryl",
        "electorate": "Taranaki-King Country",
        "party": "Vision New Zealand"
    },
    {
        "name": "ROBERTS, Angela",
        "electorate": "Taranaki-King Country",
        "party": "Labour Party"
    },
    {
        "name": "AL-BUSTANJI, Aladdin",
        "electorate": "Taupō",
        "party": "Labour Party"
    },
    {
        "name": "COZENS, Zane",
        "electorate": "Taupō",
        "party": "ACT New Zealand"
    },
    {
        "name": "O'CONNOR PATENA, George",
        "electorate": "Taupō",
        "party": "Green Party"
    },
    {
        "name": "PEHI, Tira",
        "electorate": "Taupō",
        "party": "New Zealand First Party"
    },
    {
        "name": "UPSTON, Louise",
        "electorate": "Taupō",
        "party": "National Party"
    },
    {
        "name": "WILSON, Gordon",
        "electorate": "Taupō",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BALDOCK, Larry",
        "electorate": "Tauranga",
        "party": "Independent"
    },
    {
        "name": "CROOKS, Justin",
        "electorate": "Tauranga",
        "party": "Green Party"
    },
    {
        "name": "HARVEY, Erika",
        "electorate": "Tauranga",
        "party": "New Zealand First Party"
    },
    {
        "name": "LANGRIDGE, Jono",
        "electorate": "Tauranga",
        "party": "New Conservatives"
    },
    {
        "name": "SAMUELS, Leon",
        "electorate": "Tauranga",
        "party": "Vision New Zealand"
    },
    {
        "name": "STOKMAN, Chelsea Jordan",
        "electorate": "Tauranga",
        "party": "Animal Justice Party"
    },
    {
        "name": "SYDNEY, Mikaere Taingahue",
        "electorate": "Tauranga",
        "party": "Te Pāti Māori"
    },
    {
        "name": "TINETTI, Jan",
        "electorate": "Tauranga",
        "party": "Labour Party"
    },
    {
        "name": "UFFINDELL, Sam",
        "electorate": "Tauranga",
        "party": "National Party"
    },
    {
        "name": "YOUNG, Christine",
        "electorate": "Tauranga",
        "party": "ACT New Zealand"
    },
    {
        "name": "COURT, Simon",
        "electorate": "Te Atatū",
        "party": "ACT New Zealand"
    },
    {
        "name": "NEUMANN, Zooey",
        "electorate": "Te Atatū",
        "party": "Green Party"
    },
    {
        "name": "NICHOLAS, Angee",
        "electorate": "Te Atatū",
        "party": "National Party"
    },
    {
        "name": "PHILLIPS, Melanie",
        "electorate": "Te Atatū",
        "party": "Independent"
    },
    {
        "name": "TAMIHERE, John",
        "electorate": "Te Atatū",
        "party": "Te Pāti Māori"
    },
    {
        "name": "TWYFORD, Phil",
        "electorate": "Te Atatū",
        "party": "Labour Party"
    },
    {
        "name": "DOUGLAS, Rob",
        "electorate": "Tukituki",
        "party": "ACT New Zealand"
    },
    {
        "name": "HULMAN, Rob",
        "electorate": "Tukituki",
        "party": "New Zealand Loyal"
    },
    {
        "name": "LORCK, Anna",
        "electorate": "Tukituki",
        "party": "Labour Party"
    },
    {
        "name": "MANNING, Romana (Marnz)",
        "electorate": "Tukituki",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "NGAHUKA, Michael",
        "electorate": "Tukituki",
        "party": "Vision New Zealand"
    },
    {
        "name": "PETROWSKI, Melanie Lorraine",
        "electorate": "Tukituki",
        "party": "Independent"
    },
    {
        "name": "PONK, Michael",
        "electorate": "Tukituki",
        "party": "Independent"
    },
    {
        "name": "RATCLIFFE, Nick",
        "electorate": "Tukituki",
        "party": "Green Party"
    },
    {
        "name": "TOSH, Allister David",
        "electorate": "Tukituki",
        "party": "Future Youth Party"
    },
    {
        "name": "WEDD, Catherine",
        "electorate": "Tukituki",
        "party": "National Party"
    },
    {
        "name": "BREWER, Cameron",
        "electorate": "Upper Harbour",
        "party": "National Party"
    },
    {
        "name": "CHHOUR, Karen",
        "electorate": "Upper Harbour",
        "party": "ACT New Zealand"
    },
    {
        "name": "CORBETT, Tony",
        "electorate": "Upper Harbour",
        "party": "New Zealand Sovereignty Party"
    },
    {
        "name": "DOYLE, Thea",
        "electorate": "Upper Harbour",
        "party": "Green Party"
    },
    {
        "name": "NAVOT, Shai",
        "electorate": "Upper Harbour",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "NEWMAN, Chris",
        "electorate": "Upper Harbour",
        "party": "New Zealand Loyal"
    },
    {
        "name": "SOARES, Bernadette",
        "electorate": "Upper Harbour",
        "party": "Vision New Zealand"
    },
    {
        "name": "WALTERS, Vanushi",
        "electorate": "Upper Harbour",
        "party": "Labour Party"
    },
    {
        "name": "WILSON, David Ashley",
        "electorate": "Upper Harbour",
        "party": "New Zealand First Party"
    },
    {
        "name": "DORNAN, Lois",
        "electorate": "Waikato",
        "party": "Vision New Zealand"
    },
    {
        "name": "HUSBAND, Stuart",
        "electorate": "Waikato",
        "party": "New Zealand First Party"
    },
    {
        "name": "OWEN, Megan",
        "electorate": "Waikato",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "TOKO, Jamie",
        "electorate": "Waikato",
        "party": "Labour Party"
    },
    {
        "name": "VAN DE MOLEN, Tim",
        "electorate": "Waikato",
        "party": "National Party"
    },
    {
        "name": "BAKER, Leighton",
        "electorate": "Waimakariri",
        "party": "Leighton Baker Party"
    },
    {
        "name": "CAMPBELL, Ross Eric",
        "electorate": "Waimakariri",
        "party": "ACT New Zealand"
    },
    {
        "name": "DOOCEY, Matt",
        "electorate": "Waimakariri",
        "party": "National Party"
    },
    {
        "name": "MALCOLM, Gordon",
        "electorate": "Waimakariri",
        "party": "DemocracyNZ"
    },
    {
        "name": "ROSEWARNE, Dan",
        "electorate": "Waimakariri",
        "party": "Labour Party"
    },
    {
        "name": "ARNOTT, Pete",
        "electorate": "Wairarapa",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BUTTERICK, Mike",
        "electorate": "Wairarapa",
        "party": "National Party"
    },
    {
        "name": "CASEY, Simon Francis",
        "electorate": "Wairarapa",
        "party": "ACT New Zealand"
    },
    {
        "name": "GARDNER, Jared Warren",
        "electorate": "Wairarapa",
        "party": "Independent"
    },
    {
        "name": "McANULTY, Kieran",
        "electorate": "Wairarapa",
        "party": "Labour Party"
    },
    {
        "name": "WADE-BROWN, Celia",
        "electorate": "Wairarapa",
        "party": "Green Party"
    },
    {
        "name": "WAIKARE, Te Whakapono",
        "electorate": "Wairarapa",
        "party": "Te Pāti Māori"
    },
    {
        "name": "ANDERSON, Miles",
        "electorate": "Waitaki",
        "party": "National Party"
    },
    {
        "name": "BAILEY, Ray",
        "electorate": "Waitaki",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BEAMISH, Sean",
        "electorate": "Waitaki",
        "party": "ACT New Zealand"
    },
    {
        "name": "HANSEN, Pleasance",
        "electorate": "Waitaki",
        "party": "Green Party"
    },
    {
        "name": "ODERING, Anthony",
        "electorate": "Waitaki",
        "party": "New Zealand First Party"
    },
    {
        "name": "REILLE, Ethan",
        "electorate": "Waitaki",
        "party": "Labour Party"
    },
    {
        "name": "SHAND, Daniel",
        "electorate": "Waitaki",
        "party": "Independent"
    },
    {
        "name": "SMALL, Roger",
        "electorate": "Waitaki",
        "party": "DemocracyNZ"
    },
    {
        "name": "ALBERT, Natalia",
        "electorate": "Wellington Central",
        "party": "The Opportunities Party (TOP)"
    },
    {
        "name": "APPLEBY, Michael George",
        "electorate": "Wellington Central",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "ARNEIL, Taylor",
        "electorate": "Wellington Central",
        "party": "New Zealand First Party"
    },
    {
        "name": "BAINS, Pete",
        "electorate": "Wellington Central",
        "party": "Independent"
    },
    {
        "name": "FRANKS, Don",
        "electorate": "Wellington Central",
        "party": "Workers Now"
    },
    {
        "name": "GEIRINGER, Karl Thomas",
        "electorate": "Wellington Central",
        "party": "Independent"
    },
    {
        "name": "GORDON, Christopher",
        "electorate": "Wellington Central",
        "party": "Animal Justice Party"
    },
    {
        "name": "LIM, Meg",
        "electorate": "Wellington Central",
        "party": "Vision New Zealand"
    },
    {
        "name": "OMER, Ibrahim",
        "electorate": "Wellington Central",
        "party": "Labour Party"
    },
    {
        "name": "PAUL, Tamatha",
        "electorate": "Wellington Central",
        "party": "Green Party"
    },
    {
        "name": "SHEERAN, Scott",
        "electorate": "Wellington Central",
        "party": "National Party"
    },
    {
        "name": "FARRELLY, Jackie",
        "electorate": "West Coast-Tasman",
        "party": "New Zealand First Party"
    },
    {
        "name": "GREY, Sue",
        "electorate": "West Coast-Tasman",
        "party": "NZ Outdoors & Freedom Party"
    },
    {
        "name": "LILLEY, Kelly",
        "electorate": "West Coast-Tasman",
        "party": "ACT New Zealand"
    },
    {
        "name": "MARINKOVICH, Sebastian",
        "electorate": "West Coast-Tasman",
        "party": "New Zealand Loyal"
    },
    {
        "name": "O'CONNOR, Damien",
        "electorate": "West Coast-Tasman",
        "party": "Labour Party"
    },
    {
        "name": "OSMASTON, Richard",
        "electorate": "West Coast-Tasman",
        "party": "Money Free Party NZ"
    },
    {
        "name": "PHELPS, Patrick Sean",
        "electorate": "West Coast-Tasman",
        "party": "Independent"
    },
    {
        "name": "PUGH, Maureen",
        "electorate": "West Coast-Tasman",
        "party": "National Party"
    },
    {
        "name": "RICHARDS, Steve",
        "electorate": "West Coast-Tasman",
        "party": "Green Party"
    },
    {
        "name": "ARNOLD, William",
        "electorate": "Whanganui",
        "party": "New Zealand First Party"
    },
    {
        "name": "BATES, Carl",
        "electorate": "Whanganui",
        "party": "National Party"
    },
    {
        "name": "DREDGE, Craig",
        "electorate": "Whanganui",
        "party": "ACT New Zealand"
    },
    {
        "name": "KYLE, Sandra",
        "electorate": "Whanganui",
        "party": "Animal Justice Party"
    },
    {
        "name": "LEWIS, Steph",
        "electorate": "Whanganui",
        "party": "Labour Party"
    },
    {
        "name": "SANSON, Marion",
        "electorate": "Whanganui",
        "party": "Green Party"
    },
    {
        "name": "ANGELO, Simon",
        "electorate": "Whangaparāoa",
        "party": "ACT New Zealand"
    },
    {
        "name": "LAYBOURN, Craig",
        "electorate": "Whangaparāoa",
        "party": "DemocracyNZ"
    },
    {
        "name": "MITCHELL, Mark",
        "electorate": "Whangaparāoa",
        "party": "National Party"
    },
    {
        "name": "MULLER PALLARÈS, Estefania",
        "electorate": "Whangaparāoa",
        "party": "Labour Party"
    },
    {
        "name": "NEWMAN, Lorraine",
        "electorate": "Whangaparāoa",
        "party": "Green Party"
    },
    {
        "name": "WILSON, Jeanette",
        "electorate": "Whangaparāoa",
        "party": "New Zealand Loyal"
    },
    {
        "name": "ANDREWS, Janita",
        "electorate": "Whangārei",
        "party": "New Zealand Loyal"
    },
    {
        "name": "BAZELEY, Rick",
        "electorate": "Whangārei",
        "party": "Green Party"
    },
    {
        "name": "BENNEY, Gavin",
        "electorate": "Whangārei",
        "party": "New Zealand First Party"
    },
    {
        "name": "BRETHERTON, Susy",
        "electorate": "Whangārei",
        "party": "ACT New Zealand"
    },
    {
        "name": "BURGESS, Diana",
        "electorate": "Whangārei",
        "party": "DemocracyNZ"
    },
    {
        "name": "de JONGE, Jeni",
        "electorate": "Whangārei",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "GREEN, Fiona",
        "electorate": "Whangārei",
        "party": "Independent"
    },
    {
        "name": "KAPA-KINGI, Eru",
        "electorate": "Whangārei",
        "party": "Te Pāti Māori"
    },
    {
        "name": "RETI, Shane",
        "electorate": "Whangārei",
        "party": "National Party"
    },
    {
        "name": "WARREN-CLARK, Angie",
        "electorate": "Whangārei",
        "party": "Labour Party"
    },
    {
        "name": "ANDERSON, Blair",
        "electorate": "Wigram",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "CULLIMORE, Debra Marie",
        "electorate": "Wigram",
        "party": "Leighton Baker Party"
    },
    {
        "name": "HANSEN, Tubby",
        "electorate": "Wigram",
        "party": "Economic Euthenics"
    },
    {
        "name": "LYNN, Ankita",
        "electorate": "Wigram",
        "party": "ACT New Zealand"
    },
    {
        "name": "McTAGUE, Geoff",
        "electorate": "Wigram",
        "party": "Independent"
    },
    {
        "name": "SUMMERFIELD, Tracy",
        "electorate": "Wigram",
        "party": "National Party"
    },
    {
        "name": "THOMSON, Wiremu",
        "electorate": "Wigram",
        "party": "Independent"
    },
    {
        "name": "VAN DUIVENBODEN, Christine",
        "electorate": "Wigram",
        "party": "New Zealand Loyal"
    },
    {
        "name": "WESLEY, Richard",
        "electorate": "Wigram",
        "party": "Green Party"
    },
    {
        "name": "WOODS, Megan",
        "electorate": "Wigram",
        "party": "Labour Party"
    },
    {
        "name": "MAHUTA, Nanaia",
        "electorate": "Hauraki-Waikato",
        "party": "Labour Party"
    },
    {
        "name": "MAIPI-CLARKE, Hana-Rawhiti",
        "electorate": "Hauraki-Waikato",
        "party": "Te Pāti Māori"
    },
    {
        "name": "POKERE-PHILLIPS, Donna",
        "electorate": "Hauraki-Waikato",
        "party": "NZ Outdoors & Freedom Party"
    },
    {
        "name": "TANGAERE-MANUEL, Cushla",
        "electorate": "Ikaroa-Rāwhiti",
        "party": "Labour Party"
    },
    {
        "name": "TUHAKARAINA, Ata",
        "electorate": "Ikaroa-Rāwhiti",
        "party": "Vision New Zealand"
    },
    {
        "name": "WHAITIRI, Meka",
        "electorate": "Ikaroa-Rāwhiti",
        "party": "Te Pāti Māori"
    },
    {
        "name": "HENARE, Peeni",
        "electorate": "Tāmaki Makaurau",
        "party": "Labour Party"
    },
    {
        "name": "KEMP, Takutai Tarsh",
        "electorate": "Tāmaki Makaurau",
        "party": "Te Pāti Māori"
    },
    {
        "name": "TAMAKI, Hannah",
        "electorate": "Tāmaki Makaurau",
        "party": "Vision New Zealand"
    },
    {
        "name": "TANA, Darleen",
        "electorate": "Tāmaki Makaurau",
        "party": "Green Party"
    },
    {
        "name": "TE HAU, Hinurewa",
        "electorate": "Tāmaki Makaurau",
        "party": "National Party"
    },
    {
        "name": "HIPANGO, Harete",
        "electorate": "Te Tai Hauāuru",
        "party": "National Party"
    },
    {
        "name": "NGAREWA-PACKER, Debbie",
        "electorate": "Te Tai Hauāuru",
        "party": "Te Pāti Māori"
    },
    {
        "name": "PEKE-MASON, Soraya",
        "electorate": "Te Tai Hauāuru",
        "party": "Labour Party"
    },
    {
        "name": "WINIATA, Paris",
        "electorate": "Te Tai Hauāuru",
        "party": "Vision New Zealand"
    },
    {
        "name": "DAVIS, Kelvin",
        "electorate": "Te Tai Tokerau",
        "party": "Labour Party"
    },
    {
        "name": "HERBERT, Maki",
        "electorate": "Te Tai Tokerau",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "KAPA-KINGI, Mariameno",
        "electorate": "Te Tai Tokerau",
        "party": "Te Pāti Māori"
    },
    {
        "name": "LYNDON, Hūhana",
        "electorate": "Te Tai Tokerau",
        "party": "Green Party"
    },
    {
        "name": "TOAUTU, Paturiri",
        "electorate": "Te Tai Tokerau",
        "party": "Independent"
    },
    {
        "name": "FERRIS, Tākuta",
        "electorate": "Te Tai Tonga",
        "party": "Te Pāti Māori"
    },
    {
        "name": "FUIMAONO PUHI, Geoffrey Karena",
        "electorate": "Te Tai Tonga",
        "party": "Independent"
    },
    {
        "name": "ROBIN, Rebecca",
        "electorate": "Te Tai Tonga",
        "party": "Aotearoa Legalise Cannabis Party"
    },
    {
        "name": "TIRIKATENE, Rino",
        "electorate": "Te Tai Tonga",
        "party": "Labour Party"
    },
    {
        "name": "BOYNTON, Toni",
        "electorate": "Waiariki",
        "party": "Labour Party"
    },
    {
        "name": "HUNIA, Charles Tiki",
        "electorate": "Waiariki",
        "party": "Vision New Zealand"
    },
    {
        "name": "WAITITI, Rawiri",
        "electorate": "Waiariki",
        "party": "Te Pāti Māori"
    }
];