import './App.css';
import { HomePage } from './pages/Home';
import { Container } from '@mui/material';

function App() {
  return (
    <Container maxWidth="lg">

      <HomePage />

    </Container>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Election Night 2023
    //     </p>
    //   </header>
    // </div>
  );
}

export default App;
