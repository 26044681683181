import { FC } from "react";
import { PartyPageOverview } from "./PartyOverview";
import { AppStateProps } from "../../types/AppState";

export const PartyTab: FC<AppStateProps> = (props: AppStateProps) => {

    const { appState } = props;

    return (
        <>
            <PartyPageOverview appState={appState}></PartyPageOverview>
        </>
    )
}
