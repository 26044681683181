
export const electorates = [
    'Auckland Central',
    'Banks Peninsula',
    'Bay of Plenty',
    'Botany',
    'Christchurch Central',
    'Christchurch East',
    'Coromandel',
    'Dunedin',
    'East Coast',
    'East Coast Bays',
    'Epsom',
    'Hamilton East',
    'Hamilton West',
    'Hauraki-Waikato',
    'Hutt South',
    'Ikaroa-Rāwhiti',
    'Ilam',
    'Invercargill',
    'Kaikōura',
    'Kaipara ki Mahurangi',
    'Kelston',
    'Mana',
    'Māngere',
    'Manurewa',
    'Maungakiekie',
    'Mt Albert',
    'Mt Roskill',
    'Napier',
    'Nelson',
    'New Lynn',
    'New Plymouth',
    'Northcote',
    'Northland',
    'North Shore',
    'Ōhāriu',
    'Ōtaki',
    'Pakuranga',
    'Palmerston North',
    'Panmure-Ōtāhuhu',
    'Papakura',
    'Rangitata',
    'Rangitīkei',
    'Remutaka',
    'Rongotai',
    'Rotorua',
    'Selwyn',
    'Southland',
    'Taieri',
    'Takanini',
    'Tāmaki',
    'Tāmaki Makaurau',
    'Taranaki-King Country',
    'Taupō',
    'Tauranga',
    'Te Atatū',
    'Te Tai Hauāuru',
    'Te Tai Tokerau',
    'Te Tai Tonga',
    'Tukituki',
    'Upper Harbour',
    'Waiariki',
    'Waikato',
    'Waimakariri',
    'Wairarapa',
    'Waitaki',
    'Wellington Central',
    'West Coast-Tasman',
    'Whanganui',
    'Whangaparāoa',
    'Whangārei',
    'Wigram',
    'Non-electorate candidate',
    'Port Waikato'
] as const;

export type Electorate = typeof electorates[number];
