import { Alert, Container, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ElectionApp } from "../../live-election";
import { AppState } from "../../types/AppState";



export const HomePageHeader: FC = () => {

    const [appState, setAppState] = useState<AppState>({});

    useEffect(() => {
        setAppState(ElectionApp.appState);
        ElectionApp.subscribe((state) => setAppState(state));
    }, []);

    return (
        <>
            <Container style={{display: 'flex'}}>
                <Typography variant="h1" style={{display: 'flex'}}>
                    Election Night 2023
                </Typography>
                {/* <Typography variant="body2" style={{display: 'flex', }}>
                    Next Update: 
                </Typography> */}
                
            </Container>

            {appState?.header?.banner && (
                <Container>
                    <Alert severity={appState?.header?.severity || 'info'}>{appState.header.banner}</Alert>
                </Container>
            )}
        </>
    )
}
