import { Box, Container, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { PartyTab } from "../party/PartyTab";
import { AppStateProps } from "../../types/AppState";
import { ElectorateTab } from "../electorates/ElectorateTab";



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const HomePageTabLayout: FC<AppStateProps> = (props: AppStateProps) => {


    const { appState } = props;

    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
        <>

            <Box sx={{ width: '100%' }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>


                    <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
                        <Tab label="Parties" {...a11yProps(0)} />
                        <Tab label="Electorates" {...a11yProps(1)} />
                    </Tabs>

                </Box>

                <Container>
                    <CustomTabPanel value={selectedTab} index={0}>
                        <PartyTab appState={appState}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1}>
                        <ElectorateTab appState={appState}/>
                    </CustomTabPanel>

                </Container >
            </Box >
        </>
    )
}
